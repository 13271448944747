/* eslint-disable react-refresh/only-export-components */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable guard-for-in */
/* eslint-disable no-debugger */
import { Button, Group } from '@mantine/core';
import {
  createReference,
  evalFhirPathTyped,
  getExtension,
  getQuestionnaireAnswers,
  getReferenceString,
  getTypedPropertyValue,
  ProfileResource,
  TypedValue,
} from '@medplum/core';
import {
  Questionnaire,
  QuestionnaireItem,
  QuestionnaireItemInitial,
  QuestionnaireResponse,
  QuestionnaireResponseItem,
  QuestionnaireResponseItemAnswer,
  Reference,
} from '@medplum/fhirtypes';
import { useEffect, useState } from 'react';
import { Form, useMedplum, useResource } from '@medplum/react';
import { QuestionnaireFormItemArray } from './QuestionnaireFormItemArray';

export interface QuestionnaireFormProps {
  questionnaire: Questionnaire | Reference<Questionnaire>;
  subject?: Reference;
  submitButtonText?: string;
  onSubmit: (response: QuestionnaireResponse) => void;
}

export function QuestionnaireForm(props: QuestionnaireFormProps): JSX.Element | null {
  const medplum = useMedplum();
  const source = medplum.getProfile();
  const [schemaLoaded, setSchemaLoaded] = useState(false);
  const questionnaire = useResource(props.questionnaire);
  const [response, setResponse] = useState<QuestionnaireResponse | undefined>();
  const [answers, setAnswers] = useState<Record<string, QuestionnaireResponseItemAnswer>>({});
  const [activePage, setActivePage] = useState(0);

  const [numberOfPages, setNumberOfPages] = useState(getNumberOfPages(questionnaire?.item ?? []));

  const nextStep = (): void => {
    setActivePage((current) => (current >= numberOfPages ? current : current + 1));
  };

  const prevStep = (): void => setActivePage((current) => (current <= 0 ? current : current - 1));

  useEffect(() => {
    medplum
      .requestSchema('Questionnaire')
      .then(() => medplum.requestSchema('QuestionnaireResponse'))
      .then(() => setSchemaLoaded(true))
      .catch(console.log);
  }, [medplum]);

  useEffect(() => {
    setResponse(questionnaire ? buildInitialResponse(questionnaire) : undefined);
  }, [questionnaire]);

  function setItems(newResponseItems: QuestionnaireResponseItem[]): void {
    const newResponse: QuestionnaireResponse = {
      resourceType: 'QuestionnaireResponse',
      item: newResponseItems,
    };
    setResponse(newResponse);
    setAnswers(getQuestionnaireAnswers(newResponse));
  }

  if (!schemaLoaded || !questionnaire) {
    return null;
  }

  return (
    <Form
      testid="questionnaire-form"
      onSubmit={() => {
        if (props.onSubmit && response) {
          props.onSubmit({
            ...response,
            questionnaire: getReferenceString(questionnaire),
            subject: props.subject,
            source: createReference(source as ProfileResource),
            authored: new Date().toISOString(),
            status: 'completed',
          });
        }
      }}
    >
      {questionnaire.item && (
        <QuestionnaireFormItemArray
          items={questionnaire.item ?? []}
          answers={answers}
          onChange={setItems}
          renderPages={numberOfPages > 1}
          activePage={activePage}
          updateNumberOfPages={setNumberOfPages}
        />
      )}
      <Group position="right" my="xl" className="stepper-buttons">
        <ButtonGroup
          activePage={activePage}
          numberOfPages={numberOfPages}
          nextStep={nextStep}
          prevStep={prevStep}
          submitButtonText={props.submitButtonText}
        />
      </Group>
    </Form>
  );
}

interface ButtonGroupProps {
  activePage: number;
  numberOfPages: number;
  submitButtonText?: string;
  nextStep: () => void;
  prevStep: () => void;
}

function ButtonGroup(props: ButtonGroupProps): JSX.Element {
  if (props.activePage + 1 === props.numberOfPages) {
    return <Button type="submit">{props.submitButtonText ?? 'SUBMIT'}</Button>;
  } else if (props.numberOfPages - props.activePage === 1) {
    return (
      <>
        <Button className="bg-main" onClick={props.prevStep}>
          Back
        </Button>
        <Button className="bg-main" onClick={props.nextStep} type="submit">
          {props.submitButtonText ?? 'SUBMIT'}
        </Button>
      </>
    );
  } else if (props.activePage === 0) {
    return (
      <>
        <Button className="bg-main" onClick={props.nextStep}>
          Next
        </Button>
      </>
    );
  } else {
    return (
      <>
        <Button className="bg-main" onClick={props.prevStep}>
          Back
        </Button>
        <Button className="bg-main" onClick={props.nextStep}>
          Next
        </Button>
      </>
    );
  }
}

function buildInitialResponse(questionnaire: Questionnaire): QuestionnaireResponse {
  const response: QuestionnaireResponse = {
    resourceType: 'QuestionnaireResponse',
    questionnaire: getReferenceString(questionnaire),
    item: buildInitialResponseItems(questionnaire.item),
  };

  return response;
}

function buildInitialResponseItems(items: QuestionnaireItem[] | undefined): QuestionnaireResponseItem[] {
  return items?.map(buildInitialResponseItem) ?? [];
}

function buildInitialResponseItem(item: QuestionnaireItem): QuestionnaireResponseItem {
  return {
    id: generateId(),
    linkId: item.linkId,
    text: item.text,
    item: buildInitialResponseItems(item.item),
    answer: item.initial?.map(buildInitialResponseAnswer) ?? [],
  };
}

let nextId = 1;
function generateId(): string {
  return 'id-' + nextId++;
}

function buildInitialResponseAnswer(answer: QuestionnaireItemInitial): QuestionnaireResponseItemAnswer {
  return { ...answer };
}

export function isQuestionEnabled(
  item: QuestionnaireItem,
  answers: Record<string, QuestionnaireResponseItemAnswer>
): boolean {
  if (!item.enableWhen) {
    return true;
  }

  const enableBehavior = item.enableBehavior ?? 'any';

  for (const enableWhen of item.enableWhen) {
    const actualAnswer = getTypedPropertyValue(
      {
        type: 'QuestionnaireResponseItemAnswer',
        value: answers[enableWhen.question as string],
      },
      'value[x]'
    ) as TypedValue | undefined; // possibly undefined when question unanswered

    const expectedAnswer = getTypedPropertyValue(
      {
        type: 'QuestionnaireItemEnableWhen',
        value: enableWhen,
      },
      'answer[x]'
    ) as TypedValue;

    let match: boolean;

    const { operator } = enableWhen;

    // We handle exists separately since its so different in terms of comparisons than the other mathematical operators
    if (operator === 'exists') {
      // if actualAnswer is not undefined, then exists: true passes
      // if actualAnswer is undefined, then exists: false passes
      match = !!actualAnswer === expectedAnswer.value;
    } else if (actualAnswer === undefined) {
      match = false;
    } else {
      // `=` and `!=` should be treated as the FHIRPath `~` and `!~`
      // All other operators should be unmodified
      const fhirPathOperator = operator === '=' || operator === '!=' ? operator?.replace('=', '~') : operator;
      const [{ value }] = evalFhirPathTyped(`%actualAnswer ${fhirPathOperator} %expectedAnswer`, [actualAnswer], {
        actualAnswer,
        expectedAnswer,
      });
      match = value;
    }

    if (enableBehavior === 'any' && match) {
      return true;
    }
    if (enableBehavior === 'all' && !match) {
      return false;
    }
  }
  if (enableBehavior === 'any') {
    return false;
  } else {
    return true;
  }
}

function getNumberOfPages(items: QuestionnaireItem[]): number {
  items.filter((item) => {
    const extension = getExtension(item, 'http://hl7.org/fhir/StructureDefinition/questionnaire-itemControl');
    return extension?.valueCodeableConcept?.coding?.[0]?.code === 'page';
  });
  return items.length;
}
