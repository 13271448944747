import { Table } from '@mantine/core';
import { formatDateTime, normalizeErrorString } from '@medplum/core';
import { BundleEntry, Resource, ResourceType } from '@medplum/fhirtypes';
import { MedplumLink, ResourceBadge, useMedplum } from '@medplum/react';
import { useParams } from 'react-router-dom';

export function History(): JSX.Element | null {
  const medplum = useMedplum();
  const { id } = useParams() as { id: string };
  const resourceType: ResourceType = 'Patient';
  const history = medplum.readHistory(resourceType, id).read();

  return (
    <div className="resource-tab-panel patient-list">
      <div className="resource-details table-content">
        {/* <ResourceHistoryTable history={history} /> */}
        <Table withBorder withColumnBorders>
          <thead>
            <tr>
              <th>Author</th>
              <th>Date</th>
              <th>Version</th>
            </tr>
          </thead>
          <tbody>{history.entry?.map((entry, index) => <HistoryRow key={'entry-' + index} entry={entry} />)}</tbody>
        </Table>
      </div>
    </div>
  );
}

interface HistoryRowProps {
  entry: BundleEntry;
}

function HistoryRow(props: HistoryRowProps): JSX.Element {
  const { response, resource } = props.entry;
  if (resource) {
    return (
      <tr>
        <td>
          <ResourceBadge value={resource.meta?.author} link={true} />
        </td>
        <td>{formatDateTime(resource.meta?.lastUpdated)}</td>
        <td>
          <MedplumLink to={getVersionUrl(resource)}>{resource.meta?.versionId}</MedplumLink>
        </td>
      </tr>
    );
  } else {
    return (
      <tr>
        <td colSpan={3}>{normalizeErrorString(response?.outcome)}</td>
      </tr>
    );
  }
}

function getVersionUrl(resource: Resource): string {
  return `/${resource.resourceType}/${resource.id}/_history/${resource.meta?.versionId}`;
}
