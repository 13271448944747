import { formatAddress } from '@medplum/core';
import { ResourceType } from '@medplum/fhirtypes';
import { useResource } from '@medplum/react';
import { Link, useParams } from 'react-router-dom';

export function Details(): JSX.Element | null {
  const { id } = useParams() as { id: string };
  const resourceType: ResourceType = 'Patient';
  const resource: any = useResource({ reference: resourceType + '/' + id });

  if (!resource) {
    return null;
  }

  function getName(name: any): string {
    const firstName: any = [];
    name?.given.forEach((entry: any) => {
      firstName.push(entry);
    });
    const formmatedFirstName = firstName.join(' ');
    const patientName = formmatedFirstName ? formmatedFirstName + ' ' + name?.family : name?.family;
    return patientName;
  }

  return (
    <div className="resource-tab-panel">
      <div className="resource-details">
        <div>
          <div className="resource-header">
            <p>GENERAL DETAILS</p>
          </div>
          <div className="resource-data">
            <dt>MRN</dt>
            <dd>
              <div>{resource?.identifier ? resource?.identifier?.[0].value : '-'}</div>
            </dd>
          </div>
        </div>
        <div>
          <div className="resource-header">
            <p>PERSONAL DETAILS</p>
          </div>
          <div className="resource-data">
            <dt>Name</dt>
            <dd>
              <div>{getName(resource?.name[0])}</div>
            </dd>
          </div>
          <div className="resource-data">
            <dt>Contact Details</dt>
            <dd>
              <div>
                {resource?.telecom
                  ?.filter((t: any) => t.system === 'phone')
                  .map((t: any) => (
                    <p key={t.value}>
                      <b>{t.use} : </b>
                      {t.value}
                    </p>
                  ))}
                {resource?.telecom
                  ?.filter((t: any) => t.system === 'email')
                  .map((t: any) => (
                    <p key={t.value}>
                      <b>{t.system} : </b>
                      {t.value}
                    </p>
                  ))}
                {!resource?.telecom && 'NA'}
              </div>
            </dd>
          </div>
          <div className="resource-data">
            <dt>Gender</dt>
            <dd>
              <div>{resource?.gender || '-'}</div>
            </dd>
          </div>
          <div className="resource-data">
            <dt>Address</dt>
            <dd>
              <div>{resource?.address ? formatAddress(resource?.address?.[0] || {}) : 'NA'}</div>
            </dd>
          </div>
          <div className="resource-data">
            <dt>Marital Status</dt>
            <dd>
              <div>{resource?.maritalStatus?.text || '-'}</div>
            </dd>
          </div>
          <div className="resource-data">
            <dt>Race</dt>
            <dd>
              <div>
                {resource?.extension
                  ?.find((ext: any) => ext.url === 'http://hl7.org/fhir/us/core/StructureDefinition/us-core-race')
                  ?.extension?.find((subExt: any) => subExt.url === 'ombCategory')?.valueCoding?.display || '-'}
              </div>
            </dd>
          </div>
          <div className="resource-data">
            <dt>Ethnicity</dt>
            <dd>
              <div>
                {resource?.extension
                  ?.find((ext: any) => ext.url === 'http://hl7.org/fhir/us/core/StructureDefinition/us-core-ethnicity')
                  ?.extension?.find((subExt: any) => subExt.url === 'detailed' || subExt.url === 'text')?.valueString ||
                  '-'}
              </div>
            </dd>
          </div>
        </div>
        <div>
          <div className="resource-header">
            <p>OTHER DETAILS</p>
          </div>
          <div className="resource-data">
            <dt>General Practitioner</dt>
            <dd>
              <div>
                {resource?.generalPractitioner
                  ?.filter((t: any) => t.type === 'Practitioner')
                  .map((t: any, index: number) => (
                    <span key={index}>
                      <Link
                        style={{
                          textDecoration: 'none',
                          color: '#3673B6',
                        }}
                        to={t.reference}
                        target="_blank"
                      >
                        {t.display}
                      </Link>
                      {index < resource.generalPractitioner.length - 1 ? ', ' : ''}
                    </span>
                  )) || 'NA'}
              </div>
            </dd>
          </div>
          <div className="resource-data">
            <dt>Managing Organization</dt>
            <dd>
              <div>{resource?.managingOrganization?.display}</div>
            </dd>
          </div>
          <div className="resource-data">
            <dt>Link</dt>
            <dd>
              <div>{'-'}</div>
            </dd>
          </div>
        </div>
      </div>

      {/* <ResourceTable value={resource} /> */}
    </div>
  );
}
