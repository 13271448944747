/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Card, Text, Group, createStyles, Badge } from '@mantine/core';
import { IconArrowRight } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { integratedOrganizations } from '../fhirApi';
import { useMedplum } from '@medplum/react';

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
  },

  section: {
    padding: theme.spacing.sm,
    borderBottom: `1px solid #EEF1F6 !important`,
  },

  footer: {
    padding: theme.spacing.md,
    borderTop: `1px solid #EEF1F6 !important`,
  },

  scrollbar: {
    overflow: 'hidden',
    '&:hover': {
      overflowY: 'auto',
      scrollbarWidth: 'thin',
      scrollbarColor: '#ccc #f3f3f3',
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#ccc',
        borderRadius: '8px',
        '&:hover': {
          background: '#aaa',
        },
      },
    },
  },

  badge: {
    minWidth: '80px',
    minHeight: '30px',
    color: '#000000',
    textTransform: 'capitalize',
    fontWeight: 600,
  },
}));

export function OrganisationCard() {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const medplum = useMedplum();
  const [organizationResponse, setOrganizationResponse] = useState<any | null>();
  useEffect(() => {
    integratedOrganizations(medplum)
    .then((response) =>{
      setOrganizationResponse(response?.data);
    })
    .catch((error: any) => {
      console.error('Error fetching data:', error);
    });
  }, [])

  const organisationDetails = organizationResponse?.map((details: any) => (
    <Card.Section
      key={details.id}
      display="flex"
      className={classes.section}
      onClick={() => navigate('/organizations', { state: details } )}
      style={{ cursor: 'pointer' }}
    >
      <Group style={{ justifyContent: 'flex-start', flex: 4 }}>
        <Text className="f-main" fw={500}>
          {details.organizationName}
        </Text>
      </Group>
      <Group style={{ justifyContent: 'flex-start', flex: 2 }}>
        <Text className="lightText" fw={500}>
          {details.state}
        </Text>
      </Group>
      <Group style={{ justifyContent: 'flex-start', flex: 2 }}>
        <Text className="lightText" fw={500}>
          {details.ehr}
        </Text>
      </Group>
      <Group style={{ justifyContent: 'flex-start', flex: 2 }}>
        {details?.status === 'Active' ? (
          <Badge className={classes.badge} variant="filled" style={{ background: '#DEF5EA' }}>
            {details.status}
          </Badge>
        ) : details?.status === 'Inactive' ? (
          <Badge className={classes.badge} variant="filled" style={{ background: '#FAEBEB' }}>
            {details.status}
          </Badge>
        ) : (
          <Badge className={classes.badge} variant="filled" style={{ background: '#FCF2DF' }}>
            {details.status}
          </Badge>
        )}
      </Group>
    </Card.Section>
  ));

  return (
    <Card withBorder radius="md" className={classes.card}>
      <Card.Section className={classes.section} p="lg">
        <Text className="darkText" fw={700}>
          Organizations and EHRs Integrated
        </Text>
      </Card.Section>

      <Card.Section px="lg" display="flex" className={classes.section}>
        <Group style={{ justifyContent: 'flex-start', flex: 4 }}>
          <Text className="darkText" fw={700}>
            Name
          </Text>
        </Group>
        <Group style={{ justifyContent: 'flex-start', flex: 2 }}>
          <Text className="darkText" fw={700}>
            Location
          </Text>
        </Group>
        <Group style={{ justifyContent: 'flex-start', flex: 2 }}>
          <Text className="darkText" fw={700}>
            EHR
          </Text>
        </Group>
        <Group style={{ justifyContent: 'flex-start', flex: 2 }}>
          <Text className="darkText" fw={700}>
            Status
          </Text>
        </Group>
      </Card.Section>

      <Group px="xs" className={classes.scrollbar} display="block" style={{ height: '360px', overflowX: 'hidden' }}>
        {organisationDetails}
      </Group>

      <Card.Section className={classes.footer}>
        <Group style={{ alignItems: 'flex-end' }} spacing={30}>
          <div style={{ textDecoration: 'none', alignItems: 'flex-end', flex: 2 }}></div>
          <Link
            style={{
              textDecoration: 'none',
              alignItems: 'center',
              justifyContent: 'flex-end',
              flex: 1,
              color: '#222',
              display: 'flex',
            }}
            to={'/all-organizations-and-ehrs-integrated'}
          >
            <Text fw={700} mr="5px">
              View All
            </Text>
            <IconArrowRight size="16px" />
          </Link>
        </Group>
      </Card.Section>
    </Card>
  );
}
