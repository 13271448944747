import { useCallback, useState, useEffect, forwardRef } from 'react';
import { Box, Grid, Text, Select, Group, Button, Accordion, Table, Loader } from '@mantine/core';
import { useMedplum } from '@medplum/react';
import { Patient, ResearchStudy } from '@medplum/fhirtypes';
import { IconSearch, IconCircleCheck, IconExclamationCircle, IconCheckbox } from '@tabler/icons-react';
import { formatHumanName, getDisplayString, isUUID } from '@medplum/core';
import { AsyncAutocomplete, AsyncAutocompleteOption } from '../../../../../react/src/AsyncAutocomplete/AsyncAutocomplete';
import { ResourceAvatar } from '../../../../../react/src/ResourceAvatar/ResourceAvatar';
import TitleComponent from '../../../components/TitleComponent';
import { clinicalStudyData, clinicalStudyNotification } from '../../../../src/fhirApi';
import { HeaderSearchTypes, toKey, toOption } from '../../../../../react/src/AppShell/HeaderSearchInput';
import { showNotification } from '@mantine/notifications';

const defaultQuery = (input: string) => {
  const escaped = JSON.stringify(input);
  if (isUUID(input)) {
    return `{
      Patients1: PatientList(_id: ${escaped}, _count: 1) {
        resourceType
        id
        name {
          given
          family
        }
        birthDate
      }
      ServiceRequestList(_id: ${escaped}, _count: 1) {
        resourceType
        id
        subject {
          display
        }
      }
    }`;
  }
  return `{
    Patients1: PatientList(name: ${escaped}, _count: 5) {
      resourceType
      id
      name {
        given
        family
      }
      birthDate
    }
    ServiceRequestList(identifier: ${escaped}, _count: 5) {
      resourceType
      id
      subject {
        display
      }
    }
  }`;
};

const researchStudyQuery = () => `
  {
    ResearchStudyList(_count: 1000) {
      resourceType
      id
      title
      description
      period {
        start
        end
      }
      location {
        coding {
          display
        }
      }
    }
  }
`;

const EligibilityChecker = () => {
  const [selectedPatient, setSelectedPatient] = useState<string | null>(null);
  const [patients, setPatients] = useState<{ label: string; value: string }[]>([]);
  const [studies, setStudies] = useState<{ value: string; label: string; description: string; resource?: ResearchStudy }[]>([]);
  const [selectedStudy, setSelectedStudy] = useState<string | null>(null);
  const [studyDetails, setStudyDetails] = useState<ResearchStudy & { entry?: any[] | undefined }>({} as ResearchStudy & { entry?: any[] | undefined });
  const [notificationDetails, setNotificationDetails] = useState<any>(null);
  const [briefSummary, setBriefSummary] = useState('');
  const [detailedDescription, setDetailedDescription] = useState('');
  const [studyStartDate, setStudyStartDate] = useState('');
  const [studyEndDate, setStudyEndDate] = useState('');
  const [studyLocation, setStudyLocation] = useState('');
  const [eligibilityCriteria, setEligibilityCriteria] = useState<any[]>([]);
  const [eligibilityStatus, setEligibilityStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isCheckClicked, setIsCheckClicked] = useState(false);
  const medplum = useMedplum();

  const loadPatients = useCallback(async (input: string, signal: AbortSignal): Promise<HeaderSearchTypes[]> => {
    const query = defaultQuery(input);
    try {
      const response = await medplum.graphql(query, undefined, undefined, { signal });
      const patientList = getResourcesFromResponse(response, input);

      return patientList.map((patient: Patient) => ({
        resourceType: 'Patient',
        id: patient.id || '',
        name: patient.name || [],
        birthDate: patient.birthDate || '',
      })) as HeaderSearchTypes[];
    } catch (error) {
      console.error('Error fetching patients:', error);
      return [];
    }
  }, [medplum]);

  // Function to load research studies
  const loadResearchStudies = useCallback(async () => {
    try {
      const response = await medplum.graphql(researchStudyQuery());
      const researchStudyList = response.data.ResearchStudyList || [];
      const formattedStudies = researchStudyList.map((study: ResearchStudy) => ({
        value: study.id || '',
        label: study.title || '',
        resource: study,
      }));
      setStudies(formattedStudies);
    } catch (error) {
      console.error('Error fetching research studies:', error);
    }
  }, [medplum]);

  useEffect(() => {
    loadResearchStudies();
  }, [loadResearchStudies]);

  useEffect(() => {
    setIsCheckClicked(false);
  }, [selectedPatient, selectedStudy]);

  useEffect(() => {
    console.log('notificationDetails Details:', notificationDetails);
  }, [notificationDetails]);

  const handleSelect = useCallback((selected: Patient[]) => {
    if (selected && selected.length === 1) {
      const selectedPatientId = selected[0]?.id || '';
      setSelectedPatient(selectedPatientId);

      const label = selected[0]?.name?.[0]?.family
        ? selected[0]?.name?.[0]?.given?.join(' ') + ' ' + selected[0]?.name?.[0]?.family
        : selected[0]?.name?.[0]?.given?.join(' ') || 'this Patient';

      setPatients([{ label: label, value: selectedPatientId }]);

      resetEligibilityData();
    } else {
      setSelectedPatient(null);
      setPatients([]);
      resetEligibilityData();
      console.error('Expected exactly one patient to be selected');
    }
  }, []);

  const handleSelectStudy = (selectedValue: string | null) => {
    const selectedStudy = studies.find((study) => study.value === selectedValue);
    if (selectedStudy && selectedStudy.resource) {
      const resource = selectedStudy.resource;

      // Parse the description JSON string
      let parsedDescription: { briefSummary: string; detailedDescription: string } | undefined;
      if (typeof resource.description === 'string') {
        parsedDescription = JSON.parse(resource.description);
      }

      // Extract briefSummary and detailedDescription after parsing
      const briefSummary = parsedDescription?.briefSummary || 'No brief summary available';
      const detailedDescription = parsedDescription?.detailedDescription || 'No detailed description available';

      setSelectedStudy(selectedStudy.value);
      setBriefSummary(briefSummary);
      setDetailedDescription(detailedDescription);
      setStudyStartDate(resource.period?.start ? new Date(resource.period.start).toLocaleDateString() : 'N/A');
      setStudyEndDate(resource.period?.end ? new Date(resource.period.end).toLocaleDateString() : 'N/A');
      setStudyLocation(resource.location?.map(loc => loc.coding?.map(c => c.display).join(', ')).join(', ') || 'Location not available');

      resetEligibilityData();
    }
  };

  const resetEligibilityData = () => {
    setEligibilityCriteria([]);
    setEligibilityStatus(null);
    setIsCheckClicked(false);
  };

  const checkEligibility = async () => {
    if (selectedPatient && selectedStudy) {
      setLoading(true);
      try {
        const studyDataResponse = await clinicalStudyData(medplum, selectedStudy, selectedPatient);

        const eligibilityExtension = studyDataResponse.extension?.find(
          (ext: { url: string; valueString?: string }) => ext.url === "https://app.pragmaconnect.com/eligibility-assessment"
        );

        if (eligibilityExtension && eligibilityExtension.valueString) {
          const parsedData = JSON.parse(eligibilityExtension.valueString);
          const parsedEligibilityCriteria = parsedData.eligibility_assessment || [];
          setEligibilityCriteria(parsedEligibilityCriteria);
        } else {
          console.error('Eligibility assessment data not found');
        }

        setStudyDetails(studyDataResponse);
        setEligibilityStatus(studyDataResponse.status);
      } catch (error) {
        console.error('Error fetching clinical study data:', error);
      } finally {
        setLoading(false);
        setIsCheckClicked(true);
      }
    } else {
      console.error('Patient or study not selected');
    }
  };

  const notifyEligibility = async () => {
    if (selectedPatient && selectedStudy && studyDetails) {
      setLoading(true);
      try {
        const selectedPatientName = patients.length > 0 ? patients[0].label : 'Unknown Patient';

        const selectedStudyName = studies.find(study => study.value === selectedStudy)?.label || 'Unknown Study';

        const researchStudyId = studyDetails?.id || '';

        const notificationResponse = await clinicalStudyNotification(medplum, selectedPatient, selectedPatientName, selectedStudy, selectedStudyName, researchStudyId, '');

        setNotificationDetails(notificationResponse);

        showNotification({
          color: 'green',
          message: `Notification has been sent to ${selectedPatientName}`
        });
      } catch (error) {
        console.error('Error sending clinical study notification:', error);
      } finally {
        setLoading(false);
      }
    } else {
      console.error('Patient or study not selected');
    }
  };

  return (
    <Box px="lg" py="sm" sx={{ paddingBottom: '0px !important' }}>
      <Grid mb="md">
        <Grid.Col span={12} lg={12}>
          <TitleComponent title={'Eligibility Checker'} />
        </Grid.Col>
        <Grid.Col span={12} lg={12}>
          <Text style={{ fontSize: '18px', fontWeight: '600' }}>Clinical Trial Eligibility Check</Text>
        </Grid.Col>
        <Box sx={{ width: '100%', margin: '0 8px', padding: '16px', background: '#E9F3FF', borderRadius: '8px' }}>
          <Grid mb="md">
            <Grid.Col span={12} lg={5}>
              <Box className="fhir-bot" sx={{ width: '100%' }}>
                <Box mb="md" sx={{ width: '100%' }}>
                  <Text mb={8} style={{ fontSize: '16px', fontWeight: '500' }}>Patient Name</Text>
                  <AsyncAutocomplete
                    size="sm"
                    radius="md"
                    icon={<IconSearch size={16} />}
                    placeholder="Search patients"
                    loadOptions={loadPatients}
                    onChange={(item: HeaderSearchTypes[]) => handleSelect(item as Patient[])}
                    clearSearchOnChange
                    clearable={false}
                    maxSelectedValues={1}
                    toKey={toKey}
                    toOption={toOption}
                    itemComponent={ItemComponent}
                    styles={(theme) => ({
                      input: {
                        width: '100%',
                        paddingTop: '6px',
                      },
                    })}
                  />
                </Box>
              </Box>
            </Grid.Col>

            <Grid.Col span={12} lg={5.5}>
              <Box className="fhir-bot" sx={{ width: '100%' }}>
                <Box mb="md" sx={{ width: '100%' }}>
                  <Text mb={8} style={{ fontSize: '16px', fontWeight: '500' }}>Clinical Study</Text>
                  <Select
                    size="sm"
                    radius="md"
                    placeholder="Search clinical study"
                    icon={<IconSearch size={16} />}
                    data={studies}
                    onChange={handleSelectStudy}
                    searchable
                    nothingFound="No studies found"
                    styles={(theme) => ({
                      input: {
                        width: '100%',
                        paddingTop: '6px',
                      },
                    })}
                  />
                </Box>
              </Box>
            </Grid.Col>

            <Grid.Col span={12} lg={1.5}>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: '100%', marginTop: '0.5rem' }}>
                <Button
                  variant="filled"
                  onClick={checkEligibility}
                  disabled={!selectedPatient || !selectedStudy || loading || isCheckClicked}
                  sx={{
                    width: '100%',
                    height: '2.8rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                    opacity: loading ? 0.7 : 1,
                    pointerEvents: loading ? 'none' : 'auto',
                    minWidth: 'max-content'
                  }}
                >
                  {loading && (
                    <Loader
                      size="sm"
                      color="white"
                      sx={{
                        position: 'absolute',
                        left: '50%',
                        top: '50%',
                        transform: 'translate(-50%, -50%)',
                      }}
                    />
                  )}
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <IconCheckbox size={16} style={{ marginRight: '0.5rem' }} />
                    Check
                  </Box>
                </Button>
              </Box>
            </Grid.Col>
          </Grid>
        </Box>

        {/* Grid for displaying Clinical Trial Details */}
        {selectedStudy && (
          <Grid.Col span={12} lg={12}>
            <Box p="md" bg="#f9f9f9">
              <Accordion mt="lg" defaultValue="studyDetails">
                <Accordion.Item value="studyDetails">
                  <Accordion.Control>Clinical Trial Details</Accordion.Control>
                  <Accordion.Panel>
                    <Grid>
                      {/* Brief Summary */}
                      <Grid.Col span={12} mt="md">
                        <Text>
                          <strong style={{ fontSize: '16px', fontWeight: 500 }}>Brief Summary</strong>
                          <Text
                            style={{ fontSize: '14px', fontWeight: 400, marginTop: '8px', display: 'block' }}
                            dangerouslySetInnerHTML={{ __html: briefSummary }}
                          >
                          </Text>
                        </Text>
                      </Grid.Col>

                      {/* Detailed Description */}
                      <Grid.Col span={12} mt="md">
                        <Text>
                          <strong style={{ fontSize: '16px', fontWeight: 500 }}>Detailed Description</strong>
                          <Text
                            style={{ fontSize: '14px', fontWeight: 400, marginTop: '8px', display: 'block' }}
                            dangerouslySetInnerHTML={{ __html: detailedDescription }} // Render HTML safely
                          />
                        </Text>
                      </Grid.Col>

                      {/* Start Date */}
                      <Grid.Col span={3} mt="md" style={{ padding: '10px' }}>
                        <Box
                          sx={{
                            border: '1px solid #ddd',
                            borderRadius: '8px',
                            padding: '10px',
                            backgroundColor: '#F8F8F8',
                          }}
                        >
                          <Text>
                            <strong style={{ fontSize: '16px', fontWeight: 500 }}>Study Start Date</strong>
                            <br />
                            {studyStartDate}
                          </Text>
                        </Box>
                      </Grid.Col>

                      {/* End Date */}
                      <Grid.Col span={3} mt="md" style={{ padding: '10px' }}>
                        <Box
                          sx={{
                            border: '1px solid #ddd',
                            borderRadius: '8px',
                            padding: '10px',
                            backgroundColor: '#F8F8F8',
                          }}
                        >
                          <Text>
                            <strong style={{ fontSize: '16px', fontWeight: 500 }}>Study Completion (Estimated) Date</strong>
                            <br />
                            {studyEndDate}
                          </Text>
                        </Box>
                      </Grid.Col>

                      {/* Location */}
                      <Grid.Col span={3} mt="md" style={{ padding: '10px' }}>
                        <Box
                          sx={{
                            border: '1px solid #ddd',
                            borderRadius: '8px',
                            padding: '10px',
                            backgroundColor: '#F8F8F8',
                          }}
                        >
                          <Text>
                            <strong style={{ fontSize: '16px', fontWeight: 500 }}>Location</strong>
                            <br />
                            {studyLocation}
                          </Text>
                        </Box>
                      </Grid.Col>
                    </Grid>
                  </Accordion.Panel>
                </Accordion.Item>
              </Accordion>
            </Box>
          </Grid.Col>
        )}

        {/* Table for Eligibility Criteria */}
        {isCheckClicked && eligibilityCriteria.length > 0 && (
          <Grid.Col span={12} lg={12}>
            <Box p="md">
              <Text size="lg" style={{ fontSize: '18px', fontWeight: '600', marginBottom: '16px' }}>
                Clinical Trial Criteria Breakdown
              </Text>
              <Box
                sx={{
                  border: '1px solid #ccc',
                  borderRadius: '8px',
                  overflowY: 'auto',
                  maxHeight: '400px',
                }}
              >
                <Table striped highlightOnHover>
                  <thead
                    style={{
                      position: 'sticky',
                      top: 0,
                      backgroundColor: '#fff',
                      zIndex: 1,
                    }}
                  >
                    <tr>
                      <th>Criteria Name</th>
                      <th>Type</th>
                      <th>Patient Value</th>
                      <th>Criteria Met</th>
                      <th>Description</th>
                      <th>Reason</th>
                    </tr>
                  </thead>
                  <tbody>
                    {eligibilityCriteria.map((criteria, index) => (
                      <tr key={index}>
                        <td>{criteria.criteria_name}</td>
                        <td>{criteria.criteria_type}</td>
                        <td>{criteria.patient_value}</td>
                        <td>{criteria.met ? 'Yes' : 'No'}</td>
                        <td>{criteria.criteria_description}</td>
                        <td>{criteria.reason}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Box>
            </Box>
          </Grid.Col>
        )}

        {/* Eligibility Status */}
        {isCheckClicked && eligibilityStatus && (
          <Grid.Col span={12} lg={12}>
            <Box
              sx={{
                padding: '16px',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {eligibilityStatus === 'eligible' ? (
                  <>
                    <IconCircleCheck size={24} color="#3EB27B" />
                    <Text style={{ marginLeft: '8px', fontWeight: '600', fontSize: '18px', color: '#3EB27B' }}>
                      Result: Eligible
                    </Text>
                  </>
                ) : (
                  <>
                    <IconExclamationCircle size={24} color="#BE3B3B" />
                    <Text style={{ marginLeft: '8px', fontWeight: '600', fontSize: '18px', color: '#BE3B3B' }}>
                      Result: Not Eligible
                    </Text>
                  </>
                )}
              </Box>
              <Text style={{ marginTop: '8px', fontSize: '16px' }}>
                {eligibilityStatus === 'eligible' ? (
                  `${patients[0]?.label || 'The patient'} meets the criteria and is eligible for '${studies.find(study => study.value === selectedStudy)?.label || 'the study'}'.`
                ) : (
                  `After review, ${patients[0]?.label || 'the patient'} does not meet the criteria and is not eligible for '${studies.find(study => study.value === selectedStudy)?.label || 'the study'}'.`
                )}
              </Text>
            </Box>
          </Grid.Col>
        )}

        {/* Notify Eligibility Button */}
        {eligibilityCriteria.length > 0 && (
          <Grid.Col span={12} lg={2}>
            <Box p="md">
              <Button
                variant="filled"
                onClick={() => notifyEligibility()}
                sx={{
                  width: '100%',
                  height: '2.8rem',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'relative',
                  opacity: loading ? 0.7 : 1,
                  pointerEvents: loading ? 'none' : 'auto',
                  minWidth: 'max-content'
                }}
              >
                {loading && (
                  <Loader
                    size="sm"
                    color="white"
                    sx={{
                      position: 'absolute',
                      left: '50%',
                      top: '50%',
                      transform: 'translate(-50%, -50%)',
                    }}
                  />
                )}
                <Box>Notify Patient for Eligibility Result</Box>
              </Button>
            </Box>
          </Grid.Col>
        )}
      </Grid>
    </Box>
  );
};

const ItemComponent = forwardRef<HTMLDivElement, AsyncAutocompleteOption<Patient>>(
  ({ resource, ...others }: AsyncAutocompleteOption<Patient>, ref) => {
    return (
      <div ref={ref} {...others}>
        <Group noWrap>
          <ResourceAvatar value={resource} />
          <div>
            <Text>{getDisplayString(resource)}</Text>
            <Text size="xs" color="dimmed">
              {resource.birthDate}
            </Text>
          </div>
        </Group>
      </div>
    );
  }
);

function getResourcesFromResponse(response: any, query: string): Patient[] {
  const resources: Patient[] = [];
  if (response.data.Patients1) {
    resources.push(...response.data.Patients1);
  }
  return resources
    .sort((a: Patient, b: Patient) => getResourceScore(b, query) - getResourceScore(a, query))
    .slice(0, 5);
}

function getResourceScore(resource: Patient, query: string): number {
  let bestScore = 0;
  if (resource.name) {
    for (const name of resource.name) {
      bestScore = Math.max(bestScore, getStringScore(formatHumanName(name), query));
    }
  }
  return bestScore;
}

function getStringScore(str: string | undefined, query: string): number {
  if (!str) {
    return 0;
  }
  const index = str.toLowerCase().indexOf(query.toLowerCase());
  if (index < 0) {
    return 0;
  }
  return 100 - index;
}

export default EligibilityChecker;
