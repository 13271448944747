import { MedplumClient, ProfileResource, createReference } from '@medplum/core';
import { Attachment, Patient, Reference, ResourceType } from '@medplum/fhirtypes';
import { DefaultResourceTimeline } from '@medplum/react';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { ResourceTimeline } from './ResourceTimeline';

export function Timeline(): JSX.Element | null {
  const { id } = useParams() as { id: string };
  const resourceType = 'Patient';
  const reference = { reference: resourceType + '/' + id };
  switch (resourceType) {
    case 'Patient':
      return <PatientTimeline patient={reference} />;
    default:
      return <DefaultResourceTimeline resource={reference} />;
  }
}

interface PatientTimelineProps {
  patient: Patient | Reference<Patient>;
}

function PatientTimeline(props: PatientTimelineProps): JSX.Element {
  const loadTimelineResources = useCallback((medplum: MedplumClient, resourceType: ResourceType, id: string) => {
    const ref = `${resourceType}/${id}`;
    const _count = 100;
    return Promise.allSettled([
      medplum.readHistory('Patient', id),
      medplum.search('Communication', { subject: ref, _count }),
      medplum.search('Device', { patient: ref, _count }),
      medplum.search('DeviceRequest', { patient: ref, _count }),
      medplum.search('DiagnosticReport', { subject: ref, _count }),
      medplum.search('Media', { subject: ref, _count }),
      medplum.search('ServiceRequest', { subject: ref, _count }),
      medplum.search('Task', { subject: ref, _count }),
    ]);
  }, []);

  return (
    <div className="resource-tab-panel patient-list">
      <div className="resource-details timeline-tab">
        <ResourceTimeline
          value={props.patient}
          loadTimelineResources={loadTimelineResources}
          createCommunication={(resource: Patient, sender: ProfileResource, text: string) => ({
            resourceType: 'Communication',
            status: 'completed',
            subject: createReference(resource),
            sender: createReference(sender),
            sent: new Date().toISOString(),
            payload: [{ contentString: text }],
          })}
          createMedia={(resource: Patient, operator: ProfileResource, content: Attachment) => ({
            resourceType: 'Media',
            status: 'completed',
            subject: createReference(resource),
            operator: createReference(operator),
            issued: new Date().toISOString(),
            content,
          })}
        />
      </div>
    </div>
  );
}
