import { formatAddress } from '@medplum/core';
import { Patient, Reference } from '@medplum/fhirtypes';
import { HumanNameDisplay, MedplumLink, ResourceAvatar, useResource } from '@medplum/react';
import { InfoBar } from './InfoBar';
import { Card, Center, Title } from '@mantine/core';
import { getDefaultColor } from './PatientHeader.utils';

export interface PatientHeaderProps {
  patient: Patient | Reference<Patient>;
}

export function PatientHeader(props: PatientHeaderProps): JSX.Element | null {
  const patient = useResource(props.patient);
  if (!patient) {
    return null;
  }
  return (
    <Card className="resources-card custom-scrollbar">
      <Card.Section className="resources-card-header">
        <Title className="resource-header">{'Patient Details'}</Title>
      </Card.Section>
      <Card.Section className="resources-card-section">
        <Center className="resources-header">
          <ResourceAvatar value={patient} size="lg" color={getDefaultColor(patient)} />
          <InfoBar.Value>
            <MedplumLink to={`/Patient/patient-dashboard/${patient?.id}`}>
              {patient.name ? <HumanNameDisplay value={patient.name[0]} options={{ use: false }} /> : '[blank]'}
            </MedplumLink>
          </InfoBar.Value>
          <span>
            {patient?.telecom
              ?.filter((t: any) => t.system === 'email')
              .map((t: any) => t.value)
              .join(', ') || '[blank]'}
          </span>
        </Center>
        <Center className="resources-content">
          <InfoBar>
            {patient.identifier && (
              <InfoBar.Entry>
                <InfoBar.Key>MRN</InfoBar.Key>
                <InfoBar.Value>{patient?.identifier[0]?.value}</InfoBar.Value>
              </InfoBar.Entry>
            )}
            {patient.birthDate && (
              <InfoBar.Entry>
                <InfoBar.Key>DoB</InfoBar.Key>
                <InfoBar.Value>{patient.birthDate}</InfoBar.Value>
              </InfoBar.Entry>
            )}
            {patient.gender && (
              <InfoBar.Entry>
                <InfoBar.Key>Gender</InfoBar.Key>
                <InfoBar.Value>{patient.gender}</InfoBar.Value>
              </InfoBar.Entry>
            )}
            {patient.address && (
              <InfoBar.Entry>
                <InfoBar.Key>Address</InfoBar.Key>
                <InfoBar.Value>{formatAddress(patient?.address?.[0] || {})}</InfoBar.Value>
              </InfoBar.Entry>
            )}
          </InfoBar>
        </Center>
      </Card.Section>
    </Card>
  );
}
