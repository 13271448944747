import React, { useEffect, useState, useCallback, forwardRef } from 'react';
import { Modal, TextInput, Button, Text, Switch, Select, Flex, Title, Group } from '@mantine/core';
import { IconChevronDown, IconVideoPlus } from '@tabler/icons-react';
import { createTelehealthSession, fetchPatients } from '../../fhirApi';
import { useMedplumContext } from '@medplum/react';
import { showNotification } from '@mantine/notifications';

interface TelehealthModalProps {
  isOpen: boolean;
  onClose: () => void;
  onStartCall: (id: string) => void;
}

const defaultQuery = (type: 'PatientList' | 'PractitionerList', searchTerm: string): string => `{
    ${type}(name: "${searchTerm}", _count: 1000) {
      resourceType
      id
      name {
        family
        given
      }
    }
  }`;

export const TelehealthModal: React.FC<TelehealthModalProps> = ({ isOpen, onClose, onStartCall }) => {
  const { medplum, profile } = useMedplumContext();
  const [title, setTitle] = useState('');
  const [practitioner, setPractitioner] = useState<string | undefined>(undefined);
  const [patient, setPatient] = useState<string | undefined>(undefined);
  const [meetingType, setMeetingType] = useState('instant');
  const [isStarting, setIsStarting] = useState(false);
  const [patientSearchTerm, setPatientSearchTerm] = useState('');
  const [practitionerSearchTerm, setPractitionerSearchTerm] = useState('');
  const [patients, setPatients] = useState<any[]>([]);
  const [practitioners, setPractitioners] = useState<any[]>([]);
  const [formTitle, setFormTitle] = useState('Start a call');
  const activeUserType = profile?.resourceType;
  const [sdkType, setSdkType] = useState('zoom');


  const sdkOptions = [
    { value: 'zoom', label: 'Zoom', img: '/img/icons/ic-zoom.svg' },
    { value: 'liveKit', label: 'Live Kit', img: '/img/icons/ic-live-kit.svg', disabled: true},
  ];

  const icon = <img src={sdkOptions.find(sdk => sdk.value === sdkType)?.img} width='20' />;

  const ItemComponent = forwardRef<HTMLDivElement, { value: string; label: string; img: string }>(
    ({ img, label, ...others }, ref) => {
      return (
        <div ref={ref} {...others}>
          <Group noWrap>
            <img src={img} alt={label} style={{ width: 24, height: 24 }} />
            <div>
              <Text>{label}</Text>
            </div>
          </Group>
        </div>
      );
    }
  );

  const handleStartCall = async () => {
    if (
      !meetingType || 
      (activeUserType === 'Patient' && !practitioner) || 
      (activeUserType === 'Practitioner' && !patient)
    ) {
      showNotification({
        message: 'Please select the required fields',
        color: 'red',
      });
      return;
    }

    setIsStarting(true);

    const body = {
      meetingType,
      title,
      practitionerId: activeUserType === 'Patient' ? practitioner : profile?.id,
      patientId: activeUserType === 'Practitioner' ? patient : profile?.id,
      sdkType,
    };

    try {
      const response = await createTelehealthSession(medplum, body);
      if (response) {
        if (meetingType === 'instant') {
          onStartCall(response?.appointmentId);
        } else {
          closeDialog();
        }
      }
    } catch (error) {
      setIsStarting(false);
      console.error('Error:', error);
    }
  };

  const closeDialog = () => {
    setIsStarting(false);
    setTitle('');
    setPractitioner(undefined);
    setPatient(undefined);
    setFormTitle('Start a call');
    onClose();
  };

  const fetchResources = useCallback((type: 'PatientList' | 'PractitionerList', searchTerm: string, setResource: React.Dispatch<React.SetStateAction<any[]>>) => {
    if (searchTerm.length >= 3) {
      fetchPatients(medplum, defaultQuery(type, searchTerm))
        .then((response: any) => {
          const resourceList = response.data[type].map((resource: any) => {
            const givenName = resource?.name?.[0]?.given?.join(' ') || '';
            const familyName = resource?.name?.[0]?.family || '';
            return { value: resource.id, label: `${givenName} ${familyName}` };
          });
          setResource(resourceList);
        })
        .catch((error) => console.error(error));
    } else {
      setResource([]);
    }
  }, [medplum]);

  useEffect(() => {
    if (patientSearchTerm.length >= 3) {
      fetchResources('PatientList', patientSearchTerm, setPatients);
    }
  }, [patientSearchTerm, fetchResources]);

  useEffect(() => {
    if (practitionerSearchTerm.length >= 3) {
      fetchResources('PractitionerList', practitionerSearchTerm, setPractitioners);
    }
  }, [practitionerSearchTerm, fetchResources]);

  useEffect(() => {
    if (meetingType && patient) {
      setFormTitle('Meeting ready');
    } else {
      setFormTitle('Start a call');
    }
  }, [meetingType, patient]);


  return (
    <Modal
      opened={isOpen}
      onClose={closeDialog}
      title={
        <Flex align='center' gap={5}>
          <IconVideoPlus size={22} />
          <Title m={0} fw={600}>{formTitle}</Title>
        </Flex>
      }
      size="md"
    >
      <Flex align='center' justify='space-between' style={{ width: '45%' }}>
        <Text style={{ fontWeight: meetingType === 'schedule' ? '500' : '400', fontFamily: 'Inter' }}>Schedule</Text>
        <Switch
          defaultChecked
          color="#2F67AD"
          size="md"
          onChange={() => setMeetingType(meetingType === 'instant' ? 'schedule' : 'instant')}
          my="xl"
        />
        <Text style={{ fontWeight: meetingType === 'instant' ? '500' : '400', fontFamily: 'Inter' }}>Instant</Text>
      </Flex>
      <TextInput
        label="Title (Optional)"
        placeholder="Enter title"
        value={title}
        onChange={(e) => setTitle(e.currentTarget.value)}
        styles={(theme) => ({
          label: { marginBottom: theme.spacing.xs, fontFamily: 'Inter' },
        })}
        mb="md"
        radius="8px"
        size="md"
      />
      {activeUserType === 'Patient' && (
        <Select
          label="Practitioner"
          placeholder="Select"
          data={practitioners}
          value={practitioner}
          onChange={(value: string) => setPractitioner(value)}
          searchValue={practitionerSearchTerm}
          onSearchChange={setPractitionerSearchTerm}
          rightSection={<IconChevronDown size={14} />}
          styles={(theme) => ({
            label: { marginBottom: theme.spacing.xs, },
          })}
          mb="md"
          searchable
          radius="8px"
          size="md"
          required
        />
      )}
      {activeUserType === 'Practitioner' && (
        <Select
          label="Patient or Contact"
          placeholder="Select"
          data={patients}
          value={patient}
          onChange={(value: string) => setPatient(value)}
          searchValue={patientSearchTerm}
          onSearchChange={setPatientSearchTerm}
          rightSection={<IconChevronDown size={14} />}
          styles={(theme) => ({
            label: { marginBottom: theme.spacing.xs, fontFamily: 'Inter' },
          })}
          mb="xl"
          searchable
          radius="8px"
          size="md"
          required
        />
      )}
      <Select
        value={sdkType}
        label="Add video call"
        placeholder="Select"
        data={sdkOptions}
        icon={icon}
        onChange={(value: string) => setSdkType(value)}
        itemComponent={ItemComponent}
        rightSection={<IconChevronDown size={14} />}
        styles={(theme) => ({
          label: { marginBottom: theme.spacing.xs, fontFamily: 'Inter' },
        })}
        mb="xl"
        radius="8px"
        size="md"
      />

      <Flex gap={8} mt={10}>
        <Button size="md" onClick={closeDialog} bg="white" radius={4} variant="default" fullWidth>
          Cancel
        </Button>
        <Button
          size="md"
          onClick={handleStartCall}
          bg="#2F67AD"
          radius={4}
          disabled={isStarting || !meetingType || (activeUserType === 'Patient' && !practitioner) || (activeUserType === 'Practitioner' && !patient)}
          fullWidth
        >
          {isStarting ? <Text>Starting call...</Text> : 'Start video call'}
        </Button>
      </Flex>
    </Modal>
  );
};
