/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Grid, Text, Avatar } from '@mantine/core';
import { IconClockHour3 } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import { fetchPatientDetails } from '../../../fhirApi';
import { useMedplum } from '@medplum/react';

interface ClinicalNotesProps {
  appointmentData: any;
}

const PatientDetails: React.FC<ClinicalNotesProps> = ({ appointmentData }) => {
  const medplum = useMedplum();
  const [patientData, setPatientData] = useState<{ name: string }>({
    name: '',
  });
  const navigateToPatient = () => {
    localStorage.setItem('isClinicalNote', 'clinicalNotes');
    const patientURL = `${'/Patient/patient-dashboard/' + appointmentData?.patientID}`;
    window.open(patientURL, '_blank');
  };

  useEffect(() => {
    const graphqlQuery = `${
      '{ Patient(id: "' +
      appointmentData?.patientID +
      '") { resourceType id name { given family } address { line city state country } telecom {system value} gender birthDate identifier { value } } }'
    }`;
  
    fetchPatientDetails(medplum, graphqlQuery)
      .then((response: any) => {
        setPatientData({
          name: response?.data.Patient.name[0].given
        });
      })
      .catch((error: any) => {
        console.error('Error fetching data:', error);
      });
  }, []);


  const dateFormate = (inputDate: string | undefined): string => {
    if (!inputDate) {
      return 'Invalid Date';
    }
    const date = new Date(inputDate);

    const options: any = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
      timeZone: 'UTC',
    };

    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date || '');
    return formattedDate.replace(',', ',');
  };
  return (
    <Grid>
      <Grid.Col lg={2.5} className="avt-section">
        <Avatar
          src="../../../../img/dashboard-assets/profile.svg"
          radius="xl"
          size="lg"
          sx={{ display: 'block', margin: 'auto' }}
        />
        <Text className="prf-head">{appointmentData?.name || patientData.name}</Text>
        <Text component="span">{appointmentData?.email || '-'}</Text>
      </Grid.Col>
      <Grid.Col lg={9.5} className="profile-section">
        <Grid>
          <Grid.Col lg={4} pl={40}>
            <Text component="h6" className="label">
              Date & Time
            </Text>
            <Text component="span">{dateFormate(appointmentData?.startDate)}</Text>
          </Grid.Col>
          <Grid.Col lg={4} pl={40}>
            <Text component="h6" className="label">
              Practitioner
            </Text>
            <Text component="span">{appointmentData?.practitioner}</Text>
          </Grid.Col>
          <Grid.Col lg={4} pl={40}>
            <Text component="h6" className="label">
              Status
            </Text>
            <Text component="span">{appointmentData?.status}</Text>
          </Grid.Col>
          <Grid.Col lg={4} pl={40} mt={10}>
            <Text component="h6" className="label">
              Appointment Type
            </Text>
            <Text component="span">{appointmentData?.appointmentType}</Text>
          </Grid.Col>
          <Grid.Col lg={4} pl={40} mt={10} sx={{ display: 'flex', alignItems: 'center' }}>
            <IconClockHour3 style={{ color: '#3673B6' }} size={18} />
            <button
              style={{
                color: '#333',
                textDecoration: 'none',
                fontWeight: 600,
                background: 'transparent',
                border: 'none',
                cursor: 'pointer',
              }}
              onClick={navigateToPatient}
            >
              Clinical Notes History
            </button>
          </Grid.Col>
        </Grid>
      </Grid.Col>
    </Grid>
  );
};

export default PatientDetails;
