/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-debugger */
import {
  Box,
  Card,
  Grid,
  createStyles,
  Text,
  Title,
  Center,
  NumberInput,
  MultiSelect,
  Button,
  Textarea,
  Select,
} from '@mantine/core';
import { useEffect, useState } from 'react';
import { fetchPatients } from './fhirApi';
import { useMedplum } from '@medplum/react';
import { IconChevronDown } from '@tabler/icons-react';

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    height: '100%',
  },

  section: {
    padding: theme.spacing.md,
  },

  footer: {
    padding: theme.spacing.md,
    borderTop: `1px solid #EEF1F6 !important`,
  },

  header: {
    padding: theme.spacing.md,
    borderBottom: `1px solid #EEF1F6 !important`,
  },

  scrollbar: {
    overflowY: 'auto',
    scrollbarWidth: 'thin',
    scrollbarColor: '#ccc #f3f3f3',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#ccc',
      borderRadius: '8px',
      '&:hover': {
        background: '#aaa',
      },
    },
  },

  dBlock: {
    [theme.fn.smallerThan('sm')]: {
      display: 'block',
    },
  },

  button: {
    backgroundColor: '#1b5390',
    '&:disabled': {
      backgroundColor: '#d9d9d9',
    },
  },

  title: {
    fontSize: '18px',
    fontWeight: 600,
    color: '#444444',
  },

  filtersTitle: {
    fontSize: '14px',
    fontWeight: 600,
    color: '#444444',
  },
}));

const defaultQuery = `{
    PatientList(_count: 100) {
      resourceType
      id
      gender
      birthDate
      name {
        family
        given
      }
      MedicationRequestList(_reference: patient) {
          resourceType
          id
          medicationReference {
            display
          }
          status
            encounter {
            id
          }
          authoredOn
      }
        ConditionList(_reference:patient) {
        resourceType
        id
        encounter {
         id
        }
        code {
          coding {
            system
            code
          }
          text
        }
      }
    }
  }`;

export function Filters(): JSX.Element {
  const { classes } = useStyles();
  const medplum = useMedplum();
  const [query, setQuery] = useState(defaultQuery);
  const [patients, setPatients] = useState('');
  const [gender, setGender] = useState('male');
  const [minAge, setMinAge] = useState<number>(70);
  const [maxAge, setMaxAge] = useState<number>(80);
  const [conditions, setConditions] = useState<string[]>(['Type 2 diabetes mellitus (CMS/HCC)']);
  const [medications, setMedications] = useState<string[]>(['metFORMIN (GLUCOPHAGE) tablet']);
  const [allergies, setAllergies] = useState<string[]>([]);
  const [disabledApply, setDisabled] = useState(true);
  const [required, setRequired] = useState(false);

  const genderList = [
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' },
  ];
  const conditionsList = [
    'Type 2 diabetes mellitus (CMS/HCC)',
    'Heart disease',
    'Past heart attack',
    'Broken arm',
    'Arm Injury',
    'Annual Exam',
    'Diabetes mellitus (CMS/HCC)',
    'Diabetes (CMS/HCC)',
    'Environmental allergies',
    'Broken arm, left, open, initial encounter',
  ];
  const medicationsList = [
    'metFORMIN (GLUCOPHAGE) tablet',
    'ibuprofen (MOTRIN) tablet',
    'raNITIdine (ZANTAC) tablet',
    'aspirin tablet',
    'cephalexin (KEFLEX) capsule',
    'dilTIAZem (CARDIZEM) tablet',
    'measles, mumps and rubella (MMR) vaccine',
  ];
  const allergyList = ['foods', 'animals', 'dust', 'dust mites', 'medications', 'latex'];

  const onConditionsChange = (event: any) => {
    setPatients('');
    const condition = event;
    setConditions(condition);
  };

  const onMedicationsChange = (event: any) => {
    setPatients('');
    const medication = event;
    setMedications(medication);
  };

  useEffect(() => {
    if (query.trim() !== '') {
      setDisabled(false);
    }
  }, [query]);

  const handleSearchChange = (event: any) => {
    const newSearchTerm = event.target.value;
    setPatients('');
    setDisabled(true);

    if (newSearchTerm === '') {
      setQuery('');
      setRequired(true);
      console.log('Enter query');
    } else {
      setRequired(false);
      setQuery(newSearchTerm);
    }
  };

  const onFilterApply = () => {
    const filterOptions: any = {
      conditions: null,
      medications: null,
    };

    if (maxAge !== 0) {
      filterOptions.minAge = minAge;
    }

    if (maxAge !== 0) {
      filterOptions.maxAge = maxAge;
    }

    if (gender !== null) {
      filterOptions.gender = gender;
    }

    if (conditions?.length > 0) {
      filterOptions.conditions = conditions;
    }

    if (medications?.length > 0) {
      filterOptions.medications = medications;
    }

    if (allergies?.length > 0) {
      filterOptions.allergies = allergies;
    }

    if (query.trim() !== '') {
      fetchPatients(medplum, query, filterOptions)
        .then((response: any) => {
          setPatients(response);
        })
        .catch((error: any) => {
          console.error('Error fetching data:', error);
        });
    }
  };

  return (
    <>
      <Card
        mx="lg"
        my="sm"
        display="flex"
        shadow="xs"
        radius="sm"
        p="sm"
        sx={{ justifyContent: 'space-between', alignItems: 'baseline', flexDirection: 'row' }}
        className="bg-main-light"
      >
        <Center>
          <Text variant="sm" size="16px" fw="bold">
            {'Advanced Search'}
          </Text>
        </Center>
      </Card>
      <Box px="lg" py="sm" display="block">
        <Card withBorder radius="md" className={classes.card}>
          <Card.Section className={classes.section} p="lg">
            <Grid>
              <Grid.Col lg={3}>
                <Title className={classes.filtersTitle}>Age Range :</Title>
                <Center style={{ gap: 10 }}>
                  <NumberInput
                    placeholder="Min age"
                    min={0}
                    onChange={(value: number) => {
                      setPatients('');
                      setMinAge(value);
                    }}
                    value={minAge}
                  />
                  <NumberInput
                    placeholder="Max age"
                    min={minAge + 1}
                    onChange={(value: number) => {
                      setPatients('');
                      setMaxAge(value);
                    }}
                    value={maxAge}
                  />
                </Center>
              </Grid.Col>
              <Grid.Col lg={3}>
                <Title className={classes.filtersTitle}>Gender :</Title>
                <Select
                  placeholder="Select"
                  onChange={(value: any) => {
                    setPatients('');
                    setGender(value);
                  }}
                  value={gender}
                  data={genderList}
                  rightSection={<IconChevronDown size="1rem" />}
                  allowDeselect
                />
              </Grid.Col>
              <Grid.Col lg={3}>
                <Title className={classes.filtersTitle}>Conditions :</Title>
                <MultiSelect
                  placeholder="Select items"
                  data={conditionsList}
                  rightSection={<IconChevronDown size="1rem" />}
                  onChange={onConditionsChange}
                  defaultValue={conditions}
                />
              </Grid.Col>
              <Grid.Col lg={3}>
                <Title className={classes.filtersTitle}>Medications :</Title>
                <MultiSelect
                  placeholder="Select items"
                  data={medicationsList}
                  rightSection={<IconChevronDown size="1rem" />}
                  onChange={onMedicationsChange}
                  defaultValue={medications}
                />
              </Grid.Col>
              <Grid.Col lg={3}>
                <Title className={classes.filtersTitle}>Allergies :</Title>
                <MultiSelect
                  placeholder="Select items"
                  data={allergyList}
                  rightSection={<IconChevronDown size="1rem" />}
                  onChange={(value: any) => {
                    setPatients('');
                    setAllergies(value);
                  }}
                />
              </Grid.Col>
            </Grid>
          </Card.Section>

          <Card.Section className={classes.section} px="lg" py="sm" style={{ borderBottom: '0px !important' }}>
            <Grid>
              <Grid.Col lg={6}>
                <Center style={{ justifyContent: 'space-between' }}>
                  <Title className={classes.title}>Input Query :</Title>
                  <Button onClick={onFilterApply} disabled={disabledApply} className={classes.button}>
                    Run
                  </Button>
                </Center>
              </Grid.Col>
              <Grid.Col lg={6}>
                <Center style={{ justifyContent: 'flex-start' }}>
                  <Title className={classes.title}>Output :</Title>
                </Center>
              </Grid.Col>
            </Grid>
          </Card.Section>

          <Card.Section className={classes.section} p="lg" style={{ borderBottom: '0px !important' }}>
            <Grid>
              <Grid.Col lg={6}>
                <Textarea
                  autosize
                  minRows={20}
                  maxRows={20}
                  value={query}
                  placeholder="Enter your query"
                  withAsterisk
                  onChange={(event) => handleSearchChange(event)}
                  radius="md"
                  error={required}
                  className={classes.scrollbar}
                />
              </Grid.Col>
              <Grid.Col lg={6}>
                <Card withBorder radius="md" className={classes.scrollbar} style={{ height: '460px' }}>
                  <Card.Section className={classes.section} p="lg" style={{ whiteSpace: 'pre' }}>
                    {patients ? `${JSON.stringify(patients, null, 4)}` : ''}
                  </Card.Section>
                </Card>
              </Grid.Col>
            </Grid>
          </Card.Section>
        </Card>
      </Box>
    </>
  );
}
