/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-unused-expressions */
import React, { useState, useRef, useEffect } from 'react';
import { Button, Grid, Text, Box, Accordion, Avatar, Card, Input } from '@mantine/core';
import '../AudioRecorder.css';
import axios from '../../../utils/axios';
import { useMedplum } from '@medplum/react';
import TitleComponent from '../../../components/TitleComponent';
import { useParams, useLocation } from 'react-router-dom';
import ClinicalNote from './ClinicalNote';
import AudioTranscription from './AudioTranscription';
import ClinicalNotePreview from './ClinicalNotePreview';
import { getEHRTokenFromLocalStorage, formatTime } from '../../../utils/util';
import PatientDetails from './PatientDetails';
import { getConfig } from '../../../config';
import { Socket, io } from 'socket.io-client';
import { FaPlay } from 'react-icons/fa6';
import { IoMdPause } from 'react-icons/io';
import ConsultationModal from './ConsultationModal';
import { createNewSession } from '../../../fhirApi';
import { showNotification } from '@mantine/notifications';

export function Consultation(): JSX.Element {
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const intervalRef = useRef<any>(null);
  const [recording, setRecording] = useState(false);
  const [instructionRecording, setinstructionRecording] = useState(false);
  const [document, setDocument] = useState<any>('');
  const [clinicalNote, setClinicalNote] = useState<string>('');
  const [timer, setTimer] = useState(0);
  const medplum = useMedplum();
  const appointmentId = useParams().id;
  const [isClinicalNote, setisClinicalNote] = useState<boolean>(false);
  const [isAudioTranscription, setisAudioTranscription] = useState<boolean>(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
  const [isCheckInstruction, setIsCheckInstruction] = useState<boolean>(false);
  const [isClinicalNotePreview, setisClinicalNotePreview] = useState<boolean>(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);
  const [instruction, setInstruction] = useState('');
  const [preClinicalNote, setPreClinicalNote] = useState<string>('');
  const [isWaiting, setIsWaiting] = useState(false);
  const [liveTranscript, setLiveTranscript] = useState<string[]>([]);
  const [finalTranscript, setFinalTranscript] = useState<string>('');
  const [isPaused, setIsPaused] = useState(false);
  const [isStopped, setIsStopped] = useState(true);
  const [isConsultationModalOpen, setIsConsultationModalOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [lastUsedInstruction, setLastUsedInstruction] = useState('');

  const [modalSaveHandler, setModalSaveHandler] = useState<
    (patientData: { id?: string; name: string; birthDate: string }) => void
  >(() => {});

  const [patientData, setPatientData] = useState<{ id: string; name: string; birthDate: string } | null>(null);
  const resourceID = medplum.getActiveLogin()?.profile?.reference;
  const parts: any = resourceID?.split('/');
  const practitionerId = parts?.[1];
  const practitionerName = medplum.getActiveLogin()?.profile?.display;
  const [sessionStartTime, setSessionStartTime] = useState<Date | undefined>(undefined);
  let appointment_Id = appointmentId;

  // const recordTypes = {
  //   STARTED: 'started',
  //   NOTSTARTED: 'not_started',
  //   PAUSED: 'paused',
  //   STOPPED: 'stopped',
  //   FINISHED: 'finished',
  // };

  const [socket, setSocket] = useState<Socket | null>(null);
  // const [audioState, setAudioState] = useState(recordTypes.NOTSTARTED);
  const baseUrl = getConfig()?.baseUrl;
  const transcribeUrl = `${baseUrl}transcribe`;

  const ProfileIcon = <Avatar src="../../../../static/img/dashboard-assets/profile.svg" radius="xl" size="sm" />;
  const location = useLocation();
  const { appointmentData } = location.state || {};
  // Define the criteria for matching the URL
  const isAiConsultations = location.pathname === '/ai-consultations' && location.search === '';

  const [messages, setMessages] = useState([
    {
      role: 'system',
      content: 'You are a helpful assistant.',
    },
    {
      role: 'user',
      content: '',
    },
  ]);

  useEffect(() => {
    if (socket) {
      socket.on('connect', () => {
        console.log('Connected to server');
      });

      socket.on('disconnect', () => {
        console.log('Disconnected from server');
      });
    }

    return () => {
      if (socket) {
        socket.off('connect');
        socket.off('disconnect');
      }
    };
  }, [socket]);

  const startRecording = (event: string, retryCount = 3) => {
    console.log('Starting recording...');
    setSessionStartTime(new Date());
    setIsPaused(false);

    if (event === 'instruction') {
      setisAudioTranscription(false);
      setisClinicalNote(true);
      showNotification({
        message: 'Recording instruction',
        color: 'green',
      });
    } else {
      setisAudioTranscription(true);
      setisClinicalNote(false);
    }

    if (event !== 'resume' && event !== 'instruction') {
      setLiveTranscript([]);
    }

    if (isStopped && event !== 'instruction') {
      setTimer(0);
      setIsStopped(false);
    }

    if (event === 'StartRecording') {
      resetClinicalNote();
    }

    // setRecording(true);

    if (event === 'resume') {
      setIsPaused(false);
      showNotification({
        message: 'Recording resumed',
        color: 'green',
      });
    } else {
      showNotification({
        message: 'Recording started',
        color: 'green',
      });
    }

    let socket: Socket;
    try {
      socket = io(transcribeUrl, {
        transports: ['polling'],
        reconnection: true,
        reconnectionAttempts: 3,
        reconnectionDelay: 1000,
      });

      setSocket(socket);
      setIsButtonDisabled(true);

      socket.on('connect', () => {
        console.log('Event - Connected to server: ' + socket.id);
        console.log('Message - Socket Id: ' + socket.id);
      });

      // Add an event listener for the 'error' event on the socket
      socket.on('error', (error) => {
        console.error('Event - Socket error: ' + socket.id, error);
        // Disconnect the current socket
        socket.disconnect();
        // Reinitialize the socket after a delay
        //console.log('Message - Reconnecting to transcription service...');
        //setTimeout(() => startTranscription(showFlag), 1000);
      });

      socket.on('dg-connection-error', (message) => {
        console.error('Event - transcription service connection error:' + socket.id, message);
        // setAudioState(recordTypes.NOTSTARTED);
        setIsWaiting(false);
        setIsButtonDisabled(false);
      });

      socket.on('dg-reconnect-failed', (message) => {
        console.error('Event - transcription service re-connection error:' + socket.id, message);
        // setAudioState(recordTypes.NOTSTARTED);
        setIsWaiting(false);
        setIsButtonDisabled(false);
      });
    } catch (error) {
      setIsWaiting(false);
      setIsButtonDisabled(false);
      console.error('Message - Error creating socket connection:', error);
      if (retryCount > 0) {
        console.log(`Retrying connecting socket... (${retryCount} retries left)`);
        //setTimeout(() => startTranscription(showFlag, retryCount - 1), 1500);
      } else {
        console.log('Failed to start transcription service after 3 retries. Please retry after sometime.');
      }
      return;
    }

    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        const mediaRecorder = new MediaRecorder(stream);
        mediaRecorderRef.current = mediaRecorder;
        console.log('Message - MediaRecorder created');

        if (event !== 'instruction') {
          intervalRef.current = setInterval(() => {
            setTimer((prevTimer) => prevTimer + 1);
          }, 1000);
        }

        socket?.on('can-open-mic', () => {
          console.log('Event - Opening mic...: ' + socket.id);
          if (mediaRecorder.state === 'recording') {
            console.log('Message - Already recording');
            return;
          }

          if (mediaRecorder.state === 'inactive') {
            mediaRecorder.start(200);
          } else if (mediaRecorder.state === 'paused') {
            mediaRecorder.resume();
          } else {
            mediaRecorder.start(200);
          }
          socket.emit('can-open-mic-ack');
        });

        mediaRecorder.ondataavailable = (e) => {
          if (e.data.size > 0) {
            socket.emit('microphone-stream', e.data);
            console.log('Sending stream...');
          }
        };

        socket.on('close-mic', () => {
          console.log('Closing mic');
          mediaRecorder.stop();
          socket.disconnect();
        });

        socket.on('transcript-result', (socketId, jsonFromServer) => {
          console.log('JSON from server', jsonFromServer);

          updateTranscripts(jsonFromServer);
        });
        socket.on('disconnect', () => {
          console.log('Disconnected from server');
          mediaRecorder.stop();
          socket.disconnect();
        });

        event === 'StartRecording' ? setRecording(true) : setinstructionRecording(true);
      })
      .catch((error) => {
        console.error('Error accessing microphone:', error);
        console.log('Error accessing microphone:', error);
      });
  };

  const updateTranscripts = (transcript: string) => {
    setLiveTranscript((prevTranscripts) => [...prevTranscripts, transcript]);

    if (isCheckInstruction) {
      setInstruction((prevInstruction) => {
        // Otherwise, append the new transcript without adding a space
        return prevInstruction + ' ' + transcript;
      });
    }

    console.log('Transcript:', transcript);
  };
  console.log('Live Transcript', liveTranscript);

  useEffect(() => {
    const saveSession = async () => {
      if (patientData) {
        console.log('Patient data:', patientData);
        await onConfirm(patientData);
        setIsConsultationModalOpen(false);
        // setisClinicalNotePreview(true); // Open ClinicalNotePreviews
      } else {
        console.error('Patient data is not available');
      }
    };
    saveSession();
  }, [patientData]);

  const onConfirm = async (patientData: any) => {
    if (!finalTranscript?.length) {
      console.error('Transcripts are not available.');
      return;
    }
    setIsSaving(true);
    const payload = {
      patientId: patientData?.id,
      patientName: patientData.name,
      practitionerName: practitionerName,
      birthDate: patientData.birthDate,
      transcript: finalTranscript,
      practitionerId: practitionerId,
      noteType: 'Consultation Note',
      sessionStartTime: sessionStartTime,
      sessionEndTime: new Date(),
    };

    if (patientData && finalTranscript?.length) {
      try {
        const response = await createNewSession(medplum, payload);
        if (response?.message) {
          appointment_Id = response.appointmentId;
          console.log('Notes will be generated in the background.');
        }
      } catch (error) {
        console.error('Error:', error);
        console.error('Failed to generate the notes.');
      }

      try {
        await sendAudioToBackend(finalTranscript);
        setIsSubmitDisabled(false);
        setIsSaving(false);
      } catch (error) {
        console.error('Error:', error);
        console.error('Failed to send audio to backend.');
        setIsSaving(false);
      }

      socket?.disconnect();
    }
  };

  const openConsultationModal = (): Promise<void> => {
    return new Promise((resolve) => {
      setIsConsultationModalOpen(true);

      const handleModalSave = (data: { id: string; name: string; birthDate: string }) => {
        console.log('Received patient data:', data);
        setPatientData(data);
        // setIsConsultationModalOpen(false);
        setIsSaving(true);
        resolve();
      };
      setModalSaveHandler(() => handleModalSave);
    });
  };

  const stopRecordingAndProcess = async (state: string) => {
    if (state === 'pause') {
      setIsPaused(true);
      setIsSubmitDisabled(false);
      showNotification({
        message: 'Recording has been paused',
        color: 'blue',
      });
    }

    socket?.disconnect();
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      clearInterval(intervalRef.current);

      const tracks = mediaRecorderRef.current.stream.getTracks();
      tracks.forEach((track) => {
        track.stop();
      });

      if (state !== 'pause') {
        setRecording(false);
        setinstructionRecording(false);

        if (state === 'StopRecording') {
          setIsStopped(true);
          setIsPaused(false);
          setIsWaiting(true);
          setInstruction('');
          showNotification({
            message: 'Recording has been stopped',
            color: 'red',
          });
          // Create the final transcript from the live transcripts
          const fTranscript = liveTranscript.join(' ');
          setFinalTranscript(fTranscript);
          console.log(finalTranscript);
          if (isAiConsultations) {
            console.log('Consultation Modal Open');
            await openConsultationModal();
            console.log('Consultation Modal Close');
            return;
          }
        } else {
          setinstructionRecording(false);
        }
      }
    }
  };

  useEffect(() => {
    const handleAudioSend = async () => {
      if (isStopped && finalTranscript && !isAiConsultations && clinicalNote === '') {
        console.log('Creating Clinical Notes by sending audio to backend', clinicalNote, isStopped, isAiConsultations);
        try {
          await sendAudioToBackend(finalTranscript);
          setIsSubmitDisabled(false);
        } catch (error) {
          console.error('Failed to send audio to backend:', error);
        }
      }
    };

    handleAudioSend();
  }, [isStopped, finalTranscript]);

  const handleInputChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setInstruction(event.target.value);
    // setInstructionText(event.target.value);
  };

  const activeTabs = (event: string) => {
    if (event === 'note') {
      setisClinicalNote(true);
      setisAudioTranscription(false);
    } else if (event === 'audio') {
      setisAudioTranscription(true);
      setisClinicalNote(false);
    }
  };

  const sendAudioToBackend = async (text: string): Promise<any> => {
    setClinicalNote(`Generating SOAP clinical note for the following conversation: ${text}`);
    const url = 'https://api.openai.com/v1/chat/completions';
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer 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',
    };

    messages[1].content = `Generate SOAP clinical note from the following conversation: ${text}
    /n Please provide a detailed SOAP note with the following structure also include the previous conversation if available:

      Subjective:
      [Provide subjective information]

      Objective:
      [Provide objective information]

      Assessment:
      [Provide assessment]

      Plan:
      [Provide plan]`;

    console.log('text', text);
    const data = {
      model: 'gpt-3.5-turbo-1106',
      messages: messages,
      stream: false,
    };

    const result = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(data),
    });

    const chat = await result.json();
    const textResponse = chat.choices[0].message.content;
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        role: 'assistant',
        content: textResponse,
      },
    ]);
    setIsWaiting(false);
    setClinicalNote((prevNote) => textResponse);
    setisClinicalNote(true);
    setisAudioTranscription(false);

    // await getConversation(text);

    const token = await medplum.getAccessToken();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        ehr_token: getEHRTokenFromLocalStorage(),
      },
    };

    const postBody: any = {};
    postBody.clinicalnote = textResponse;
    postBody.appointmentId = appointment_Id;

    try {
      console.log('Posting clinical note to backend', postBody, 'Config', config);
      const response = await axios.post(`/api/pragmaconnect/create-clinical-note`, postBody, config);
      setDocument(response.data.document);
      setIsCheckInstruction(true);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const generateDynamicNote = async () => {
    const url = 'https://api.openai.com/v1/chat/completions';
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer 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',
    };

    setinstructionRecording(false);
    await stopRecordingAndProcess('instruction');
    setFinalTranscript((prevTranscript) => prevTranscript + ' ' + instruction)

    const userMessage = {
      role: 'user',
      content: `${instruction}`,
    };

    const conversation = [...messages, userMessage];
console.log("conversation",conversation);
    setMessages(conversation);

    const data = {
      model: 'gpt-3.5-turbo-1106',
      messages: conversation,
      stream: false,
    };

    const result = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(data),
    });

    const chat = await result.json();
    const textResponse = chat.choices[0].message.content;

    const assistantMessage = {
      role: 'assistant',
      content: textResponse,
    };

    const responseConversation = [...conversation, assistantMessage];

    setLastUsedInstruction(instruction);
    setMessages(responseConversation);
    setPreClinicalNote(clinicalNote); // Store the previous note
    // Clear the input field, but keep the instruction for display

    setClinicalNote(textResponse);

    setInstruction('');
  };

  useEffect(() => {
    if (isCheckInstruction) {
      updateClinicalNote();
    }
  }, [clinicalNote]);

  const updateClinicalNote = async () => {
    const token = await medplum.getAccessToken();

    const postData: any = {};
    postData.clinicalnote = clinicalNote;
    postData.appointmentId = appointmentId;
    postData.documentReferenceId = document?.id;

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        ehr_token: getEHRTokenFromLocalStorage(),
      },
    };

    const updateBody: any = {};
    updateBody.clinicalnote = clinicalNote;
    updateBody.documentId = document?.id;
    if (document?.id) {
      try {
        console.log('update-clinical-note', updateBody, 'Config', config);
        await axios.put(`/api/pragmaconnect/update-clinical-note`, updateBody, config);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        console.log('create-clinical-note', postData, 'Config', config);
        const postBody: any = {};
        postBody.clinicalnote = clinicalNote;
        postBody.appointmentId = appointmentId;
        await axios.post(`/api/pragmaconnect/create-clinical-note`, postBody, config);
      } catch (error) {
        console.log(error);
      }
    }

    showNotification({ color: 'green', message: 'Your Clinical Note has been submitted successfully.' });

    // const response: any = await axios.post(`/ehr/epic/create-clinical-note-appointment`, postData, config);
    // if (response?.data?.docRefId) {
    //   showNotification({ color: 'green', message: 'Your Clinical Note has been submitted successfully.' });

    // } else {
    //   showNotification({ color: 'green', message: 'Your Clinical Note has been submitted successfully.' });

    // }
  };

  const resetClinicalNote = () => {
    setLiveTranscript([]);
    setRecording(false);
    setIsButtonDisabled(false);
    setClinicalNote('');
    setIsSubmitDisabled(true);
    setIsCheckInstruction(false);
    setTimer(0);
    setInstruction('');
  };

  return (
    <>
      <Box px="lg" pt="sm" sx={{ paddingBottom: 0 }}>
        <TitleComponent title={'Consultations'} />
      </Box>
      <Box px="lg" pt="sm" sx={{ paddingTop: '0.4rem' }}>
        {!isAiConsultations && (
          <Accordion variant="contained" defaultValue="profile">
            <Accordion.Item value="profile">
              <Accordion.Control>Patient Details</Accordion.Control>
              <Accordion.Panel>
                <PatientDetails appointmentData={appointmentData} />
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        )}
        <Accordion variant="contained" defaultValue="consultation" mt={20} className="consultation">
          <Accordion.Item value="consultation">
            <Accordion.Control>Consultation Session</Accordion.Control>
            <Accordion.Panel>
              <Card display="block" shadow="xs" radius="sm" p="xs" className="bg-main-light titlebar">
                <Grid>
                  <Grid.Col lg={6} className="titlebar-content">
                    {!recording ? (
                      <Button className="record-btn" variant="default" onClick={() => startRecording('StartRecording')}>
                        <img src="../../../../img/dashboard-assets/mic.svg" />
                        Start Recording
                      </Button>
                    ) : (
                      <>
                        <Button
                          className="record-btn"
                          variant="default"
                          onClick={() => stopRecordingAndProcess('StopRecording')}
                        >
                          <img src="../../../../img/dashboard-assets/mic.svg" />
                          Stop Recording
                        </Button>

                        {!isPaused ? (
                          <Button
                            className="record-btn"
                            ml="sm"
                            variant="default"
                            onClick={() => stopRecordingAndProcess('pause')}
                          >
                            <IoMdPause />
                          </Button>
                        ) : (
                          <Button
                            className="record-btn"
                            ml="sm"
                            variant="default"
                            onClick={() => startRecording('resume')}
                          >
                            <FaPlay />
                          </Button>
                        )}
                      </>
                    )}
                    {isButtonDisabled && (
                      <Text mt={10} ml={15}>
                        {formatTime(timer)}
                      </Text>
                    )}
                  </Grid.Col>
                  <Grid.Col lg={6} className="titlebar-content" style={{ justifyContent: 'flex-end' }}>
                    {isButtonDisabled && (
                      <Grid sx={{ alignItems: 'center' }}>
                        <Button
                          className="clinical-btn"
                          onClick={() => activeTabs('note')}
                          sx={{
                            backgroundColor: `${isClinicalNote ? '#3673B6' : '#fff'}`,
                            color: `${isClinicalNote ? '#fff' : '#333333'}`,
                            '&:hover': { backgroundColor: `${isClinicalNote ? '#3673B6' : '#fff'}` },
                          }}
                          // disabled={isClinicalNoteDisabled}
                        >
                          Clinical Notes
                        </Button>
                        <Button
                          className="audio-btn"
                          onClick={() => activeTabs('audio')}
                          sx={{
                            backgroundColor: `${isAudioTranscription ? '#3673B6' : '#fff'}`,
                            color: `${isAudioTranscription ? '#fff' : '#333333'}`,
                            '&:hover': { backgroundColor: `${isAudioTranscription ? '#3673B6' : '#fff'}` },
                          }}
                        >
                          Audio Transcription
                        </Button>
                      </Grid>
                    )}
                  </Grid.Col>
                </Grid>
              </Card>
              {isClinicalNote ? (
                <>
                  <ClinicalNote
                    clinicalNote={clinicalNote}
                    instruction={instruction}
                    lastUsedInstruction={lastUsedInstruction}
                    preClinicalNote={preClinicalNote}
                    loading={isWaiting}
                  />
                  {isCheckInstruction && (
                    <>
                      <Text sx={{ fontSize: '16px', fontWeight: 500, margin: '8px 0' }}>Instructions</Text>
                      <Input
                        icon={ProfileIcon}
                        placeholder="Write / Record your instruction"
                        onChange={handleInputChange}
                        value={instruction}
                        rightSection={
                          <div className="d-flex g-12 input-icon">
                            {!instructionRecording ? (
                              <Avatar
                                src="../../../../img/dashboard-assets/mic-icon.svg"
                                radius="xl"
                                size="sm"
                                sx={{ cursor: 'pointer' }}
                                onClick={() => {
                                  startRecording('instruction');
                                }}
                              />
                            ) : (
                              <Avatar
                                src="../../../../img/dashboard-assets/ic_stop.svg"
                                radius="xl"
                                size="sm"
                                sx={{ cursor: 'pointer' }}
                                onClick={() => {
                                  stopRecordingAndProcess('instruction');
                                }}
                              />
                            )}
                            <Avatar
                              src="../../../../img/dashboard-assets/upload-icon.svg"
                              size="sm"
                              sx={{ cursor: 'pointer' }}
                              onClick={() =>
                                instruction.trim()
                                  ? generateDynamicNote()
                                  : showNotification({
                                      color: 'red',
                                      message: 'Please enter some instruction to generate a dynamic note',
                                    })
                              }
                            />
                          </div>
                        }
                      />
                    </>
                  )}
                </>
              ) : (
                <AudioTranscription audioTranscription={liveTranscript} />
              )}
              <Grid pl={10} mt={20}>
                <Button
                  sx={{ backgroundColor: '#BDBDBD' }}
                  onClick={() => {
                    resetClinicalNote();
                    setisAudioTranscription(true);
                    setisClinicalNote(false);
                  }}
                  disabled={isSubmitDisabled}
                >
                  Cancel
                </Button>

                <Button
                  onClick={() => setisClinicalNotePreview(true)}
                  sx={{ marginLeft: '10px', backgroundColor: '#3673B6' }}
                  disabled={!(clinicalNote.length > 0)}
                >
                  Preview
                </Button>
              </Grid>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
        {isClinicalNotePreview && (
          <ClinicalNotePreview
            isOpen={isClinicalNotePreview}
            handleClose={() => setisClinicalNotePreview(false)}
            clinicalNote={clinicalNote}
            appointmentId={appointment_Id}
            document={document}
          />
        )}

        {isConsultationModalOpen && (
          <ConsultationModal
            isOpen={isConsultationModalOpen}
            onClose={() => {
              setIsConsultationModalOpen(false);
              resetClinicalNote();
            }}
            onSave={modalSaveHandler}
            isSaving={isSaving}
          />
        )}
      </Box>
    </>
  );
}
