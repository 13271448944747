import { Grid, TextInput, Text, Input } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import countryCode from '../countryCode.json';
import { useEffect, useState } from 'react';

interface OrganizationAdminProps {
  orgListResponse?: any;
  onUpdateContactInfo: (formData: any) => void;
}
const OrganizationAdmin: React.FC<OrganizationAdminProps> = ({ orgListResponse, onUpdateContactInfo }) => {
  const [formData, setFormData] = useState<any>({
    firstName: orgListResponse?.firstName,
    lastName: orgListResponse?.lastName,
    email: orgListResponse?.email,
    contactNo: orgListResponse?.contactNo,
    contactCountryCode: orgListResponse?.contactCountryCode,
  });
  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSelectChange = (event: any) => {
    const { name, value } = event.target;
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  };
  useEffect(() => {
    onUpdateContactInfo(formData);
  }, [formData, onUpdateContactInfo]);
  return (
    <>
      <Grid sx={{ margin: '10px 0 0', alignItems: 'center' }}>
        <Grid.Col span={12} lg={3} sx={{ padding: 0 }}>
          <Text style={{ fontSize: '14px', color: '#272D41' }}>
            Name<span style={{ color: 'red' }}>*</span>
          </Text>
        </Grid.Col>
        <Grid.Col span={12} lg={4.5}>
          <TextInput
            value={formData?.firstName}
            placeholder="First Name"
            name="firstName"
            onChange={handleInputChange}
          />
        </Grid.Col>
        <Grid.Col span={12} lg={4.5}>
          <TextInput value={formData?.lastName} placeholder="Last Name" name="lastName" onChange={handleInputChange} />
        </Grid.Col>
      </Grid>
      <Grid sx={{ margin: '10px 0 0', alignItems: 'center' }}>
        <Grid.Col span={12} lg={3} sx={{ padding: 0 }}>
          <Text style={{ fontSize: '14px', color: '#272D41' }}>
            Email<span style={{ color: 'red' }}>*</span>
          </Text>
        </Grid.Col>
        <Grid.Col span={12} lg={9}>
          <TextInput
            type="email"
            value={formData?.email}
            placeholder="Email"
            name="email"
            onChange={handleInputChange}
          />
        </Grid.Col>
      </Grid>
      <Grid sx={{ margin: '10px 0 0', alignItems: 'center' }}>
        <Grid.Col span={12} lg={3} sx={{ padding: 0 }}>
          <Text style={{ fontSize: '14px', color: '#272D41' }}>
            Phone No<span style={{ color: 'red' }}>*</span>
          </Text>
        </Grid.Col>
        <Grid.Col span={12} lg={4.5}>
          <Input
            component="select"
            rightSection={<IconChevronDown size={14} stroke={1.5} />}
            name="contactCountryCode"
            value={formData.contactCountryCode}
            onChange={handleSelectChange}
          >
            <option>Select</option>
            {countryCode.map((e, i) => (
              <option key={i} value={e.value}>
                {e.label}
              </option>
            ))}
          </Input>
        </Grid.Col>
        <Grid.Col span={12} lg={4.5}>
          <TextInput
            value={formData?.contactNo}
            placeholder="Contact No"
            name="contactNo"
            onChange={handleInputChange}
            maxLength={10}
          />
        </Grid.Col>
      </Grid>
    </>
  );
};

export default OrganizationAdmin;
