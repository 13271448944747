import { Card, Text, Group, createStyles, Center } from '@mantine/core';
import { IconArrowRight } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import { IconAndDetails, IconAndText } from './ProvidersDashboard';

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
  },

  section: {
    padding: theme.spacing.sm,
    borderBottom: `1px solid #EEF1F6 !important`,
  },

  footer: {
    padding: theme.spacing.md,
    borderTop: `1px solid #EEF1F6 !important`,
  },

  scrollbar: {
    overflow: 'hidden',
    '&:hover': {
      overflowY: 'auto',
      scrollbarWidth: 'thin',
      scrollbarColor: '#ccc #f3f3f3',
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#ccc',
        borderRadius: '8px',
        '&:hover': {
          background: '#aaa',
        },
      },
    },
  },
}));

const data = [
  {
    id: '1',
    name: 'Surgery',
    doctors: 'Dr. Krista Wiegand',
    address: '320, Weston St. NY',
    clinic: 'Southwest Family Medicine',
    dateTime: '2 Aug 2023, 10:00 am',
    time: '10:00 am',
  },
];

export function Procedures() {
  const { classes } = useStyles();

  const organisationDetails = data.map((details) => (
    <Card.Section key={details.id} display="flex" className={classes.section}>
      <Group px="xs" style={{ justifyContent: 'flex-start', flex: 5 }}>
        <div>
          <Text size="sm" fw={600}>
            {details.name}
          </Text>
          <Text size="xs" fw={400} fs="12px">
            {details.clinic}
          </Text>
          <IconAndDetails ImageSrc="../../../img/dashboard-assets/calendar-blank.svg" text={details.dateTime} />
          <Center style={{ justifyContent: 'flex-start' }}>
            <IconAndDetails ImageSrc="" text={'by ' + details.doctors} />
            <IconAndDetails ImageSrc="../../../img/dashboard-assets/location-pin.svg" text={details.clinic} />
          </Center>
        </div>
      </Group>
    </Card.Section>
  ));

  return (
    <Card shadow="sm" radius="md" className={classes.card}>
      <Card.Section className={classes.section}>
        <IconAndText ImageSrc="../../../img/dashboard-assets/circle-alarm.svg" text={'Procedures'} />
      </Card.Section>

      <Group display="block" className={classes.scrollbar} style={{ height: '200px', overflowX: 'hidden' }}>
        {organisationDetails}
      </Group>

      <Card.Section className={classes.footer}>
        <Group style={{ alignItems: 'flex-end' }} spacing={30}>
          <div style={{ textDecoration: 'none', alignItems: 'flex-end', flex: 2 }}></div>
          <Link
            style={{
              textDecoration: 'none',
              alignItems: 'center',
              justifyContent: 'flex-end',
              flex: 1,
              color: '#222',
              display: 'flex',
            }}
            to={''}
          >
            <Text fw={700} mr="5px">
              View All
            </Text>
            <IconArrowRight size="16px" />
          </Link>
        </Group>
      </Card.Section>
    </Card>
  );
}
