import React, { useState, useEffect } from 'react';
import { Grid, TextInput, Text, Box, Card, Button, Divider, Select, PasswordInput } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { useMedplum } from '@medplum/react';
import { createPractitioner, getAllOrganization } from '../../fhirApi';
import { showNotification } from '@mantine/notifications';

interface Organization {
  id: string;
  name: string;
}

export const PractitionersForm: React.FC = () => {
  const navigate = useNavigate();
  const medplum = useMedplum();
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [selectedOrganizationId, setSelectedOrganizationId] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchOrganizations = async () => {
      const response = await getAllOrganization(medplum);
      if (response !== null) {
        const orgs = response.data.entry.map((entry: any) => {
          const resource = entry.resource;
          return {
            id: resource.id,
            name: resource.name,
          };
        });
        setOrganizations(orgs);
      }
    };

    fetchOrganizations();
  }, []);

  const [formState, setFormState] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    organizationName: '',
    gender: '',
    address: {
      line: ['', ''],
      city: '',
      state: '',
      postalCode: '',
      country: '',
    }
  });

  const [errors, setErrors] = useState<{
    firstName?: string;
    lastName?: string;
    email?: string;
    password?: string;
    confirmPassword?: string;
    organizationName?: string;
    gender?: string;
    address?: { [key: string]: string };
  }>({});

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name.startsWith('address.line.')) {
      const index = parseInt(name.split('.')[2], 10);
      setFormState((prevState) => ({
        ...prevState,
        address: {
          ...prevState.address,
          line: prevState.address.line.map((line, i) => (i === index ? value : line)),
        },
      }));
    } else if (name.startsWith('address.')) {
      const addressField = name.split('.')[1];
      setFormState((prevState) => ({
        ...prevState,
        address: {
          ...prevState.address,
          [addressField]: value,
        },
      }));
    } else {
      setFormState({
        ...formState,
        [name]: value,
      });
    }
  };

  const handleSelectChange = (name: string) => (value: string | null) => {
    setFormState((prevState) => ({ ...prevState, [name]: value }));
    if (name === 'organizationName') {
      setSelectedOrganizationId(value);
      clearError('organizationName');
    }
  };

  const validateForm = () => {
    let formIsValid = true;
    let errors: { [key: string]: string } = {};

    if (!formState.firstName) {
      formIsValid = false;
      errors['firstName'] = 'First Name is required';
    }

    if (!formState.lastName) {
      formIsValid = false;
      errors['lastName'] = 'Last Name is required';
    }

    if (!formState.email) {
      formIsValid = false;
      errors['email'] = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formState.email)) {
      formIsValid = false;
      errors['email'] = 'Email is not valid';
    }

    if (!formState.password) {
      formIsValid = false;
      errors['password'] = 'Password is required';
    }

    if (formState.password !== formState.confirmPassword) {
      formIsValid = false;
      errors['confirmPassword'] = 'Passwords do not match';
    }

    if (!formState.organizationName) {
      formIsValid = false;
      errors['organizationName'] = 'Organization Name is required';
    }

    if (!formState.gender) {
      formIsValid = false;
      errors['gender'] = 'Gender is required';
    }

    if (!formState.address.line[0]) {
      formIsValid = false;
      errors['address.line'] = 'Address Line is required';
    }

    if (!formState.address.city) {
      formIsValid = false;
      errors['address.city'] = 'City is required';
    }

    if (!formState.address.state) {
      formIsValid = false;
      errors['address.state'] = 'State is required';
    }

    if (!formState.address.postalCode) {
      formIsValid = false;
      errors['address.postalCode'] = 'Postal Code is required';
    }

    if (!formState.address.country) {
      formIsValid = false;
      errors['address.country'] = 'Country is required';
    }


    setErrors(errors);
    return formIsValid;
  };

  function clearError(field: string) {
    setErrors((prevErrors) => {
      return { ...prevErrors, [field]: null };
    });
  }

  const createNewPractitioner = () => {
    if (validateForm()) {
      setIsSubmitting(true);
      const { email, firstName: first_name, lastName: last_name, password, gender, address } = formState;
      const payload = {
        email,
        first_name,
        last_name,
        password,
        gender,
        address: [
          {
            line: address.line,
            city: address.city,
            state: address.state,
            postalCode: address.postalCode,
            country: address.country,
          },
        ],
        organization_id: selectedOrganizationId
      };

      createPractitioner(medplum, payload)
        .then((response: any) => {
          setIsSubmitting(false);
          if (response) {
            const message = response.data.result;
            showNotification({ color: 'green', message });
            navigate('/Practitioner');
          } else {
            showNotification({ color: 'red', message: 'An error occurred while creating the practitioner.' });
          }
        })
        .catch((error: any) => {

          setIsSubmitting(false);
          if (error.response.status === 500) {
            const errMsg = error.response.data.error.split('Error:');
            showNotification({
              color: 'red', message: errMsg.length > 0 ? errMsg[1] : errMsg[0]
            });
          }
        });
    }
  };

  return (
    <>
      <Box px="lg" py="sm" sx={{ paddingBottom: '0px !important' }}>
        <Grid mb="md">
          <Grid.Col span={12} lg={9}>
            <Card withBorder radius="md" sx={{ padding: '0px !important', boxShadow: '0px 1px 2px 0px #0000001A' }}>
              <Card.Section p="lg" sx={{ padding: '2rem 2rem !important' }}>
                <form>
                  <div>
                    <Grid sx={{ marginBottom: '15px', alignItems: 'start' }}>
                      <Grid.Col span={12} lg={3}>
                        <Text style={{ fontSize: '14px', color: '#272D41' }}>
                          Name<span style={{ color: 'red' }}>*</span>
                        </Text>
                      </Grid.Col>
                      <Grid.Col span={12} lg={4.5}>
                        <TextInput
                          placeholder="First Name"
                          name="firstName"
                          onChange={handleInputChange}
                          onInput={() => clearError('firstName')}
                          required
                        />
                        {errors.firstName && <span style={{ color: 'red' }}>{errors.firstName}</span>}
                      </Grid.Col>
                      <Grid.Col span={12} lg={4.5}>
                        <TextInput
                          placeholder="Last Name"
                          name="lastName"
                          onChange={handleInputChange}
                          onInput={() => clearError('lastName')}
                          required
                        />
                        {errors.lastName && <span style={{ color: 'red' }}>{errors.lastName}</span>}
                      </Grid.Col>
                    </Grid>
                    <Grid sx={{ marginBottom: '15px', alignItems: 'start' }}>
                      <Grid.Col span={12} lg={3}>
                        <Text style={{ fontSize: '14px', color: '#272D41' }}>
                          Email<span style={{ color: 'red' }}>*</span>
                        </Text>
                      </Grid.Col>
                      <Grid.Col span={12} lg={9}>
                        <TextInput
                          type="email"
                          placeholder="Email"
                          name="email"
                          onChange={handleInputChange}
                          onInput={() => clearError('email')}
                          required
                        />
                        {errors.email && <span style={{ color: 'red' }}>{errors.email}</span>}
                      </Grid.Col>
                    </Grid>
                    <Grid sx={{ marginBottom: '15px', alignItems: 'start' }}>
                      <Grid.Col span={12} lg={3}>
                        <Text style={{ fontSize: '14px', color: '#272D41' }}>
                          Password<span style={{ color: 'red' }}>*</span>
                        </Text>
                      </Grid.Col>
                      <Grid.Col span={12} lg={9}>
                        <PasswordInput
                          placeholder="Password"
                          name="password"
                          onChange={handleInputChange}
                          onInput={() => clearError('password')}
                          required
                        />
                        {errors.password && <span style={{ color: 'red' }}>{errors.password}</span>}
                      </Grid.Col>
                    </Grid>
                    <Grid sx={{ marginBottom: '15px', alignItems: 'start' }}>
                      <Grid.Col span={12} lg={3}>
                        <Text style={{ fontSize: '14px', color: '#272D41' }}>
                          Confirm Password<span style={{ color: 'red' }}>*</span>
                        </Text>
                      </Grid.Col>
                      <Grid.Col span={12} lg={9}>
                        <PasswordInput
                          placeholder="Confirm Password"
                          name="confirmPassword"
                          onChange={handleInputChange}
                          onInput={() => clearError('confirmPassword')}
                          required
                        />
                        {errors.confirmPassword && <span style={{ color: 'red' }}>{errors.confirmPassword}</span>}
                      </Grid.Col>
                    </Grid>
                    <Grid sx={{ marginBottom: '15px', alignItems: 'start' }}>
                      <Grid.Col span={12} lg={3}>
                        <Text style={{ fontSize: '14px', color: '#272D41' }}>
                          Gender<span style={{ color: 'red' }}>*</span>
                        </Text>
                      </Grid.Col>
                      <Grid.Col span={12} lg={9}>
                        <Select
                          placeholder="Gender"
                          name="gender"
                          onChange={handleSelectChange('gender')}
                          onInput={() => clearError('gender')}
                          required
                          data={[
                            { value: 'male', label: 'Male' },
                            { value: 'female', label: 'Female' },
                            { value: 'other', label: 'Other' },
                            { value: 'unknown', label: 'Unknown' }
                          ]}
                        />
                        {errors.gender && <span style={{ color: 'red' }}>{errors.gender}</span>}
                      </Grid.Col>
                    </Grid>
                    <Grid sx={{ marginBottom: '15px', alignItems: 'start' }}>
                      <Grid.Col span={12} lg={3}>
                        <Text style={{ fontSize: '14px', color: '#272D41' }}>
                          Address<span style={{ color: 'red' }}>*</span>
                        </Text>
                      </Grid.Col>
                      <Grid.Col span={12} lg={9}>
                        <TextInput
                          placeholder="Address Line 1"
                          name="address.line.0"
                          onChange={handleInputChange}
                          onInput={() => clearError('address.line')}
                          required
                        />
                        {errors?.address?.line && <span style={{ color: 'red' }}>{errors?.address?.line}</span>}
                      </Grid.Col>
                    </Grid>
                    <Grid sx={{ marginBottom: '15px', alignItems: 'start' }}>
                      <Grid.Col span={12} lg={3}></Grid.Col>
                      <Grid.Col span={12} lg={9}>
                        <TextInput
                          placeholder="Address Line 2"
                          name="address.line.1"
                          onChange={handleInputChange}
                        />
                      </Grid.Col>
                    </Grid>
                    <Grid sx={{ marginBottom: '15px', alignItems: 'start' }}>
                      <Grid.Col span={12} lg={3}></Grid.Col>
                      <Grid.Col span={12} lg={4.5}>
                        <TextInput
                          placeholder="City"
                          name="address.city"
                          onChange={handleInputChange}
                          onInput={() => clearError('address.city')}
                          required
                        />
                        {errors?.address?.city && <span style={{ color: 'red' }}>{errors?.address?.city}</span>}
                      </Grid.Col>
                      <Grid.Col span={12} lg={4.5}>
                        <TextInput
                          placeholder="State"
                          name="address.state"
                          onChange={handleInputChange}
                          onInput={() => clearError('address.state')}
                          required
                        />
                        {errors?.address?.state && <span style={{ color: 'red' }}>{errors?.address?.state}</span>}
                      </Grid.Col>
                    </Grid>
                    <Grid sx={{ marginBottom: '15px', alignItems: 'start' }}>
                      <Grid.Col span={12} lg={3}></Grid.Col>

                      <Grid.Col span={12} lg={4.5}>
                        <TextInput
                          placeholder="Postal Code"
                          name="address.postalCode"
                          onChange={handleInputChange}
                          onInput={() => clearError('address.postalCode')}
                          required
                        />
                        {errors?.address?.postalCode && <span style={{ color: 'red' }}>{errors?.address?.postalCode}</span>}
                      </Grid.Col>
                      <Grid.Col span={12} lg={4.5}>
                        <TextInput
                          placeholder="Country"
                          name="address.country"
                          onChange={handleInputChange}
                          onInput={() => clearError('address.country')}
                          required
                        />
                        {errors?.address?.country && <span style={{ color: 'red' }}>{errors?.address?.country}</span>}
                      </Grid.Col>

                    </Grid>
                    <Grid sx={{ marginBottom: '15px', alignItems: 'start' }}>
                      <Grid.Col span={12} lg={3}>
                        <Text style={{ fontSize: '14px', color: '#272D41' }}>
                          Organization Name<span style={{ color: 'red' }}>*</span>
                        </Text>
                      </Grid.Col>
                      <Grid.Col span={12} lg={9}>
                        <Select
                          placeholder="Organization Name"
                          name="organizationName"
                          onChange={handleSelectChange('organizationName')}
                          onInput={() => clearError('organizationName')}
                          required
                          data={organizations.map((org) => ({ value: org.id, label: org.name }))}
                        />
                        {errors.organizationName && <span style={{ color: 'red' }}>{errors.organizationName}</span>}
                      </Grid.Col>
                    </Grid>
                  </div>
                </form>
                <Divider my="sm" />
                <Grid sx={{ justifyContent: 'end', margin: '20px 0' }}>
                  <Button
                    sx={{
                      border: '1.5px solid #3673B6',
                      background: 'transparent',
                      padding: '8px 60px',
                      color: '#3673B6',
                      fontWeight: 600,
                      marginRight: '10px',
                      '&:hover': {
                        background: 'transparent',
                      },
                    }}
                    onClick={() => navigate(`/Practitioner`)}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    sx={{
                      background: '#3673B6',
                      padding: '8px 60px',
                      fontWeight: 600,
                      '&:hover': {
                        background: '#3673B6',
                      },
                    }}
                    onClick={createNewPractitioner}
                  >
                    Save
                  </Button>
                </Grid>
              </Card.Section>
            </Card>
          </Grid.Col>
        </Grid>
      </Box >
    </>
  );
};


