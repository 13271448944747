import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Group, TextInput, Select, Tabs, Flex, Loader } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { fetchPatients } from '../../../fhirApi';
import { useMedplum } from '@medplum/react';

const defaultQuery = (searchTerm: string): string => `{
  PatientList(name: "${searchTerm}", _count: 1000) {
    resourceType
    id
    birthDate
    name {
      family
      given
    }
  }
}`;

const filterOptions: any = {
  conditions: null,
  medications: null,
};

interface ConsultationModalProps {
  isOpen: boolean;
  onClose: () => void;
  isSaving: boolean;
  onSave: (patientData: { id?: string; name: string; birthDate: string }) => void;
}

interface FormValues {
  name: string;
  dob: Date | null;
}

const ConsultationModal: React.FC<ConsultationModalProps> = ({ isOpen, onClose, onSave, isSaving }) => {
  const [activeTab, setActiveTab] = useState<string>('new');
  const [selectedPatient, setSelectedPatient] = useState<string | null>(null);
  const [patients, setPatients] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const medplum = useMedplum();

  const form = useForm<FormValues>({
    initialValues: {
      name: '',
      dob: null,
    },
    validate: {
      name: (value) => (value ? null : 'Name is required'),
      dob: (value) => (value ? null : 'Date of Birth is required'),
    },
  });

  useEffect(() => {
    if (searchTerm.length >= 3) {
      const query = defaultQuery(searchTerm);

      fetchPatients(medplum, query, filterOptions)
        .then((response: any) => {
          const patientList = response.map((patient: any) => {
            const givenName = patient?.name?.[0]?.given ? patient?.name?.[0]?.given.join(' ') : '';
            const familyName = patient?.name?.[0]?.family || '';
            const label = familyName ? `${givenName} ${familyName}` : givenName;

            return {
              value: patient.id,
              label: label,
              dob: patient.birthDate,
            };
          });
          setPatients(patientList);
        })
        .catch((error: any) => {
          console.error('Error fetching data:', error);
        });
    } else {
      setPatients([]);
    }
  }, [searchTerm, medplum]);

  const previousPatientRef = useRef<string | null>(null);

  useEffect(() => {
    if (selectedPatient && selectedPatient !== previousPatientRef.current) {
      previousPatientRef.current = selectedPatient;
      const patient = patients.find((p) => p.value === selectedPatient);
      if (patient) {
        form.setValues({
          name: patient.label,
                    dob: patient.dob ? new Date(patient.dob) : null, // Assuming patient.dob is already a string
        });
      }
    }
  }, [selectedPatient, patients, form]);
  
  

  const disableInputs = selectedPatient !== null;

  const handleSave = (): void => {
    if (!form.isValid()) {
      return;
    }
    const formatDate = (dateString: string | null): string => {
      if (!dateString) {return '';}
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    };
    if (activeTab === 'existing' && selectedPatient) {
      const patient = patients.find((p) => p.value === selectedPatient);
      if (patient) {
        console.log('Saving existing patient:', patient);
        onSave({
          id: patient.value,
          name: patient.label,
          birthDate: patient.dob,
        });
      } else {
        console.log('Selected patient not found in patients list');
      }
    } else {
      const newPatient = form.values;
      console.log('Saving new patient:', { ...newPatient, dob: formatDate(newPatient?.dob as string | null) });
      onSave({
        name: newPatient.name,
        birthDate: formatDate(newPatient.dob as string | null),
      });
    }
  };
  
  return (
    <Modal opened={isOpen} onClose={onClose} title="Patient Details">
      <Tabs value={activeTab} onTabChange={(value) => setActiveTab(value ?? 'new')}>
        <Tabs.List>
          <Tabs.Tab value="new">New Patient</Tabs.Tab>
          <Tabs.Tab value="existing">Existing Patient</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="new" pt="xs">
          <Flex direction="column" style={{ height: '100%' }}>
            <form style={{ minHeight: '50vh' }} onSubmit={form.onSubmit((values) => console.log(values))}>
              <TextInput
                label="Name"
                required
                {...form.getInputProps('name')}
                disabled={disableInputs}
                mb={12}
                labelProps={{ style: { marginBottom: '8px' } }}
              />
              <DateInput
                label="Date of Birth"
                {...form.getInputProps('dob')}
                valueFormat="MM/DD/YYYY"
                maxDate={new Date()}
                disabled={disableInputs}
                sx={{ '.mantine-Popover-dropdown': { transform: 'scale(0.8)', transformOrigin: 'top left' } }}
                styles={(theme) => ({
                  input: { width: '100%' },
                  calendarHeader: {
                    maxWidth: '100%',
                    marginBottom: theme.spacing.xs,
                  },
                  calendarHeaderLevel: { fontSize: theme.fontSizes.sm },
                  yearsList: { maxWidth: '100%' },
                  monthsList: { maxWidth: '100%' },
                  day: { height: 'auto', width: 'auto' },
                })}
                labelProps={{ style: { marginBottom: '6px' } }}
                onChange={(value) => form.setFieldValue('dob', value)}
              />
            </form>
            <Group position="right" mt="md">
              {isSaving ? (
                <Button onClick={handleSave} disabled={true}>
                  <div>Saving patient and generating clinical notes</div>
                  <Loader size={16} ml={'7px'} />
                </Button>
              ) : (
                <div>
                  <Button onClick={handleSave} mr={4}>
                    Confirm and Next
                  </Button>
                  <Button variant="default" onClick={onClose}>
                    Discard
                  </Button>
                </div>
              )}
            </Group>
          </Flex>
        </Tabs.Panel>

        <Tabs.Panel style={{ minHeight: '50vh' }} value="existing" pt="xs">
          <Select
            label="Search Patient"
            placeholder="Search Patient"
            data={patients}
            searchable
            clearable
            nothingFound={searchTerm.length >= 3 && patients.length === 0 ? "No Patient" : null}
            value={selectedPatient}
            onSearchChange={(value) => setSearchTerm(value)}
            searchValue={searchTerm}
            onChange={setSelectedPatient}
            mb="xl"
            styles={(theme) => ({
              label: {
                marginBottom: 4,
              },
              input: {
                marginTop: 0,
              },
            })}
          />
          {selectedPatient && (
            <Flex direction="column" style={{ height: '100%' }}>
              <form style={{ minHeight: '50vh' }} onSubmit={form.onSubmit((values) => console.log(values))}>
                <TextInput label="Name" required {...form.getInputProps('name')} disabled={disableInputs} mb="md" />
                <DateInput
                  label="Date of Birth"
                  {...form.getInputProps('dob')}
                  valueFormat="MM/DD/YYYY"
                  maxDate={new Date()}
                  disabled={disableInputs}
                  mb="md"
                  sx={{ '.mantine-Popover-dropdown': { transform: 'scale(0.8)', transformOrigin: 'top left' } }}
                  styles={(theme) => ({
                    input: { width: '100%' },
                    calendarHeader: {
                      maxWidth: '100%',
                      marginBottom: theme.spacing.xs,
                    },
                    calendarHeaderLevel: { fontSize: theme.fontSizes.sm },
                    yearsList: { maxWidth: '100%' },
                    monthsList: { maxWidth: '100%' },
                    day: { height: 'auto', width: 'auto' },
                  })}
                  onChange={(value) => form.setFieldValue('dob', value)}
                />
              </form>
              <Group position="right" mt="md">
                {isSaving ? (
                  <Button onClick={handleSave} disabled={true}>
                    <div>Saving patient and generating clinical notes</div>
                    <Loader size={16} ml={'7px'} />
                  </Button>
                ) : (
                  <div>
                    <Button onClick={handleSave} mr={4}>
                      Confirm & Next
                    </Button>
                    <Button variant="default" onClick={onClose}>
                      Discard
                    </Button>
                  </div>
                )}
              </Group>
            </Flex>
          )}
        </Tabs.Panel>
      </Tabs>
    </Modal>
  );
};

export default ConsultationModal;
