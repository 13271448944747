import React, { Children, ReactNode } from 'react';

interface EachProps {
  render: (item: any, index: number) => ReactNode;
  of: any[];
}

const Each: React.FC<EachProps> = ({ render, of }) => (
  <>
    {Children.toArray(of.map((item: any, index: number) => render(item, index)))}
  </>
);

export default Each;
