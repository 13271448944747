// Import necessary components and colors
import React, { useState, useRef } from 'react';
import { Modal, Textarea, Button, Grid, Text, Box } from '@mantine/core';
import './AudioRecorder.css';
import axios from '../../utils/axios';
import { IconPlayerPlayFilled, IconPlayerStopFilled } from '@tabler/icons-react';
import ClinicalNotes from './ClinicalNotes';
import { useMedplum } from '@medplum/react';

interface AudioRecorderProps {
  isOpen: boolean;
  handleClose: () => void;
  appointmentId: string;
  appointmentData: any;
}

const AudioRecorder: React.FC<AudioRecorderProps> = ({ isOpen, handleClose, appointmentId, appointmentData }) => {
  const audioChunksRef = useRef<Blob[]>([]);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const intervalRef = useRef<any>(null);
  const [recording, setRecording] = useState(false);
  const [transcript, setTranscript] = useState<string>('');
  const [document, setDocument] = useState<any>('');
  const [clinicalNote, setClinicalNote] = useState<string>('');
  const [isClinicalNotesOpen, setIsClinicalNotesOpen] = useState(false);
  const [timer, setTimer] = useState(0);
  const [modalWidth, setModalWidth] = useState<number | string>('');
  const [modalHeight, setModalheight] = useState<number | string>('');
  const medplum = useMedplum();
  const startRecording = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        const mediaRecorder = new MediaRecorder(stream);
        intervalRef.current = setInterval(() => {
          setTimer((prevTimer) => prevTimer + 1);
        }, 1000);
        mediaRecorder.ondataavailable = (e) => {
          if (e.data.size > 0) {
            audioChunksRef.current.push(e.data as Blob);
          }
        };

        mediaRecorder.onstop = () => {
          const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
          sendAudioToBackend(audioBlob);
          audioChunksRef.current = [];
        };

        audioChunksRef.current = [];
        mediaRecorder.start();
        setRecording(true);
        mediaRecorderRef.current = mediaRecorder;
      })
      .catch((error) => {
        console.error('Error accessing microphone:', error);
      });
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      clearInterval(intervalRef.current);
      setRecording(false);
    }
  };

  const sendAudioToBackend = async (audioBlob: Blob) => {
    try {
      let token = await medplum.getAccessToken();
      const formData = new FormData();
      formData.append('audio', audioBlob, 'audio.wav');

      const response = await axios.post(
        `/api/pragmaconnect/generate-transcribe?appointmentId=${appointmentId}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTranscript(response.data.transcript); // Assuming the response has a 'transcript' property
      setDocument(response.data.document);
    } catch (error) {
      console.error('Error sending audio to the backend:', error);
    }
  };

  const formatTime = (seconds: number): string => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const pad = (num: number) => (num < 10 ? `0${num}` : `${num}`);

    return `${pad(hours)}:${pad(minutes)}:${pad(remainingSeconds)}`;
  };

  const handleOpenClinicalNotesModel = async () => {
    let token = await medplum.getAccessToken();
    let postData: any = {};
    postData.clinicalnote = transcript;
    postData.documentId = document?.id;
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    const response: any = await axios.post(`/api/pragmaconnect/generate-clinical-note`, postData, config);
    if (response.data.transcript) {
      setClinicalNote(response.data.transcript);
      setIsClinicalNotesOpen(true);
      setModalWidth('640px');
      setModalheight('553px');
    }
  };

  return (
    <>
      <Modal
        opened={isOpen}
        onClose={handleClose}
        title={isClinicalNotesOpen ? 'Clinical Notes Preview' : 'AI Consultation'}
        style={{
          backgroundColor: '#F5F5F5',
          color: '#333',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        className="consultation-modal"
      >
        <Box px="lg" py="sm" sx={{ padding: '0 !important' }}>
          {isClinicalNotesOpen ? (
            <ClinicalNotes
              clinicalNotes={clinicalNote}
              appointmentId={appointmentId}
              documentId={document?.id}
              handleClose={handleClose}
            />
          ) : (
            <Grid>
              <Grid.Col sm={12} md={5} lg={5} sx={{ borderRight: '1px solid #F2F2F2' }}>
                <Grid className="content-box">
                  <Grid.Col span={12} lg={6}>
                    <Text className="audio-label">Name</Text>
                  </Grid.Col>
                  <Grid.Col span={12} lg={6}>
                    <Text className="audio-content">{appointmentData.name}</Text>
                  </Grid.Col>
                </Grid>
                <Grid className="content-box">
                  <Grid.Col span={12} lg={6}>
                    <Text className="audio-label">Date</Text>
                  </Grid.Col>
                  <Grid.Col span={12} lg={6}>
                    <Text className="audio-content" sx={{ color: '#000000 !important' }}>
                      {appointmentData.appointmentTime}
                    </Text>
                  </Grid.Col>
                </Grid>
                <Grid className="content-box">
                  <Grid.Col span={12} lg={6}>
                    <Text className="audio-label">Practitioner</Text>
                  </Grid.Col>
                  <Grid.Col span={12} lg={6}>
                    <Text className="audio-content">{appointmentData.practitioner}</Text>
                  </Grid.Col>
                </Grid>
                <Grid className="content-box">
                  <Grid.Col span={12} lg={6}>
                    <Text className="audio-label">Location</Text>
                  </Grid.Col>
                  <Grid.Col span={12} lg={6}>
                    <Text className="audio-content" sx={{ color: '#000000 !important' }}>
                      {appointmentData.organization}
                    </Text>
                  </Grid.Col>
                </Grid>
                <Grid className="content-box">
                  <Grid.Col span={12} lg={6}>
                    <Text className="audio-label" sx={{ color: '#000000 !important' }}>
                      Status
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={12} lg={6}>
                    <Text className="audio-content" sx={{ color: '#000000 !important' }}>
                      {appointmentData.status}
                    </Text>
                  </Grid.Col>
                </Grid>
                <Grid className="content-box">
                  <Grid.Col span={12} lg={6}>
                    <Text className="audio-label">Appointment Type</Text>
                  </Grid.Col>
                  <Grid.Col span={12} lg={6}>
                    <Text className="audio-content">{appointmentData.appointmentName}</Text>
                  </Grid.Col>
                </Grid>
              </Grid.Col>
              <Grid.Col sm={12} md={7} lg={7} pl={15}>
                <Text component="h3">Consultation Session</Text>
                <Box className="box">
                  <Grid>
                    {!recording ? (
                      <Grid.Col
                        md={2}
                        lg={2}
                        sm={2}
                        sx={{ textAlign: 'center', cursor: 'pointer' }}
                        onClick={startRecording}
                      >
                        <div className="icon-bg">
                          <IconPlayerPlayFilled size={14} className="icon-position" />
                        </div>
                        <Text component="span" className="text-dec">
                          Start
                        </Text>
                      </Grid.Col>
                    ) : (
                      <Grid.Col
                        md={2}
                        lg={2}
                        sm={2}
                        sx={{ textAlign: 'center', cursor: 'pointer' }}
                        onClick={stopRecording}
                      >
                        <div className="icon-bg">
                          <IconPlayerStopFilled size={14} className="icon-position" />
                        </div>
                        <Text component="span" className="text-dec">
                          Stop
                        </Text>
                      </Grid.Col>
                    )}
                    {/* <Grid.Col md={2} lg={2} sx={{ textAlign: 'center', cursor: 'pointer' }}>
                  <div className="icon-bg">
                    <IconPlayerPauseFilled size={14} className="icon-position" />
                  </div>
                  <Text component="span" className="text-dec">
                    Pause
                  </Text>
                </Grid.Col> */}
                    {
                      <Grid.Col md={6} lg={10} sx={{ textAlign: 'end' }}>
                        <Text mt={10}>{formatTime(timer)}</Text>
                      </Grid.Col>
                    }
                  </Grid>
                </Box>
                <Text component="h3">Audio Transcription</Text>
                {transcript ? (
                  <Textarea
                    value={transcript}
                    minRows={10}
                    placeholder="Clinical assessment transcript"
                    onChange={(e) => setTranscript(e.target.value)}
                  />
                ) : (
                  <Box className="box-1" sx={{ height: '90%' }}>
                    <Grid sx={{ display: 'grid', justifyContent: 'center' }}>
                      <img
                        src="../../img/dashboard-assets/conversation.svg"
                        style={{ display: 'block', margin: 'auto' }}
                      />
                      <Text component="span" sx={{ color: '#4F4F4F' }}>
                        No conversation available
                      </Text>
                    </Grid>
                  </Box>
                )}
                <Grid sx={{ padding: '30px 10px' }}>
                  <Button
                    sx={{
                      background: '#BDBDBD',
                      color: '#FCFCFD',
                      fontWeight: 600,
                      marginRight: '10px',
                      '&:hover': {
                        background: '#BDBDBD',
                      },
                    }}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    sx={{
                      background: '#3673B6',
                      fontWeight: 600,
                      '&:hover': {
                        background: '#3673B6',
                      },
                    }}
                    onClick={() => handleOpenClinicalNotesModel()}
                    disabled={!transcript}
                  >
                    Generate Clinical Note
                  </Button>
                </Grid>
              </Grid.Col>
            </Grid>
          )}
          <style>
            {`.consultation-modal .mantine-Modal-content {
          min-width: ${modalWidth} !important;
          min-height: ${modalHeight} !important;
        }`}
          </style>
        </Box>
      </Modal>
    </>
  );
};

export default AudioRecorder;
