/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useRef, useState } from 'react';
import { Text, Card, Avatar, Textarea } from '@mantine/core';
import { IconCheckbox, IconCopy } from '@tabler/icons-react';

interface ClinicalNotesProps {
  clinicalNote: any;
  instruction: string;
  lastUsedInstruction: string;
  preClinicalNote: string;
  loading: boolean;
}
const ClinicalNote: React.FC<ClinicalNotesProps> = (props: ClinicalNotesProps) => {
  const inputRef = useRef<HTMLDivElement>(null);
  const [isCopied, setIsCopied] = useState(false);
  const copyText = () => {
    try {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 500);
      const textToCopy = inputRef.current?.innerText;

      if (textToCopy) {
        navigator.clipboard.writeText(textToCopy);
      }
    } catch (error) {
      console.error('Unable to copy text to clipboard', error);
    }
  };
  return (
    <>
      <Text sx={{ fontSize: '16px', fontWeight: 500, display: 'inline-block' }}>
        Clinical Notes Preview
        {/* <Tooltip label="User can edit clinical notes directly." transitionProps={{ transition: 'pop', duration: 300 }}>
          <IconInfoCircleFilled size={20} style={{ marginLeft: '5px', color: '#828282', verticalAlign: 'middle' }} />
        </Tooltip> */}
      </Text>
      <Card display="block" mt={10} radius="sm" p="xs" sx={{ border: '1px solid #BDBDBD', padding: '20px' }}>
        {props.clinicalNote ? (
          <>
            <div className="card-heading">
              <Avatar src="../../../../img/dashboard-assets/profile.svg" radius="xl" size="sm" />
              <Text sx={{ marginLeft: '10px', fontWeight: 600 }}>Your generated clinical notes</Text>
            </div>
            {props.preClinicalNote && (
              <div className="">
                <div className="d-flex align-start">
                  <Avatar src="../../../../img/dashboard-assets/bot.svg" radius="xl" size="sm" />
                  <Text sx={{ marginLeft: '10px', fontWeight: 700, color: '#2F67AD' }}>PCBot</Text>
                </div>
                <Text className="prg-scrollable-section" ref={inputRef}>
                  {props.preClinicalNote.split('\n').map((paragraph: any, index: any, array: any) => (
                    <React.Fragment key={index}>
                      {paragraph}
                      {index !== array.length - 1 && paragraph.trim() && <br />}
                    </React.Fragment>
                  ))}
                  {isCopied ? (
                    <IconCheckbox className="copy-icon" />
                  ) : (
                    <IconCopy className="copy-icon" onClick={copyText} />
                  )}
                </Text>
              </div>
            )}
            {props.preClinicalNote && (
              <div className="card-heading" style={{ margin: '25px 0 15px' }}>
                <Avatar src="../../../../img/dashboard-assets/profile.svg" radius="xl" size="sm" />
                <Text sx={{ marginLeft: '10px', fontWeight: 600 }}>
                  {props.lastUsedInstruction || 'No instruction provided'}
                </Text>
              </div>
            )}
            <div className="innser-card">
              <div className="d-flex align-start">
                <Avatar src="../../../../img/dashboard-assets/bot.svg" radius="xl" size="sm" />
                <Text sx={{ marginLeft: '10px', fontWeight: 700, color: '#2F67AD' }}>PCBot</Text>
              </div>
              <Text className="prg-scrollable-section" ref={inputRef}>
                {props.loading ? (
                  <>
                    <span>{props.clinicalNote}</span>
                    <span className="loading">
                      <span className="loading__dot"></span>
                      <span className="loading__dot"></span>
                      <span className="loading__dot"></span>
                    </span>
                  </>
                ) : (
                  <>
                    {props.clinicalNote.split('\n').map((paragraph: any, index: any, array: any) => (
                      <React.Fragment key={index}>
                        {paragraph}
                        {index !== array.length - 1 && paragraph.trim() && <br />}
                      </React.Fragment>
                    ))}
                    {isCopied ? (
                      <IconCheckbox className="copy-icon" />
                    ) : (
                      <IconCopy className="copy-icon" onClick={copyText} />
                    )}
                  </>
                )}
              </Text>
            </div>
          </>
        ) : (
          <>
            {props.loading ? (
              <span className="loading">
                <span className="loading__dot"></span>
                <span className="loading__dot"></span>
                <span className="loading__dot"></span>
              </span>
            ) : (
              <Textarea
                readOnly
                minRows={3}
                sx={{ padding: '10px 35px', lineHeight: '25px', fontWeight: 600, fontSize: '16px' }}
                className="text-area"
              >
                No data to show
              </Textarea>
            )}
          </>
        )}
      </Card>
    </>
  );
};

export default ClinicalNote;
