import { useEffect, useCallback, useState, forwardRef } from 'react';
import { Box, Grid, Text, Select, Group, Textarea, Button, Loader } from '@mantine/core';
import { useMedplum } from '@medplum/react';
import { Patient, ResearchStudy } from '@medplum/fhirtypes';
import { IconSearch } from '@tabler/icons-react';
import { formatHumanName, getDisplayString, isUUID } from '@medplum/core';
import { AsyncAutocomplete, AsyncAutocompleteOption } from '../../../../../react/src/AsyncAutocomplete/AsyncAutocomplete';
import { ResourceAvatar } from '../../../../../react/src/ResourceAvatar/ResourceAvatar';
import TitleComponent from '../../../components/TitleComponent';
import { HeaderSearchTypes, toKey, toOption } from '../../../../../react/src/AppShell/HeaderSearchInput';
import { clinicalStudyNotification } from '../../../../src/fhirApi';
import { showNotification } from '@mantine/notifications';

const defaultQuery = (input: string) => {
  const escaped = JSON.stringify(input);
  if (isUUID(input)) {
    return `{
      Patients1: PatientList(_id: ${escaped}, _count: 1) {
        resourceType
        id
        name {
          given
          family
        }
        birthDate
      }
      ServiceRequestList(_id: ${escaped}, _count: 1) {
        resourceType
        id
        subject {
          display
        }
      }
    }`;
  }
  return `{
    Patients1: PatientList(name: ${escaped}, _count: 5) {
      resourceType
      id
      name {
        given
        family
      }
      birthDate
    }
    ServiceRequestList(identifier: ${escaped}, _count: 5) {
      resourceType
      id
      subject {
        display
      }
    }
  }`;
};

const researchStudyQuery = () => `
  {
    ResearchStudyList(_count: 1000) {
      resourceType
      id
      title
      description
    }
  }
`;

const InvitePatients = () => {
  const [selectedPatient, setSelectedPatient] = useState<string | null>(null);
  const [patients, setPatients] = useState<{ label: string; value: string }[]>([]);
  const [studies, setStudies] = useState<{ value: string; label: string; description: string; resource?: ResearchStudy }[]>([]);
  const [message, setMessage] = useState<string>('');
  const [selectedStudy, setSelectedStudy] = useState<string | null>(null);
  const [summary, setSummary] = useState<string>('');
  const [notificationDetails, setNotificationDetails] = useState<any>(null);
  const [autocompleteKey, setAutocompleteKey] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const medplum = useMedplum();

  const loadPatients = useCallback(async (input: string, signal: AbortSignal): Promise<HeaderSearchTypes[]> => {
    const query = defaultQuery(input);
    try {
      const response = await medplum.graphql(query, undefined, undefined, { signal });
      const patientList = getResourcesFromResponse(response, input);

      return patientList.map((patient: Patient) => ({
        resourceType: 'Patient',
        id: patient.id || '',
        name: patient.name || [],
        birthDate: patient.birthDate || '',
      })) as HeaderSearchTypes[];
    } catch (error) {
      console.error('Error fetching patients:', error);
      return [];
    }
  }, [medplum]);

  const loadResearchStudies = useCallback(async () => {
    try {
      const response = await medplum.graphql(researchStudyQuery());
      const researchStudyList = response.data.ResearchStudyList || [];
      const formattedStudies = researchStudyList.map((study: ResearchStudy) => ({
        value: study.id || '',
        label: study.title || '',
        resource: study,
      }));
      setStudies(formattedStudies);
    } catch (error) {
      console.error('Error fetching research studies:', error);
    }
  }, [medplum]);

  useEffect(() => {
    loadResearchStudies();
  }, [loadResearchStudies]);

  useEffect(() => {
  }, [notificationDetails]);

  const handleSelect = useCallback((selected: Patient[]) => {
    if (selected && selected.length === 1) {
      const selectedPatientId = selected[0]?.id || '';
      setSelectedPatient(selectedPatientId);

      const label = selected[0]?.name?.[0]?.family
        ? selected[0]?.name?.[0]?.given?.join(' ') + ' ' + selected[0]?.name?.[0]?.family
        : selected[0]?.name?.[0]?.given?.join(' ') || 'this Patient';

      setPatients([{ label: label, value: selectedPatientId }]);
    } else {
      setSelectedPatient(null);
      setPatients([]);
    }
  }, []);

  const handleSelectStudy = async (selectedValue: string | null) => {
    const selectedStudy = studies.find((study) => study.value === selectedValue);

    if (selectedStudy && selectedStudy.resource) {
      const resource = selectedStudy.resource;
      let parsedDescription: { briefSummary: string } | undefined;

      if (typeof resource.description === 'string') {
        parsedDescription = JSON.parse(resource.description);
      }

      const briefSummary = parsedDescription?.briefSummary || 'No brief summary available';

      setSelectedStudy(selectedStudy.value);
      setSummary(briefSummary);
    }
  };

  const handleCancel = () => {
    setSelectedPatient(null);
    setPatients([]);
    setSelectedStudy(null);
    setSummary('');
    setMessage('');
    setNotificationDetails(null);
    setAutocompleteKey(prevKey => prevKey + 1);
  };

  const handleInvite = async () => {
    if (!selectedPatient) {
      showNotification({
        color: 'red',
        message: 'Please select the patient!',
      });
      return;
    }

    if (!selectedStudy) {
      showNotification({
        color: 'red',
        message: 'Please select a clinical study!',
      });
      return;
    }

    setLoading(true);
    try {
      const selectedPatientName = patients.length > 0 ? patients[0].label : 'Unknown Patient';

      const selectedStudyName = studies.find(study => study.value === selectedStudy)?.label || 'Unknown Study';

      const notificationResponse = await clinicalStudyNotification(medplum, selectedPatient, selectedPatientName, selectedStudy, selectedStudyName, '', message);

      setNotificationDetails(notificationResponse);
      showNotification({
        color: 'green',
        message: (
          <div>
            <Text>Invitation Sent!</Text>
            <Text>Your invitation has been successfully sent to {selectedPatientName} to participate in the clinical study.</Text>
          </div>
        ),
      });
    } catch (error) {
      let details;
      if (error && typeof error === 'object' && 'outcome' in error && Array.isArray((error as any).outcome?.issue) && (error as any).outcome.issue[0]?.details?.text) {
        details = JSON.parse((error as any).outcome.issue[0].details.text);
      }

      showNotification({
        color: 'red',
        message: (
          <div>
            <Text>{details?.error || 'An error occurred while sending the invitation'}</Text>
          </div>
        ),
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box px="lg" py="sm" sx={{ paddingBottom: '0px !important' }}>
      <Grid mb="md">
        <Grid.Col span={12} lg={12}>
          <TitleComponent title={'Invite Patients'} />
        </Grid.Col>
        <Grid.Col span={12} lg={12}>
          <Text style={{ fontSize: '18px', fontWeight: '600' }}>Invite patients to clinical trials</Text>
        </Grid.Col>
        <Grid.Col span={12} lg={6}>
          <Box className="fhir-bot" sx={{ width: '100%' }}>
            <Box mb="md" sx={{ width: '100%' }}>
              <Text mb={8} style={{ fontSize: '16px', fontWeight: '500' }}>Patient Name</Text>
              <AsyncAutocomplete
                key={autocompleteKey}
                size="sm"
                radius="md"
                icon={<IconSearch size={16} />}
                placeholder="Search patients"
                loadOptions={loadPatients}
                onChange={(item: HeaderSearchTypes[]) => handleSelect(item as Patient[])}
                clearSearchOnChange
                clearable={false}
                maxSelectedValues={1}
                toKey={toKey}
                toOption={toOption}
                itemComponent={ItemComponent}
                value={patients.length > 0 ? [patients[0].value] : []}
                styles={(theme) => ({
                  input: {
                    width: '100%',
                    paddingTop: '6px',
                  },
                })}
              />
            </Box>
            <Box mb="md" sx={{ width: '100%' }}>
              <Text mb={8} style={{ fontSize: '16px', fontWeight: '500' }}>Clinical Trial Name</Text>
              <Select
                size="sm"
                radius="md"
                placeholder="Search clinical study"
                icon={<IconSearch size={16} />}
                data={studies}
                onChange={handleSelectStudy}
                searchable
                nothingFound="No studies found"
                value={selectedStudy}
                styles={(theme) => ({
                  input: {
                    width: '100%',
                    paddingTop: '6px',
                  },
                })}
              />
            </Box>
            <Box mt="md" sx={{ width: '100%' }}>
              <Text mb={8} style={{ fontSize: '16px', fontWeight: '500' }}>Summary</Text>
              <Box
                sx={{
                  border: '1px solid #dcdcdc',
                  borderRadius: '4px',
                  padding: '8px',
                  backgroundColor: '#f9f9f9',
                  width: '100%',
                }}
              >
                <Text dangerouslySetInnerHTML={{ __html: summary }}></Text>
              </Box>
            </Box>
            <Box mt="md" sx={{ width: '100%' }}>
              <Text mb={8} style={{ fontSize: '16px', fontWeight: '500' }}>Message</Text>
              <Textarea
                placeholder="Enter your message here"
                value={message}
                onChange={(e) => setMessage(e.currentTarget.value)}
                minRows={4}
                maxRows={8}
                styles={{
                  root: {
                    width: '100%',
                  },
                }}
              />
            </Box>
            <Box mt="md" sx={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
              <Button variant="outline" onClick={handleCancel}>Cancel</Button>

              <Button
                variant="filled"
                onClick={handleInvite}
                sx={{
                  position: 'relative',
                  opacity: loading ? 0.7 : 1,
                  pointerEvents: loading ? 'none' : 'auto',
                }}
              >
                {loading && (
                  <Loader
                    size="sm"
                    color="white"
                    sx={{
                      position: 'absolute',
                      left: '50%',
                      top: '50%',
                      transform: 'translate(-50%, -50%)',
                    }}
                  />
                )}
                <Box>Invite</Box>
              </Button>
            </Box>
          </Box>
        </Grid.Col>
      </Grid>
    </Box>
  );
};

const ItemComponent = forwardRef<HTMLDivElement, AsyncAutocompleteOption<Patient>>(
  ({ resource, ...others }: AsyncAutocompleteOption<Patient>, ref) => {
    return (
      <div ref={ref} {...others}>
        <Group noWrap>
          <ResourceAvatar value={resource} />
          <div>
            <Text>{getDisplayString(resource)}</Text>
            <Text size="xs" color="dimmed">
              {resource.birthDate}
            </Text>
          </div>
        </Group>
      </div>
    );
  }
);

function getResourcesFromResponse(response: any, query: string): Patient[] {
  const resources: Patient[] = [];
  if (response.data.Patients1) {
    resources.push(...response.data.Patients1);
  }
  return resources
    .sort((a: Patient, b: Patient) => getResourceScore(b, query) - getResourceScore(a, query))
    .slice(0, 5);
}

function getResourceScore(resource: Patient, query: string): number {
  let bestScore = 0;
  if (resource.name) {
    for (const name of resource.name) {
      bestScore = Math.max(bestScore, getStringScore(formatHumanName(name), query));
    }
  }
  return bestScore;
}

function getStringScore(str: string | undefined, query: string): number {
  if (!str) {
    return 0;
  }
  const index = str.toLowerCase().indexOf(query.toLowerCase());
  if (index < 0) {
    return 0;
  }
  return 100 - index;
}

export default InvitePatients;
