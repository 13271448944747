import { useState, useEffect } from 'react';
import { Box, Grid, Table } from '@mantine/core';
import { useMedplum } from '@medplum/react';
import TitleComponent from '../../../components/TitleComponent';
import { getClinicalTrialNotification } from '../../../../src/fhirApi';

type ClinicalTrialNotificationType = {
  note: string;
  occurrenceDateTime: string;
  status: string;
  originalDate: string;
};

const ClinicalTrialNotification = () => {
  const medplum = useMedplum();
  const [clinicalTrialNotifications, setClinicalTrialNotifications] = useState<ClinicalTrialNotificationType[]>([]);

  const getClinicalTrialNotificationList = async () => {
    try {
      const response = await getClinicalTrialNotification(medplum);
      if (response && response.entry) {
        let notifications = response.entry.map((entry: any) => ({
          note: entry.resource.note?.[0]?.text || '-',
          occurrenceDateTime: entry.resource.occurrenceDateTime
            ? new Date(entry.resource.occurrenceDateTime).toLocaleDateString()
            : '-',
          originalDate: entry.resource.occurrenceDateTime || '',
          status: entry.resource.status || '-',
        }));

        // Sort notifications by date in descending order (most recent first)
        notifications = notifications.sort((a: { originalDate: string }, b: { originalDate: string }) =>
          new Date(b.originalDate).getTime() - new Date(a.originalDate).getTime()
        );

        setClinicalTrialNotifications(notifications);
      } else {
        console.error('No response received');
      }
    } catch (error) {
      console.error('Error fetching clinical study data:', error);
    }
  };

  useEffect(() => {
    getClinicalTrialNotificationList();
  }, []);

  return (
    <Box px="lg" py="sm" sx={{ paddingBottom: '0px !important' }}>
      <Grid mb="md">
        <Grid.Col span={12} lg={12}>
          <TitleComponent title={'Notifications'} />
        </Grid.Col>

        <Grid.Col span={12} lg={12}>
          <Box p="md">
            <Box
              sx={{
                border: '1px solid #ccc',
                borderRadius: '8px',
                overflowY: 'auto',
                maxHeight: '400px',
              }}
            >
              <Table striped highlightOnHover>
                <thead
                  style={{
                    position: 'sticky',
                    top: 0,
                    backgroundColor: '#fff',
                    zIndex: 1,
                  }}
                >
                  <tr>
                    <th>Notifications</th>
                    <th>Date</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {clinicalTrialNotifications.length > 0 ? (
                    clinicalTrialNotifications.map((notification, index) => (
                      <tr key={index}>
                        <td>{notification.note}</td>
                        <td>{notification.occurrenceDateTime}</td>
                        <td>{notification.status}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={3}>No notifications found.</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Box>
          </Box>
        </Grid.Col>
      </Grid>
    </Box>
  );
};

export default ClinicalTrialNotification;