import React, { useEffect, useState } from 'react';
import { Paper, Button, Group, Tabs, createStyles, Pagination, Box, Text } from '@mantine/core';
import { IconRefresh, IconCirclePlus } from '@tabler/icons-react';
import { formatSearchQuery, Operator, SearchRequest } from '@medplum/core';
import { useMedplum } from '@medplum/react';
import { Appointment, Bundle } from '@medplum/fhirtypes';
import TitleComponent from '../TitleComponent';
import TelehealthFilters from './TelehealthFilters';
import TelehealthTable from './TelehealthTable';
import { TelehealthModal } from './TelehealthModal';
import { useNavigate } from 'react-router-dom';

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    height: '100%',
  },

  section: {
    padding: theme.spacing.md,
  },

  footer: {
    padding: theme.spacing.md,
    borderTop: `1px solid #EEF1F6 !important`,
  },

  header: {
    padding: theme.spacing.md,
    borderBottom: `1px solid #EEF1F6 !important`,
  },

  icon: {
    padding: theme.spacing.xs,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#EDF5FF',
    height: '40px',
    width: '40px',
    borderRadius: '25px',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[5],
  },

  scrollbar: {
    overflow: 'hidden',
    '&:hover': {
      overflowY: 'auto',
      scrollbarWidth: 'thin',
      scrollbarColor: '#ccc #f3f3f3',
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#ccc',
        borderRadius: '8px',
        '&:hover': {
          background: '#aaa',
        },
      },
    },
  },

  label: {
    fontWeight: 400,
    fontSize: theme.fontSizes.sm,
    marginRight: theme.spacing.sm,
  },

  table: {
    cursor: 'pointer',
    borderRadius: theme.radius.md,
  },

  tableRow: {
    padding: '30px',
  },

  highlitedRow: {
    color: '#1B5390 !important',
    background: 'none !important',
  },

  dBlock: {
    [theme.fn.smallerThan('sm')]: {
      display: 'block',
    },
  },

  tabs: {
    '& .mantine-Tabs-tab': {
      fontSize: '14px',
      color: '#667085',
      marginRight: '10px',
      fontWeight: 600,
      borderRadius: '0',
      padding: '0.30rem 0.35rem !important',
    },
    '& button[data-active]': {
      color: '#2F67AD',
      background: 'transparent !important',
      borderBottom: '2px solid #2F67AD !important',
      fontWeight: 600,
      borderRadius: '0',
      padding: '0.30rem 0.35rem !important',
    },
    '& .mantine-Tabs-tabsList': {
      borderBottom: '1px solid #EAECF0',
    },
  },

  tabPanel: {
    display: 'block',
    flexDirection: 'row',
    alignItems: 'center',
  },

  badge: {
    minWidth: 'auto',
    minHeight: '25px',
    color: '#000000',
    textTransform: 'capitalize',
    fontWeight: 600,
    padding: '0 0.8rem',
  },

  pagination: {
    '& button': {
      border: 'none',
    },
    '& button[data-active]': {
      color: '#1b5390  ',
      background: 'none !important',
      backgroundColor: 'none !important',
      borderBottom: '0px !important',
      fontWeight: 700,
    },
  },
})); // TODO: update styles

const Telehealth: React.FC = () => {
  const medplum = useMedplum();
  const navigate = useNavigate();
  const { classes } = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [offset, setOffset] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<Bundle<Appointment> | undefined>(undefined);
  const [totalItems, setTotalItems] = useState(0);
  const itemsPerPage = 10;
  const [statuses, setStatuses] = useState([]);
  const [activeTab, setActiveTab] = useState('all');
  const [advancedFilters, setAdvancedFilters] = useState<any>([]);

  const filterTabs = [
    { label: 'All', value: 'all' },
    { label: 'Pre-Booked', value: 'pending' },
    { label: 'In Office', value: 'in-office' },
    { label: 'Completed', value: 'fulfilled' },
    { label: 'Cancelled', value: 'cancelled' },
  ];

  const fetchData = async (): Promise<void> => {
    setIsLoading(true);
    let filters = [{ code: 'service-type', operator: Operator.EQUALS, value: 'Telehealth' },
      { code: '_sort', operator: Operator.EQUALS, value: '-date' }
    ];
    if (activeTab === 'in-office') {
      filters = [{ code: 'service-type', operator: Operator.EQUALS, value: 'In Office' }];
    }

    filters = advancedFilters.length ? [...filters, ...advancedFilters] : filters;

    const query: SearchRequest = {
      resourceType: 'Appointment',
      filters: filters,
      offset: offset,
      count: itemsPerPage,
      total: 'accurate',
    };

    if (activeTab !== 'all' && activeTab !== 'in-office') {
      query.filters?.push({ code: 'status', operator: Operator.EQUALS, value: activeTab });
    }

    try {
      const response: any = await medplum.readResource('Appointment', formatSearchQuery(query));
      setData(response.entry);
      setTotalItems(response.total ?? 0);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getAppointmentStatuses = async (): Promise<void> => {
    try {
      const body = {
        url: 'http://hl7.org/fhir/ValueSet/appointmentstatus|4.0.1',
        filter: '',
      }

      const response: any = await medplum.get(`/fhir/R4/ValueSet/$expand?url=${body.url}&filter=${body.filter}`);
      if (response?.expansion?.contains) {
        const statuses = response.expansion.contains;
        setStatuses(statuses);
      }
    } catch (error) {
      console.error('Error fetching statuses:', error);
    }
  };

  useEffect(() => {
    fetchData();
    getAppointmentStatuses();
  }, [offset, medplum, activeTab, advancedFilters]);

  const handlePageChange = (page: number): void => {
    setOffset((page - 1) * itemsPerPage);
  };

  const handleRefresh = (): void => {
    setOffset(0);
    setActiveTab('all');
    fetchData();
  };

  const handleTabChange = (newTab: string): void => {
    setActiveTab(newTab);
  };

  const handleFilters = (filters: any): void => {
    const { date, location, practitioner, patient } = filters;
    const practitionerReference = practitioner ? `Practitioner/${practitioner}` : '';
    const locationReference = location ? `Location/${location}` : '';
    const patientReference = patient ? `Patient/${patient}` : '';
    const newDate = date ? new Date(date) : null;
    const nextDate = newDate ? new Date(newDate.setDate(newDate.getDate() + 1)).toISOString() : null;    

    const updatedFilters = [
      { code: 'date', operator: Operator.GREATER_THAN_OR_EQUALS, value: date },
      { code: 'date', operator: Operator.LESS_THAN, value: nextDate },
      { code: 'location', operator: Operator.EQUALS, value: locationReference },
      { code: 'practitioner', operator: Operator.EQUALS, value: practitionerReference },
      { code: 'patient', operator: Operator.EQUALS, value: patientReference },
    ]?.filter(filter => filter.value);

    setAdvancedFilters(updatedFilters);
  }

  const handleStartCall = (appointmentId: string) => {
    if (!appointmentId) {
      console.error('Appointment ID not found');
      return;
    }
    handleRefresh();
    setIsModalOpen(false);
    navigate(`/Telehealth/${appointmentId}/meeting`);
  };

  return (
    <Paper className="telehealth-container">
      <TitleComponent title="Telehealth" />

      <Box m="md" mb={0} style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Group style={{ gap: 0 }}>
          <Button
            leftIcon={<IconCirclePlus color="#112950" size={18} />}
            variant="default"
            style={{
              border: 'none',
              fontFamily: 'Inter',
              fontWeight: 500,
              color: '#112950',
            }}
            onClick={() => setIsModalOpen(true)}
          >
            New Appointment
          </Button>
          <Button
            leftIcon={<IconRefresh color="#112950" size={18} />}
            variant="default"
            style={{
              border: 'none',
              fontFamily: 'Inter',
              fontWeight: 500,
              color: '#112950'
            }}
            onClick={() => handleRefresh()}
          >
            Refresh
          </Button>
        </Group>
        
        <Group>
          {totalItems && (
            <Text size={12} ff='Inter' color='#112950'>
              Showing {((offset + 1) * itemsPerPage)} of {totalItems?.toLocaleString()}
            </Text>
          )}
        </Group>
      </Box>

      <Box mt={0} className='resource-tab-panel patient-list'>
        <TelehealthFilters onApplyFilters={handleFilters} />

        <Tabs mt="md" variant="pills" value={activeTab} className={classes.tabs} onTabChange={handleTabChange}>
          <Tabs.List w="min-content" style={{ borderBottom: '1px solid #EAECF0 !important', whiteSpace: 'nowrap', flexWrap: 'nowrap' }}>
            {filterTabs.map((tab) => (
              <Tabs.Tab key={tab.value} value={tab.value}>
                {tab.label}
              </Tabs.Tab>
            ))}
          </Tabs.List>

          {filterTabs.map((tab) => (
            <Tabs.Panel key={tab.value} value={tab.value} pt="md" className={classes.tabPanel}>
              <TelehealthTable
                data={data}
                isLoading={isLoading}
                statuses={statuses}
                handleStartCall={(value) =>handleStartCall(value)}
              />
            </Tabs.Panel>
          ))}
        </Tabs>


        <Pagination
          total={Math.ceil(totalItems / itemsPerPage)}
          position="right"
          onChange={handlePageChange}
          className={classes.pagination}
          pt="md"
        />
      </Box>

      <TelehealthModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onStartCall={(value) => handleStartCall(value)}
      />
    </Paper>
  );
};

export default Telehealth;
