import { Card, Text, Group, createStyles, Center } from '@mantine/core';
import { IconArrowRight } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import { IconAndDetails, IconAndText } from './ProvidersDashboard';

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
  },

  section: {
    padding: theme.spacing.sm,
    borderBottom: `1px solid #EEF1F6 !important`,
  },

  footer: {
    padding: theme.spacing.md,
    borderTop: `1px solid #EEF1F6 !important`,
  },

  icon: {
    padding: theme.spacing.xs,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '40px',
    width: '40px',
    borderRadius: '25px',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[5],
  },

  scrollbar: {
    overflow: 'hidden',
    '&:hover': {
      overflowY: 'auto',
      scrollbarWidth: 'thin',
      scrollbarColor: '#ccc #f3f3f3',
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#ccc',
        borderRadius: '8px',
        '&:hover': {
          background: '#aaa',
        },
      },
    },
  },
}));

const data = [
  {
    id: '1',
    name: 'Abilify Maintena 1',
    quantity: '200 mg',
    time: '10:00 am',
    duration: 'Daily',
  },
  {
    id: '2',
    name: 'Abilify Maintena 2',
    quantity: '500 mg',
    time: '10:00 pm',
    duration: 'Daily',
  },
];

export function MedicationsCard() {
  const { classes } = useStyles();

  const organisationDetails = data.map((details) => (
    <Card.Section key={details.id} display="flex" className={classes.section}>
      <Group style={{ justifyContent: 'flex-start', flex: 1, alignItems: 'flex-start' }}>
        <div className={classes.icon}>
          <img src="../../img/dashboard-assets/pill.svg" height="20px" />
        </div>
      </Group>
      <Group style={{ justifyContent: 'flex-start', flex: 5 }}>
        <div>
          <Text size="sm" fw={600}>
            {details.name}
          </Text>
          <Center style={{ justifyContent: 'flex-start' }}>
            <IconAndDetails ImageSrc="" text={details.quantity} />
            <IconAndDetails ImageSrc="../../../img/dashboard-assets/clock.svg" text={details.time} />
          </Center>
        </div>
      </Group>
      <Group style={{ justifyContent: 'flex-start', flex: 1, alignItems: 'flex-start' }}>
        <Text size="xs" fw={400} fs="12px">
          {details.duration}
        </Text>
      </Group>
    </Card.Section>
  ));

  return (
    <Card shadow="sm" radius="md" className={classes.card}>
      <Card.Section className={classes.section}>
        <IconAndText ImageSrc="../../../img/dashboard-assets/circle-shield-plus.svg" text={'Medications'} />
      </Card.Section>

      <Group display="block" className={classes.scrollbar} style={{ height: '200px', overflowX: 'hidden' }}>
        {organisationDetails}
      </Group>

      <Card.Section className={classes.footer}>
        <Group style={{ alignItems: 'flex-end' }} spacing={30}>
          <div style={{ textDecoration: 'none', alignItems: 'flex-end', flex: 2 }}></div>
          <Link
            style={{
              textDecoration: 'none',
              alignItems: 'center',
              justifyContent: 'flex-end',
              flex: 1,
              color: '#222',
              display: 'flex',
            }}
            to={''}
          >
            <Text fw={700} mr="5px">
              View All
            </Text>
            <IconArrowRight size="16px" />
          </Link>
        </Group>
      </Card.Section>
    </Card>
  );
}
