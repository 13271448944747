import { Box, Center, Grid, Title, createStyles, Text } from '@mantine/core';
import { SignInForm, useMedplum } from '@medplum/react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { getConfig } from './config';

const useStyles = createStyles((theme) => ({
  hiddenMobile: {
    [theme.fn.smallerThan('md')]: {
      display: 'none',
    },
  },

  paddingMobile: {
    [theme.fn.smallerThan('sm')]: {
      padding: '25px !important',
    },
  },

  primaryTitle: {
    textAlign: 'left',
    color: '#000',
    float: 'left',
    fontSize: '34px',
    lineHeight: '1.1',
    fontWeight: 300,
    marginBottom: '8px',
  },

  secondaryTitle: {
    color: '#000',
    float: 'left',
    fontSize: '28px',
    lineHeight: '1.1',
    fontWeight: 700,
    marginBottom: '8px',
  },

  subTitle: {
    color: '#94a3b8',
    marginTop: '10px',
    letterSpacing: '-0.025em',
    fontSize: '14px',
    fontWeight: 300,
  },
}));

export function SignInPage(): JSX.Element {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const config = getConfig();
  const { classes } = useStyles();
  const medplum = useMedplum();

  const onSignIn = () => {
    if (medplum.isSuperAdmin()) {
      navigate(searchParams.get('next') || '/');
    } else if (medplum.getUserConfiguration()?.menu?.[0]?.link?.[0].target) {
      navigate(medplum.getUserConfiguration()?.menu?.[0]?.link?.[0].target ?? '/');
    } else {
      navigate('/');
    }
  }

  return (
    <Grid style={{ background: '#E9F3FF' }}>
      <Grid.Col
        md={6}
        lg={6}
        style={{ alignContent: 'center', justifyContent: 'center', minHeight: '100vh' }}
        className={classes.hiddenMobile}
      >
        <Box className={classes.paddingMobile}>
          <Center
            p={{ xs: 'lg', md: 'xs' }}
            style={{
              alignContent: 'center',
              alignItems: 'center',
              justifyContent: 'center',
              margin: 'auto',
              minHeight: '100vh',
            }}
          >
            <Grid sx={{ pl: 3, pr: 3 }} style={{ justifyContent: 'center' }}>
              <Grid.Col md={9} lg={9} xs={12} mb="lg">
                <Link to="https://pragmaconnect.com/" target="_blank">
                  <img src="../img/pragmaconnect-logo.svg" height={35} />
                </Link>
              </Grid.Col>
              <Grid.Col md={8} lg={8} xs={12}>
                <Title variant="h1" fw="300" className={classes.primaryTitle} w="100%">
                  Welcome to <span style={{ fontWeight: 600 }}>{'PragmaConnect'}</span>
                </Title>
                <Text variant="h5" className={classes.subTitle} mb="sm" w="100%">
                  One Platform for All HealthCare Integrations
                </Text>
                <Box>
                  <img src="../img/signin-bg.svg" style={{ width: '100%', height: 'auto', marginTop: '20px' }} />
                </Box>
              </Grid.Col>
            </Grid>
          </Center>
        </Box>
      </Grid.Col>
      <Grid.Col md={6} lg={6} className={classes.paddingMobile}>
        <Center
          p={{ xs: 'lg', md: 'xs' }}
          style={{ alignContent: 'center', justifyContent: 'center', minHeight: '100vh', width: '100%' }}
        >
          <SignInForm
            onSuccess={onSignIn}
            // onForgotPassword={() => navigate('/resetpassword')}
            // register new user is disabled
            // onRegister={config.registerEnabled ? () => navigate('/register') : undefined}
            googleClientId={config.googleClientId}
            login={searchParams.get('login') || undefined}
            projectId={searchParams.get('project') || undefined}
          >
            <Center display="flex" m={{ xs: 'lg', md: 'xs' }} style={{ flexDirection: 'column', minWidth: '350px' }}>
              <Title className={classes.secondaryTitle} variant="h2" fw={700} mb={3}>
                Sign In
              </Title>
              <p>Please log in to your account to proceed.</p>

              {searchParams.get('project') === 'new' && <div>Sign in again to create a new project</div>}
            </Center>
            {/* <Link to="/login" style={{ color: '#2f67ad' }}>
              Login With SSO
            </Link> */}
          </SignInForm>
        </Center>
      </Grid.Col>
    </Grid>
  );
}
