/* eslint-disable no-nested-ternary */
/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-debugger */
import React, { useEffect, useState } from 'react';
import { Box, Card, Grid, createStyles, Text, Tabs, Center, Skeleton } from '@mantine/core';
import { Cell, Legend, Pie, PieChart, BarChart, Bar, XAxis, Tooltip, Sector } from 'recharts';
import RegisterPatientsList from './RegisterPatientsList';
import TitleComponent from '../TitleComponent';
import { getDemographic, patientCountsByEhr, patientCountsByOrganization, registeredPatients } from '../../fhirApi';
import { useMedplum } from '@medplum/react';

const COLORS = ['#89AED3', '#7FD3EC', '#EDB089', '#D9DADB'];
const colors = ['#1B5390', '#4D8CD0', '#8DBEF3', '#3673B6', '#8DBEF3', '#4D8CD0'];

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    height: '100%',
  },

  section: {
    padding: theme.spacing.md,
  },

  header: {
    padding: theme.spacing.md,
    borderBottom: `1px solid #EEF1F6 !important`,
  },

  icon: {
    padding: theme.spacing.xs,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#EDF5FF',
    height: '40px',
    width: '40px',
    borderRadius: '25px',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[5],
  },

  scrollbar: {
    overflow: 'hidden',
    '&:hover': {
      overflowY: 'auto',
      scrollbarWidth: 'thin',
      scrollbarColor: '#ccc #f3f3f3',
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#ccc',
        borderRadius: '8px',
        '&:hover': {
          background: '#aaa',
        },
      },
    },
  },

  customTabs: {
    '& button': {
      fontSize: '14px',
      color: '#000',
      background: '#eee',
      marginRight: '10px',
    },
    '& button[data-active]': {
      color: '#000',
      background: '#C9E1FF !important',
      backgroundColor: '#C9E1FF !important',
      fontWeight: 'bold',
    },
  },

  demographicsChartWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    '@media (max-width: 767px)': {
      flexDirection: 'column',
    },
  },
  demographicsChartLegend: {
    width: '40%',
    '@media (max-width: 767px)': {
      width: '100%',
    },
  },
  demographicsChartChart: {
    width: '60%',
    '@media (max-width: 767px)': {
      width: '100%',
      marginTop: '1rem',
    },
  },

  customTabPanel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  label: {
    fontWeight: 500,
    fontSize: theme.fontSizes.sm,
  },

  activeLabel: {
    fontWeight: 700,
    fontSize: theme.fontSizes.md,
  },

  activeBullet: {
    width: '20px',
    height: '20px',
  },
}));

const tabs = ['Race', 'Ethnicity', 'Age', 'Gender'];

export function PatientDashboard() {
  const [selected, setSelected] = useState({ id: 'all', label: 'All' });
  const { classes } = useStyles();
  const [activeIndex, setActiveIndex] = useState(-1);
  const [patientsByEhrType, setPatientsByEhrType] = useState([]);
  const [demographicData, setDemographicData] = useState<any | null>([]);
  const [newPatientRegistered, setNewPatientRegistered] = useState<any | null>([]);
  const [organizationCountResponse, setorganizationCountResponse] = useState<any | null>();
  const medplum = useMedplum();
  useEffect(() => {
    // Use a single call to initiate all three asynchronous operations
    Promise.all([
      getPatientCountList(),
      getRegisteredPatients('')
    ]).catch((error) => {
      console.error('Error fetching data:', error);
    });

  }, []);

  // Patient Count api is calling
  const getPatientCountList = () => {
    return patientCountsByEhr(medplum).then((response) => {
      const updatedResponse = response?.data.map((item: any, index: number) => ({
        ...item,
        id: index + 1,
      }));
      setPatientsByEhrType(updatedResponse);
    });
  };

  // Demographic api is calling
  const getDemographicList = (ehr: string) => {
    return getDemographic(medplum, ehr).then((response) => {
      setDemographicData(response?.data);
    });
  };

  // bar chart api is calling
  const getRegisteredPatients = (ehr: string) => {
    return registeredPatients(medplum, ehr).then((response) => {
      setNewPatientRegistered(response?.data);
    });
  };

  const getPatientCountsByOrganization = (ehr: string) => {
    patientCountsByOrganization(medplum, ehr)
      .then((response) => {
        setorganizationCountResponse(response?.data);
      })
      .catch((error: any) => {
        console.error('Error fetching data:', error);
      });
  };

  const ehrTypesWithAll = [
    { id: 'all', name: 'All', counts: patientsByEhrType?.reduce((acc: any, cur: any) => acc + cur.counts, 0) },
    ...patientsByEhrType || [],
  ];
  const [selectedEhrType, setEhrType] = React.useState(ehrTypesWithAll[0]);

  const ActiveShape = (props: any) => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = props;

    return selectedEhrType?.name !== 'All' ? (
      <g>
        <text x={cx} y={cy - 10} dy={8} textAnchor="middle">
          {selectedEhrType?.name}
        </text>
        <text x={cx} y={cy + 10} dy={8} textAnchor="middle" fontWeight={700}>
          {selectedEhrType?.counts}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius - 5}
          outerRadius={outerRadius + 5}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
      </g>
    ) : (
      <g>
        <text x={cx} y={cy - 10} dy={8} textAnchor="middle">
          {selectedEhrType?.name}
        </text>
        <text x={cx} y={cy + 10} dy={8} textAnchor="middle" fontWeight={700}>
          {selectedEhrType?.counts}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
      </g>
    );
  };

  useEffect(() => {
    const selectedIdString = selected.id.toString();
    const patientIndex = patientsByEhrType?.findIndex((item: any) => item.id.toString() === selectedIdString);
    setActiveIndex(selectedIdString === 'all' ? -1 : patientIndex);

    ehrTypesWithAll.filter((item) => {
      if (item.id.toString() === selected.id) {
        setEhrType(item);
      }
    });

    if (selected.label === 'All') {
      getDemographicList('');
      getPatientCountsByOrganization('');
    } else {
      getDemographicList(selected.label);
      getPatientCountsByOrganization(selected.label);
    }

  }, [selected]);

  const handleEhrTypeSelect = (selectedItem: any) => {
    setSelected(selectedItem);
  };

  return (
    <>
      <TitleComponent
        title="Patients"
        EhrFilter={true}
        selectedLabel={selectedEhrType ? selectedEhrType.name : ''}
        selectedEhrType={selectedEhrType}
        onEhrTypeSelect={handleEhrTypeSelect}
      />
      <Box px="lg" py="sm">
        <Grid>
          <Grid.Col span={12} sm={6} lg={6}>
            <RegisterPatientsList selectedEHR={selected} organizationResponse={organizationCountResponse} />
          </Grid.Col>
          <Grid.Col span={12} sm={6} lg={6}>
            <Card withBorder radius="md" className={classes.card}>
              <Card.Section className={classes.header} p="lg">
                <Text fw={700}>Patients Registered under each EHR system</Text>
              </Card.Section>
              {patientsByEhrType?.length !== 0 ? (
                <Card.Section
                  className={classes.section}
                  p="lg"
                  style={{ minHeight: '300px', borderBottom: '0px !important', flexDirection: 'row' }}
                  display={{ xs: 'block', md: 'block', lg: 'flex' }}
                >
                  <Center p="md" display={{ xs: 'block', md: 'block', lg: 'flex' }} style={{ flex: 1 }}>
                    <Legend
                      wrapperStyle={{
                        position: 'relative',
                        border: '1px solid #EEF1F6',
                        padding: '20px',
                        borderRadius: '5px',
                        boxSizing: 'border-box',
                      }}
                      content={
                        <Box
                          display={{ xs: 'block', md: 'block', lg: 'flex' }}
                          style={{ width: '100%', justifyContent: 'center', flexWrap: 'wrap' }}
                        >
                          {patientsByEhrType?.map((entry: any, index: any) => (
                            <Center
                              key={`legend-${index}`}
                              style={{ marginBottom: '10px', justifyContent: 'flex-start', width: '100%' }}
                            >
                              <div
                                className={selected.id === entry.id ? classes.activeBullet : ''}
                                style={{
                                  backgroundColor: COLORS[index % COLORS.length],
                                  width: '12px',
                                  height: '12px',
                                  marginRight: '10px',
                                  borderRadius: '25px',
                                }}
                              ></div>
                              <span className={selected.id === entry.id ? classes.activeLabel : classes.label}>
                                {selected.id === 'all' ? `${entry.name} : ${entry.counts}` : entry.name}
                              </span>
                            </Center>
                          ))}
                        </Box>
                      }
                    />
                  </Center>
                  <Center style={{ flex: 1 }}>
                    <PieChart width={300} height={300}>
                      <Pie
                        activeIndex={activeIndex}
                        data={patientsByEhrType}
                        cx="50%"
                        cy="50%"
                        innerRadius={70}
                        outerRadius={110}
                        fill="#8884d8"
                        paddingAngle={0}
                        dataKey="counts"
                        activeShape={ActiveShape}
                        label
                      >
                        {patientsByEhrType?.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} style={{ outline: 'none' }} />
                        ))}
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </Center>
                </Card.Section>
              ) : (
                <>
                  <Skeleton height={8} radius="xl" />
                  <Skeleton height={8} mt={6} radius="xl" />
                  <Skeleton height={8} mt={6} width="70%" radius="xl" />
                </>
              )}
            </Card>
          </Grid.Col>
        </Grid>
      </Box>
      <Box px="lg" py="sm">
        <Grid mb="md">
          <Grid.Col span={12} sm={12} lg={6} style={{}}>
            <Card withBorder radius="md" className={classes.card}>
              <Card.Section className={classes.header} p="lg">
                <Text fw={700}>New Patients Registered</Text>
              </Card.Section>
              {newPatientRegistered?.length !== 0 ? (
                <Card.Section className={classes.section} p="lg" style={{ borderBottom: '0px !important' }}>
                  <Text variant="xs" mb="md">
                    {'This data is extracted from patient records and EHRs.'}
                  </Text>
                  <Center>
                    {' '}
                    <BarChart width={500} height={200} data={newPatientRegistered} barSize={25}>
                      <XAxis dataKey="name" axisLine={false} tickLine={false} />
                      <Tooltip />
                      {newPatientRegistered[0]?.patientCounts?.map((ehrType: any, index: number) => {
                        // let isHighlighted;
                        const fill = colors[index % 20];

                        // console.log(selected.label);
                        // const indexA = data.findIndex((item) =>
                        //   item.patientCounts.some((counts) => counts[selected.label]),
                        // );

                        // if (indexA !== -1) {
                        //   console.log(index)
                        //   isHighlighted = index == indexA;
                        //   fill = isHighlighted ? '#2F67AD' : colors[index % 20];
                        // }

                        return (
                          <Bar
                            key={Object.keys(ehrType)[0]}
                            dataKey={`patientCounts[${index}].${Object.keys(ehrType)[0]}`}
                            stackId="stack"
                            fill={fill}
                            name={`${Object.keys(ehrType)}`}
                          />
                        );
                      })}
                    </BarChart>
                  </Center>
                </Card.Section>
              ) : (
                <>
                  <Skeleton height={8} radius="xl" />
                  <Skeleton height={8} mt={6} radius="xl" />
                  <Skeleton height={8} mt={6} width="70%" radius="xl" />
                </>
              )}
            </Card>
          </Grid.Col>
          <Grid.Col span={12} sm={12} lg={6}>
            <Card withBorder radius="md" className={classes.card}>
              <Card.Section className={classes.header} p="lg">
                <Text fw={700}>Demographics</Text>
              </Card.Section>
              {demographicData?.length !== 0 ? (
                <Card.Section className={classes.section} p="lg" style={{}}>
                  <Tabs variant="pills" defaultValue="race" className={classes.customTabs}>
                    <Tabs.List style={{ whiteSpace: 'nowrap', flexWrap: 'nowrap' }}>
                      {tabs.map((t) => (
                        <Tabs.Tab key={t} value={t.toLowerCase()}>
                          {t}
                        </Tabs.Tab>
                      ))}
                    </Tabs.List>
                    <Tabs.Panel value="race" pt="md" className={classes.customTabPanel}>
                      <Box p="md" style={{ flex: 1, width: '40%' }}>
                        <Legend
                          wrapperStyle={{
                            position: 'relative',
                            border: '1px solid #EEF1F6',
                            padding: '10px',
                            borderRadius: '5px',
                            boxSizing: 'border-box',
                          }}
                          content={
                            <Box style={{ display: 'flex', width: '100%', justifyContent: 'center', flexWrap: 'wrap' }}>
                              {demographicData?.race?.map((entry: any, index: number) => (
                                <Center
                                  key={`legend-${index}`}
                                  style={{ marginBottom: '10px', justifyContent: 'flex-start', width: '40%' }}
                                >
                                  <div
                                    style={{
                                      backgroundColor: COLORS[index % COLORS.length],
                                      width: '12px',
                                      height: '12px',
                                      marginRight: '10px',
                                      borderRadius: '25px',
                                    }}
                                  ></div>
                                  <span>{entry.name}</span>
                                </Center>
                              ))}
                            </Box>
                          }
                        />
                      </Box>
                      <PieChart width={300} height={220} style={{ flex: 1, width: '50%' }}>
                        <Tooltip />
                        <Pie
                          data={demographicData?.race}
                          innerRadius={50}
                          outerRadius={80}
                          fill="#8884d8"
                          paddingAngle={0}
                          dataKey="patientCounts"
                          label
                        >
                          {demographicData?.race?.map((enrty: any, index: number) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                              style={{ outline: 'none' }}
                            />
                          ))}
                        </Pie>
                      </PieChart>
                    </Tabs.Panel>
                    <Tabs.Panel value="ethnicity" pt="md" className={classes.customTabPanel}>
                      <Box p="md" style={{ flex: 1, width: '40%' }}>
                        <Legend
                          wrapperStyle={{
                            position: 'relative',
                            border: '1px solid #EEF1F6',
                            padding: '10px',
                            borderRadius: '5px',
                            boxSizing: 'border-box',
                          }}
                          content={
                            <Box style={{ display: 'flex', width: '100%', justifyContent: 'center', flexWrap: 'wrap' }}>
                              {demographicData?.ethnicity?.map((entry: any, index: number) => (
                                <Center
                                  key={`legend-${index}`}
                                  style={{ marginBottom: '10px', justifyContent: 'flex-start', width: '40%' }}
                                >
                                  <div
                                    style={{
                                      backgroundColor: COLORS[index % COLORS.length],
                                      width: '12px',
                                      height: '12px',
                                      marginRight: '10px',
                                      borderRadius: '25px',
                                    }}
                                  ></div>
                                  <span>{entry.name}</span>
                                </Center>
                              ))}
                            </Box>
                          }
                        />
                      </Box>
                      <PieChart width={300} height={220} style={{ flex: 1, width: '50%' }}>
                        <Tooltip />
                        <Pie
                          data={demographicData?.ethnicity}
                          innerRadius={50}
                          outerRadius={80}
                          fill="#8884d8"
                          paddingAngle={0}
                          dataKey="patientCounts"
                          label
                        >
                          {demographicData?.ethnicity?.map((enrty: any, index: number) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                              style={{ outline: 'none' }}
                            />
                          ))}
                        </Pie>
                      </PieChart>
                    </Tabs.Panel>
                    <Tabs.Panel value="age" pt="md" className={classes.customTabPanel}>
                      <div className={classes.demographicsChartWrapper}>
                        <Box p="md" className={classes.demographicsChartLegend}>
                          <Legend
                            wrapperStyle={{
                              position: 'relative',
                              border: '1px solid #EEF1F6',
                              padding: '10px',
                              borderRadius: '5px',
                              boxSizing: 'border-box',
                            }}
                            content={
                              <Box style={{ display: 'flex', width: '100%', justifyContent: 'center', flexWrap: 'wrap' }}>
                                {demographicData?.age?.map((entry: any, index: number) => (
                                  <Center
                                    key={`legend-${index}`}
                                    style={{ marginBottom: '10px', justifyContent: 'flex-start', width: '40%' }}
                                  >
                                    <div
                                      style={{
                                        backgroundColor: colors[index % colors.length],
                                        width: '12px',
                                        height: '12px',
                                        marginRight: '10px',
                                        borderRadius: '25px',
                                      }}
                                    ></div>
                                    <span>{entry.age}</span>
                                  </Center>
                                ))}
                              </Box>
                            }
                          />
                        </Box>
                        <Box className={classes.demographicsChartChart}>
                          <BarChart width={300} height={200} data={demographicData?.age} barSize={12}>
                            <XAxis dataKey="name" axisLine={false} tickLine={false} />
                            <Tooltip />
                            <Bar dataKey="patientCounts" fill="#8884d8" label={{ position: 'top' }}>
                              {demographicData?.age?.map((entry: any, index: number) => (
                                <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                              ))}
                            </Bar>
                          </BarChart>
                        </Box>
                      </div>
                    </Tabs.Panel>
                    <Tabs.Panel value="gender" pt="md" className={classes.customTabPanel}>
                      <Box p="md" style={{ flex: 1, width: '40%' }}>
                        <Legend
                          wrapperStyle={{
                            position: 'relative',
                            border: '1px solid #EEF1F6',
                            padding: '20px',
                            borderRadius: '5px',
                            boxSizing: 'border-box',
                          }}
                          content={
                            <Box style={{ display: 'flex', width: '100%', justifyContent: 'center', flexWrap: 'wrap' }}>
                              {demographicData?.gender?.map((entry: any, index: number) => (
                                <Center
                                  key={`legend-${index}`}
                                  style={{ marginBottom: '10px', justifyContent: 'flex-start', width: '40%' }}
                                >
                                  <div
                                    style={{
                                      backgroundColor: COLORS[index % COLORS.length],
                                      width: '12px',
                                      height: '12px',
                                      marginRight: '10px',
                                      borderRadius: '25px',
                                    }}
                                  ></div>
                                  <span>{entry.name}</span>
                                </Center>
                              ))}
                            </Box>
                          }
                        />
                      </Box>
                      <PieChart width={300} height={220} style={{ flex: 1, width: '50%' }}>
                        <Tooltip />
                        <Pie
                          data={demographicData?.gender}
                          dataKey="patientCounts"
                          nameKey="name"
                          cx="50%"
                          cy="50%"
                          outerRadius={80}
                          label
                        >
                          {demographicData?.gender?.map((enrty: any, index: number) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                              style={{ outline: 'none' }}
                            />
                          ))}
                        </Pie>
                      </PieChart>
                    </Tabs.Panel>
                  </Tabs>
                </Card.Section>
              ) : (
                <>
                  <Skeleton height={8} radius="xl" />
                  <Skeleton height={8} mt={6} radius="xl" />
                  <Skeleton height={8} mt={6} width="70%" radius="xl" />
                </>
              )}
            </Card>
          </Grid.Col>
        </Grid>
      </Box>
    </>
  );
}
