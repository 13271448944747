import { Card, Text, Group, createStyles, Button, Skeleton } from '@mantine/core';
import { IconArrowRight } from '@tabler/icons-react';
import { Link, useNavigate } from 'react-router-dom';
import { patientsByOrganization } from '../fhirApi';
import { useMedplum } from '@medplum/react';
import { useEffect, useLayoutEffect, useState } from 'react';
import Each from './Each';

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
  },

  section: {
    padding: theme.spacing.sm,
    borderBottom: `1px solid #EEF1F6 !important`,
  },

  footer: {
    padding: theme.spacing.md,
    borderTop: `1px solid #EEF1F6 !important`,
  },

  scrollbar: {
    overflow: 'hidden',
    '&:hover': {
      overflowY: 'auto',
      scrollbarWidth: 'thin',
      scrollbarColor: '#ccc #f3f3f3',
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#ccc',
        borderRadius: '8px',
        '&:hover': {
          background: '#aaa',
        },
      },
    },
  },

  badge: {
    minWidth: '80px',
    minHeight: '30px',
    color: '#000000',
    textTransform: 'capitalize',
    fontWeight: 600,
  },
}));

export interface patientOrganization {
  id: string,
  name: string,
  gender: string,
  birthDate: string,
}

export function PatientsList(props: any) {
  const { classes } = useStyles();
  const medplum = useMedplum();
  const navigate = useNavigate();
  const [patientsList, setPatientList] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isDataFound, setIsDataFound] = useState<boolean>(false);

  const fetchData = async () => {
    try {
      const response = await patientsByOrganization(medplum, props.orgId);
      if (response?.data.length !==0) {
        setPatientList(response?.data || []);
      } else {
        setIsDataFound(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [props.orgId]);

  useLayoutEffect(() => {
    console.log('1st');
  }, []);

  return (
    <Card withBorder radius="md" className={classes.card}>
      <Card.Section className={classes.section} p="lg">
        <Text className="darkText" fw={700}>
          Patients List
        </Text>
      </Card.Section>

      <Card.Section px="lg" display="flex" className={classes.section}>
        <Group style={{ justifyContent: 'flex-start', flex: 2 }}>
          <Text className="darkText" fw={700}>
            Patient Name
          </Text>
        </Group>
        <Group style={{ justifyContent: 'flex-start', flex: 2 }}>
          <Text className="darkText" fw={700}>
            Gender
          </Text>
        </Group>
        <Group style={{ justifyContent: 'flex-start', flex: 2 }}>
          <Text className="darkText" fw={700}>
            Date of Birth
          </Text>
        </Group>
        <Group style={{ justifyContent: 'flex-start', flex: 1 }}>
          <Text className="darkText" fw={700}>
            Details
          </Text>
        </Group>
      </Card.Section>

      <Group px="xs" className={classes.scrollbar} display="block" style={{ height: '281px', overflowX: 'hidden' }}>
        <Each
          of={patientsList}
          render={(item: patientOrganization) => (
            <Card.Section display="flex" className={classes.section}>
            <Group style={{ justifyContent: 'flex-start', flex: 2 }}>
              <Text className="f-main" fw={500} onClick={() => navigate(`/Patient/patient-dashboard/${item.id}` )} style={{ cursor: 'pointer' }}>
                {item.name}
              </Text>
            </Group>
            <Group style={{ justifyContent: 'flex-start', flex: 2 }}>
              <Text className="lightText" fw={500}>
                {item.gender}
              </Text>
            </Group>
            <Group style={{ justifyContent: 'flex-start', flex: 2 }}>
              <Text className="lightText" fw={500}>
                {item.birthDate === 'unknown' ? '-' : item.birthDate}
              </Text>
            </Group>
            <Group style={{ justifyContent: 'flex-start', flex: 1 }}>
              <Button
                compact
                size="xs"
                radius="xs"
                style={{
                  textDecoration: 'none',
                  color: '#fff',
                  backgroundColor: '#3673B6',
                }}
              >
                View Profile
              </Button>
            </Group>
          </Card.Section>
          )}
        />
        {isDataFound && (
          <Group style={{ justifyContent: 'center', flex: 1, position: 'relative', top: '50%' }}>
            <Text className="darkText" fw={700}>
              No Data Found
            </Text>
          </Group>
        )}
        {isLoading && (
           <>
            <Skeleton height={8} radius="xl" />
            <Skeleton height={8} mt={6} radius="xl" />
            <Skeleton height={8} mt={6} width="70%" radius="xl" />
          </>
        )}
      </Group>

      <Card.Section className={classes.footer}>
        <Group style={{ alignItems: 'flex-end' }} spacing={30}>
          <div style={{ textDecoration: 'none', alignItems: 'flex-end', flex: 2 }}></div>
          <Link
            style={{
              textDecoration: 'none',
              alignItems: 'center',
              justifyContent: 'flex-end',
              flex: 1,
              color: '#5D6874',
              display: 'flex',
            }}
            to={''}
          >
            <Text fw={700} mr="5px">
              View All
            </Text>
            <IconArrowRight size="16px" />
          </Link>
        </Group>
      </Card.Section>
    </Card>
  );
}
