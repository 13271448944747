import { useContext, useState, useEffect, useCallback } from 'react';
import { Modal, Button, Group, Radio, TextInput, Text, Paper } from '@mantine/core';
import ZoomContext from '../../context/zoom-context';
import ZoomMediaContext from '../../context/media-context';
import './call-out-modal.scss';
import { CRCReturnCode } from '@zoom/videosdk';
import { getCRCCallStatus } from '../video-constants';

interface CRCCallOutModalProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

const CRCCallOutModal = (props: CRCCallOutModalProps) => {
  const { visible, setVisible } = props;
  const zmClient = useContext(ZoomContext);
  const { mediaStream } = useContext(ZoomMediaContext);
  const [status, setStatus] = useState(-1);
  const [ip, setIp] = useState('');
  const [protocol, setProtocol] = useState(1);

  const onCrcStatusChange = useCallback((payload: any) => {
    const { code } = payload;
    setStatus(code);
  }, []);

  useEffect(() => {
    zmClient.on('crc-call-out-state-change', onCrcStatusChange);
    return () => {
      zmClient.off('crc-call-out-state-change', onCrcStatusChange);
    };
  }, [zmClient, onCrcStatusChange]);

  const handleCall = async () => {
    try {
      if (ip && protocol) {
        mediaStream?.callCRCDevice(ip, protocol);
      } else {
        throw new Error('Please enter a valid IP address and select a protocol');
      }
    } catch (e) {
      console.warn(e);
    }
  };

  const handleCancel = async () => {
    if (status === CRCReturnCode.Ringing) {
      mediaStream?.cancelCallCRCDevice(ip, protocol);
    }
    setVisible(false);
  };

  return (
    <Modal
      opened={visible}
      onClose={handleCancel}
      title="Call a H.323/SIP Room System"
      centered
    >
      <Paper p="md">
        <TextInput
          label="H.323/SIP Room System"
          placeholder="IP Address"
          value={ip}
          onChange={(event) => setIp(event.currentTarget.value)}
          required
        />
        <Radio.Group
          label="Protocol"
          value={protocol.toString()}
          onChange={(value) => setProtocol(parseInt(value, 10))}
          required
          mt="sm"
        >
          <Radio value="1" label="H323" />
          <Radio value="2" label="SIP" />
        </Radio.Group>

        {status !== -1 && (
          <Text mt="sm" color="dimmed">
            CRC call status: 
            <span className={getCRCCallStatus(status)?.type}>
              {getCRCCallStatus(status)?.text}
            </span>
          </Text>
        )}

        <Group position="right" mt="md">
          <Button variant="outline" onClick={handleCancel}>Cancel</Button>
          <Button onClick={handleCall}>Call</Button>
        </Group>
      </Paper>
    </Modal>
  );
};

export default CRCCallOutModal;
