import { useState, useEffect } from 'react';
import { Box, Grid, Table } from '@mantine/core';
import { useMedplum } from '@medplum/react';
import TitleComponent from '../../../components/TitleComponent';
import { getAllClinicalStudyConsents } from '../../../../src/fhirApi';

const ConsentTracker = () => {
  const medplum = useMedplum();
  const [allClinicalStudyConsentsList, setAllClinicalStudyConsentsList] = useState<{ patientName: string, studyName: string, consentDate: Date, condition: string, location: string, consentStatus: string, consentType: string }[]>([]);

  const getAllClinicalStudyConsentsList = async () => {
    try {
      const response = await getAllClinicalStudyConsents(medplum);
      if (response) {
        setAllClinicalStudyConsentsList(response);
      } else {
        console.error('No response received');
      }
    } catch (error) {
      console.error('Error fetching clinical study data:', error);
    }
  };

  useEffect(() => {
    getAllClinicalStudyConsentsList();
  }, []);
  
  return (
    <Box px="lg" py="sm" sx={{ paddingBottom: '0px !important' }}>
      <Grid mb="md">
        <Grid.Col span={12} lg={12}>
          <TitleComponent title={'Consent Tracking'} />
        </Grid.Col>
        
          <Grid.Col span={12} lg={12}>
            <Box p="md">
              <Box
                sx={{
                  border: '1px solid #ccc',
                  borderRadius: '8px',
                  overflowY: 'auto',
                  maxHeight: '400px',
                }}
              >
                <Table striped highlightOnHover>
                  <thead
                    style={{
                      position: 'sticky',
                      top: 0,
                      backgroundColor: '#fff',
                      zIndex: 1,
                    }}
                  >
                    <tr>
                    <th>Patient Name</th>
                    <th>Consent date</th>
                    <th>Clinical Trial</th>
                    <th>Condition</th>
                    <th>Location</th>
                    <th>Consent Type</th>
                    <th>Consent Status</th>
                  </tr>
                  </thead>
                  <tbody>
                  {allClinicalStudyConsentsList.length > 0 ? (
                    allClinicalStudyConsentsList.map((patient, index) => (
                      <tr key={index}>
                        <td>{patient.patientName || '-'}</td>
                        <td>{patient.consentDate ? new Date(patient.consentDate).toLocaleDateString() : '-'}</td>
                        <td>{patient.studyName || '-'}</td>
                        <td>{patient.condition || '-'}</td>
                        <td>{patient.location || '-'}</td>
                        <td>{patient.consentType || '-'}</td>
                        <td>{patient.consentStatus || '-'}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={7}>No qualified patients found.</td>
                    </tr>
                  )}
                  </tbody>
                </Table>
              </Box>
            </Box>
          </Grid.Col>
      </Grid>
    </Box>
  );
};

export default ConsentTracker;
