export const STATUS_COLORS = {
    noshow: '#FF6347',
    proposed: '#ADD8E6',
    active: '#32CD32',
    rejected: '#FF4500',
    inactive: '#A9A9A9',
    'entered-in-error': '#D3D3D3',
    pending: '#FFD274',
    cancelled: '#FF4500',
    booked: '#32CD32',
    fulfilled: '#006400',
    arrived: '#008080',
    'checked-in': '#000080',
    waitlist: '#800080',
} as const;

export type AppointmentStatus = "proposed | pending | booked | arrived | fulfilled | cancelled | noshow | entered-in-error | checked-in | waitlist";