/* eslint-disable @typescript-eslint/prefer-optional-chain */
import React, { useEffect, useState } from 'react';
import NextGenLogo from '../../static/img/logos/nextgen-logo.svg';
import EpicLogo from '../../static/img/logos/epic-logo.svg';
import AthenaLogo from '../../static/img/logos/athena-logo.svg';
import CernerLogo from '../../static/img/logos/cerner-logo.svg';
import { Box, Button, createStyles } from '@mantine/core';
import { useMedplum } from '@medplum/react';
import { Grid, Text, Input, Tooltip } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';

const useStyles = createStyles((theme) => ({
  logoImg: {
    height: '40px',
    width: '100%',
    padding: '5px',
  },

  disableImg: {
    filter: 'invert(0.1) sepia(1) saturate() hue-rotate(179deg)',
    height: '40px',
    width: '100%',
    padding: '5px',
    opacity: '0.4',
  },

  ssoButton: {
    width: '125px',
    border: '1px solid #BBBDBF',
    padding: '10px 0',
    backgroundColor: '#FFFFFF',
    height: '50px',
    '&:hover': {
      backgroundColor: '#FFFFFF',
      border: '1px solid #2f67ad',
    },
  },
  disablebtn: {
    pointerEvents: 'none',
    width: '125px',
    border: '1px solid #BBBDBF',
    padding: '10px 0',
    backgroundColor: '#FFFFFF',
    height: '50px',
    '&:hover': {
      backgroundColor: '#FFFFFF',
      border: '1px solid #2f67ad',
    },
  },
}));

interface SSOButtonsProps {
  organizationList: any;
}
export function SSOButtons(props: SSOButtonsProps): React.JSX.Element {
  const { classes } = useStyles();
  const [selectedValue, setSelectedValue] = useState('');
  const currentPageUri = window.location.href.split('?')[0];
  const [organizations, setOrganizations] = useState([]);
  const [organizationsComplete, setOrganizationsComplete] = useState([]);
  const [ehr, setEhr] = useState();
  const medplum = useMedplum();

  const setLoginType = (event: any) => {
    const organization: any = localStorage.getItem('Organization');
    const orgObject = JSON.parse(organization);
    const orgConfig = orgObject.resource.extension.find((item: any) => item.type === 'provider');
    const aud = orgConfig.apiUrl.slice(0, -1);
    setEhr(orgConfig.ehr);
    window.open(
      `${orgConfig.authorizationUrl}?client_id=${orgConfig.clientId}&response_type=code&redirect_uri=${currentPageUri}&scope=${orgConfig.scope}&aud=${aud}`,
      '_self'
    );
  };

  const handleDropdownChange = (event: any) => {
    const value = event.target.value;
    setSelectedValue(value);

    const findOrganization = (list: any[]) => {
      const organizationsData = list.filter((entry: any) => entry.resource.id === value);
      const organization: any = organizationsData[0];
      localStorage.setItem('Organization', JSON.stringify(organization));
      const orgConfig = organization.resource.extension.find((item: any) => item.type === 'provider');
      setEhr(orgConfig?.ehr);
      localStorage.setItem('EMR_CODE', orgConfig?.ehr);
    };

    if (props.organizationList) {
      findOrganization(props.organizationList);
    } else if (organizationsComplete) {
      findOrganization(organizationsComplete);
    }
  };

  const fetchOrgs = async () => {
    try {
      const response = await medplum.get('/fhir/R4/Organization?_count=100');
      setOrganizationsComplete(response.entry);
      const organizationsData = response.entry
        .filter(
          (entry: any) =>
            entry.resource.extension && entry.resource.extension.find((ext: any) => ext.type === 'provider')
        )
        .map((entry: any) => ({
          id: entry.resource.id,
          name: entry.resource.name,
        }));
      setOrganizations(organizationsData);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (props.organizationList) {
      const organizationsData = props.organizationList.map((entry: any) => ({
        id: entry.resource.id,
        name: entry.resource.name,
      }));
      setOrganizations(organizationsData);
    } else {
      fetchOrgs();
    }
  }, []);

  return (
    <>
      <img src="../../../../img/dashboard-assets/sso-blue.svg" width="35px" />
      <Text variant="h6" className="f-main" style={{ fontSize: '20px', fontWeight: 600, flex: 1, color: '#444444' }}>
        Login to EHR with SSO
      </Text>
      <Text variant="h6" style={{ fontSize: '14px', fontWeight: 400, color: '#888B92', margin: '15px 0 0' }}>
        Choose the organization to proceed with EHR SSO login.
      </Text>
      <Grid sx={{ margin: '0' }}>
        <Grid.Col span={12} lg={12} sx={{ padding: '0 9px' }}>
          <Text style={{ fontSize: '14px', paddingTop: '15px', textAlign: 'left', fontWeight: '500' }}>
            Select Organization
          </Text>
        </Grid.Col>
        <Grid.Col lg={12}>
          <Input
            component="select"
            rightSection={<IconChevronDown size={14} stroke={1.5} />}
            name="ehr"
            value={selectedValue}
            onChange={handleDropdownChange}
            style={{
              fontSize: '14px',
              borderRadius: '4px',
            }}
          >
            <option value="">Select an organization</option>
            {organizations.map((org: any) => (
              <option key={org.id} value={org.id}>
                {org.name}
              </option>
            ))}
          </Input>
        </Grid.Col>
      </Grid>
      <Grid style={{ textAlign: 'center', margin: '0 10px 0 0px' }} mb="md">
        <Box sx={{ margin: '20px 0px 0 10px' }}>
          <Button
            type="button"
            className={ehr === 'EPIC' ? classes.ssoButton : classes.disablebtn}
            onClick={() => setLoginType('epic')}
          >
            <img src={EpicLogo} alt="Epic Logo" className={ehr === 'EPIC' ? classes.logoImg : classes.disableImg} />
          </Button>
        </Box>
        <Box sx={{ margin: '20px 0px 0 15px' }}>
          <Button
            type="button"
            className={ehr === 'NEXTGEN' ? classes.ssoButton : classes.disablebtn}
            onClick={() => setLoginType('nextgen')}
          >
            <img
              src={NextGenLogo}
              alt="NextGen Logo"
              className={ehr === 'NEXTGEN' ? classes.logoImg : classes.disableImg}
            />
          </Button>
        </Box>
        <Box sx={{ margin: '20px 0px 0 15px' }}>
          <Button
            type="button"
            className={ehr === 'CERNER' ? classes.ssoButton : classes.disablebtn}
            onClick={() => setLoginType('cerner')}
          >
            <img
              src={CernerLogo}
              alt="Cerner Logo"
              className={ehr === 'CERNER' ? classes.logoImg : classes.disableImg}
            />
          </Button>
        </Box>
        <Box sx={{ margin: '20px 0px 0 15px' }}>
          <Tooltip label="Coming Soon">
            <Button
              type="button"
              className={ehr === 'ATHENA' ? classes.ssoButton : classes.disablebtn}
              onClick={() => setLoginType('athena')}
            >
              <img
                src={AthenaLogo}
                alt="Athena Logo"
                className={ehr === 'ATHENA' ? classes.logoImg : classes.disableImg}
              />
            </Button>
          </Tooltip>
        </Box>
      </Grid>
    </>
  );
}
