import React from 'react';
import { Table, Box, Text, Group, Image, Center } from '@mantine/core';
import { Appointment } from '@medplum/fhirtypes';
import { format } from 'date-fns';
import { StatusBadge } from '../../components/StatusBadge';
import { STATUS_COLORS } from '../../utils/constants';
import { IconCircleCheck, IconDotsVertical, IconVideo } from '@tabler/icons-react';

interface TelehealthTableProps {
  data: any;
  isLoading: boolean;
  statuses: { system: string; code: string; display: string }[];
  handleStartCall: (appointmentId: string) => void;
}

const TelehealthTable: React.FC<TelehealthTableProps> = ({ data, isLoading, statuses, handleStartCall }) => {
  const icons = {
    IcUser: '/img/icons/ic-user.svg',
    IcShieldPlus: '/img/icons/ic-shield-plus.svg',
    IcSquareCheck: '/img/icons/ic-square-check.svg',
    IcCard: '/img/icons/ic-id-card.svg',
    IcMobile: '/img/icons/ic-mobile.svg',
    IcChat: '/img/icons/ic-chat.svg',
  };

  return (
    <Table>
      <thead>
        <tr>
          <th>Time & Status</th>
          <th>Patient</th>
          <th>Appointment Date</th>
          <th>Reason</th>
          <th>Duration</th>
          <th>Visit Components</th>
          <th>Provider</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {isLoading ? (
          <tr>
            <td colSpan={8}>
              <Center>Loading...</Center>
            </td>
          </tr>
        ) : data?.length === 0 ? (
          <tr>
            <td colSpan={8}>
              <Center>No data found</Center>
            </td>
          </tr>
        ) : (
          data?.map((item: any) => {
            const appointment = item.resource as Appointment;
            const patient = appointment.participant?.find((p) => p.actor?.reference?.startsWith('Patient'));
            const practitioner = appointment.participant?.find((p) =>
              p.actor?.reference?.startsWith('Practitioner')
            );
            const isAppointmentFulfilled: boolean = (appointment.status === 'fulfilled');

            return (
              <tr key={appointment.id}>
                <td>
                  <Box>
                    <Text fw={500}>{appointment?.start ? format(appointment?.start, 'hh:mm A') : '-'}</Text>

                    { appointment?.status && <StatusBadge
                      statusColor={ STATUS_COLORS[appointment?.status] || 'gray'}
                      statusLabel={statuses.find((status) => status.code === appointment?.status)?.display?.toUpperCase() || appointment?.status}
                      badgeColor="#D0D5DD"
                      textColor="#757575"
                    />}
                  </Box>
                </td>
                <td>
                  <Text fw={700}>{patient?.actor?.display || 'Unknown'}</Text>
                </td>
                <td>
                  {appointment?.start
                    ? format(new Date(appointment?.start), 'DD MMM YYYY')
                    : '-'}
                </td>
                <td style={{ maxWidth: 250}}>
                  { appointment?.description || '-'}
                </td>
                <td>{appointment?.minutesDuration ? `${appointment?.minutesDuration} min` : '-'}</td>
                <td>
                  <Group className=''>
                    <Image className='' src={icons.IcUser} width={18} />
                    <Image src={icons.IcShieldPlus} width={18} />
                    <Image src={icons.IcSquareCheck} width={18} />
                    <Image src={icons.IcCard} width={18} />
                    <Image src={icons.IcMobile} width={18} />
                  </Group>
                </td>
                <td>
                  {practitioner?.actor?.display || 'Unknown'}
                </td>
                <td>
                  <Group style={{ display: 'flex', alignItems: 'center', marginLeft: '0.7rem', cursor: 'pointer' }}>
                    <IconCircleCheck stroke={1.5} size={20} />
                    <Image src={icons.IcChat} width={18} />
                    <IconVideo color={ isAppointmentFulfilled ? "#00B14A" : "gray"} stroke={1.5} size={20} onClick={() => appointment?.id && !isAppointmentFulfilled && handleStartCall(appointment.id)} />
                    <IconDotsVertical stroke={1.5} size={18} />
                  </Group>
                </td>
              </tr>
            );
          })
        )}
      </tbody>
    </Table>
  );
};

export default TelehealthTable;
