/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Card, Text, Group, createStyles } from '@mantine/core';
import { IconArrowRight } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import { patientCountsByOrganization } from '../../fhirApi';

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
  },

  section: {
    padding: theme.spacing.md,
    borderBottom: `1px solid #EEF1F6 !important`,
  },

  scrollbar: {
    overflow: 'hidden',
    '&:hover': {
      overflowY: 'auto',
      scrollbarWidth: 'thin',
      scrollbarColor: '#ccc #f3f3f3',
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#ccc',
        borderRadius: '8px',
        '&:hover': {
          background: '#aaa',
        },
      },
    },
  },
}));

export interface patientCountsByOrganization {
  id: string,
  name: string,
  ehrname: string,
  patientCount: number
}

export interface registerPatientProps {
  selectedEHR: any;
  organizationResponse: any;
}

const RegisterPatientsList: React.FC<registerPatientProps> = ({selectedEHR , organizationResponse}) => {
  const { classes } = useStyles();

  const organisationDetails = organizationResponse?.data?.map((details: patientCountsByOrganization) => (
    <Card.Section key={details.id} display="flex" className={classes.section}>
      <Group style={{ justifyContent: 'flex-start', flex: 4 }}>
        <Text fw={500}>{details.name}</Text>
      </Group>
      <Group style={{ justifyContent: 'flex-start', flex: 4 }}>
        <Text fw={500}>{details.ehrname}</Text>
      </Group>
      <Group style={{ justifyContent: 'center', flex: 1 }}>
        <Text fw={500}>{details.patientCount}</Text>
      </Group>
    </Card.Section>
  ));

  return (
    <Card withBorder radius="md" className={classes.card}>
      <Card.Section className={classes.section} p="lg">
        <Text fw={700}>Patients Registered by Organization</Text>
      </Card.Section>

      <Card.Section display="flex" className={classes.section}>
        <Group style={{ justifyContent: 'flex-start', flex: 4 }}>
          <Text fw={700}>Organization Name</Text>
        </Group>
        <Group style={{ justifyContent: 'flex-start', flex: 4 }}>
          <Text fw={700}>EHR</Text>
        </Group>
        <Group style={{ justifyContent: 'center', flex: 1 }}>
          <Text fw={700}>Count</Text>
        </Group>
      </Card.Section>

      <Group className={classes.scrollbar} display="block" style={{ height: '280px', overflowX: 'hidden' }}>
        {organisationDetails?.length !==0 ? organisationDetails : <Text sx={{ textAlign: 'center', position: 'relative', top: '50%'}} fw={500}>No Data Found</Text>}
      </Group>

      <Card.Section className={classes.section}>
        <Group style={{ alignItems: 'flex-end' }} spacing={30}>
          <div style={{ textDecoration: 'none', alignItems: 'flex-end', flex: 2 }}></div>
          <Link
            style={{
              textDecoration: 'none',
              alignItems: 'center',
              justifyContent: 'flex-end',
              flex: 1,
              color: '#222',
              display: 'flex',
            }}
            to={'/all-patients-registered-by-organizations'}
          >
            <Text fw={700} mr="5px">
              View All
            </Text>
            <IconArrowRight size="16px" />
          </Link>
        </Group>
      </Card.Section>
    </Card>
  );
};
 
export default RegisterPatientsList;
