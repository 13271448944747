import { Card, Text, Group, createStyles, Skeleton, Box, Grid, Center, Pagination } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useMedplum } from '@medplum/react';
import Each from '../Each';
import TitleComponent from '../TitleComponent';
import axios from '../../utils/axios';

const useStyles = createStyles((theme) => ({


  scrollbar: {
    overflow: 'hidden',
    '&:hover': {
      overflowY: 'auto',
      scrollbarWidth: 'thin',
      scrollbarColor: '#ccc #f3f3f3',
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#ccc',
        borderRadius: '8px',
        '&:hover': {
          background: '#aaa',
        },
      },
    },
  },


  card: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
  },
  section: {
    padding: theme.spacing.sm,
    borderBottom: `1px solid #EEF1F6 !important`,
  },
  
}));

export function AllPractitionersConnectedwithOrganization(props : any): JSX.Element {
  const { classes } = useStyles();
  const medplum = useMedplum();
  const [practitionerResponse, setPractitionerResponse] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isDataFound, setIsDataFound] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPageCount, setTotalPageCount] = useState<number>(0);
  const perPage = 20;

  const fetchData = async () => {
    const token = await medplum.getAccessToken();
    const offset = (currentPage - 1) * perPage;
    try {
      setIsLoading(true);
      setPractitionerResponse([]);
      setIsDataFound(false);
      const response = await axios.get(
        `api/analytics/get-practitioners-by-organization?_count=20&organizationId=all&_offset=${offset}`,
        {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.data?.data?.length !== 0) {
        setTotalPageCount(response?.data.data.length);
        setPractitionerResponse(response.data.data || []);
      } else {
        setIsDataFound(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  return (
    <>
      <TitleComponent title="Practitioners Connected with Organization" />
      <Box px="lg" py="sm">
        <Grid mb="md">
          <Grid.Col span={12}>
            <Card withBorder radius="md" className={classes.card}>
              <Card.Section px="lg" display="flex" className={classes.section}>
                <Group style={{ justifyContent: 'flex-start', flex: 4 }}>
                  <Text className="darkText" fw={700}>Name</Text>
                </Group>
                <Group style={{ justifyContent: 'flex-start', flex: 3 }}>
                  <Text className="darkText" fw={700}>Specialization</Text>
                </Group>
                <Group style={{ justifyContent: 'flex-start', flex: 5 }}>
                  <Text className="darkText" fw={700}>Email ID</Text>
                </Group>
                <Group style={{ justifyContent: 'flex-start', flex: 3 }}>
                  <Text className="darkText" fw={700}>Phone No.</Text>
                </Group>
                <Group style={{ justifyContent: 'flex-start', flex: 3 }}>
                  <Text className="darkText" fw={700}>Organizations</Text>
                </Group>
              </Card.Section>

              <Group
                px="xs"
                display="block"
                className={classes.scrollbar}
                style={{ height: '450px', overflowX: 'hidden' }}
              >
                <Each
                  of={practitionerResponse}
                  
                  render={(item: any) => (
                    <Card.Section display="flex" className={classes.section}>
                      <Group style={{ justifyContent: 'flex-start', flex: 4 }}>
                        <Text className="lightText" fw={500}>{item?.name || '-'}</Text>
                      </Group>
                      <Group style={{ justifyContent: 'flex-start', flex: 3 }}>
                        <Text className="lightText" fw={500}>{item?.specialization || '-'}</Text>
                      </Group>
                      <Group style={{ justifyContent: 'flex-start', flex: 5 }}>
                        <Text className="lightText" fw={500}>{item?.email || '-'}</Text>
                      </Group>
                      <Group style={{ justifyContent: 'flex-start', flex: 3 }}>
                        <Text className="lightText" fw={500}>{item?.phone || '-'}</Text>
                      </Group>
                      <Group style={{ justifyContent: 'flex-start', flex: 3 }}>
                        <Text className="lightText" fw={500}>{item?.organization || '-'}</Text>
                      </Group>
                    </Card.Section>
                  )}
                />
                {isDataFound && (
                  <Group style={{ justifyContent: 'center', flex: 1, position: 'relative', top: '50%' }}>
                    <Text className="darkText" fw={700}>No Data Found</Text>
                  </Group>
                )}
                {isLoading && (
                  <div style={{ marginTop: '10px' }}>
                    <Skeleton height={8} radius="xl" />
                    <Skeleton height={8} mt={6} radius="xl" />
                    <Skeleton height={8} mt={6} width="70%" radius="xl" />
                  </div>
                )}
              </Group>
            </Card>
          </Grid.Col>
        </Grid>
        <Center m="md" p="md" style={{ justifyContent: 'flex-end' }}>
        {totalPageCount && (
            <Pagination
              className="pagination"
              value={currentPage}
              total={Math.ceil(totalPageCount / perPage)}
              onChange={setCurrentPage}
            />
          )}
        </Center>
      </Box>
    </>
  );
}
