import { ResourceType } from '@medplum/fhirtypes';
import { Document, ResourceBlame, useMedplum } from '@medplum/react';
import { useParams } from 'react-router-dom';

export function Blame(): JSX.Element | null {
  const medplum = useMedplum();
  const { id } = useParams() as { id: string };
  const resourceType: ResourceType = 'Patient';
  const history = medplum.readHistory(resourceType, id).read();

  return (
    <Document>
      <ResourceBlame history={history} />
    </Document>
  );
}
