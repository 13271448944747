/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Center, createStyles, Text } from '@mantine/core';
import { IconChevronRight, IconHome2 } from '@tabler/icons-react';
import { Link, useLocation } from 'react-router-dom';

const useStyles = createStyles(() => ({
  link: {
    fontSize: '14px',
    color: '#1B5390',
    textDecoration: 'none',
    '& :hover': {
      textDecoration: 'underline',
    },
  },
}));

const Breadcrumb = () => {
  const location = useLocation();
  const { classes } = useStyles();

  const generateBreadcrumb = () => {
    const pathnames = location.pathname.split('/').filter((pathname) => pathname !== '');
    const isRoot = pathnames[0] === 'dashboard';

    return (
      <Center>
        <Center style={{ alignItems: 'flex-end' }}>
          <IconHome2 size="15px" />
          <Link className={classes.link} to={'/dashboard'} style={{ lineHeight: '12px', marginLeft: '5px' }}>
            Dashboard
          </Link>
        </Center>

        {!isRoot ? (
          <ul style={{ display: 'flex', margin: '0', paddingInlineStart: '5px' }}>
            {pathnames.map((pathname, index) => {
              const formatPathname = pathname
                .replace(/-/g, ' ')
                .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
              const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
              const isLast = index === pathnames.length - 1;
              return (
                <li key={pathname} style={{ listStyleType: 'none' }}>
                  <Center style={{ alignItems: 'center' }}>
                    <IconChevronRight size="15px" style={{ marginRight: '5px', lineHeight: '15px' }} />
                    {isLast ? (
                      <Text fw={600}>{formatPathname}</Text>
                    ) : (
                      <Link className={classes.link} to={routeTo}>
                        {formatPathname}
                      </Link>
                    )}
                  </Center>
                </li>
              );
            })}
          </ul>
        ) : null}
      </Center>
    );
  };

  return <Center>{generateBreadcrumb()}</Center>;
};

export default Breadcrumb;
