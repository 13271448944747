import { Box, Grid } from '@mantine/core';
import { QueryClient, QueryClientProvider } from 'react-query';
import FeaturedCards from '../FeaturedCards';
import { UpcomingAppointments } from '../UpcomingAppointments';
import { PatientsList } from '../PatientsList';
import { useLocation } from 'react-router-dom';
import TitleComponent from '../TitleComponent';
import { useEffect, useState } from 'react';
import { organizationStatisticCounts } from '../../fhirApi';
import { useMedplum } from '@medplum/react';

export function OrganisationDashboard(): JSX.Element {
  const queryClient = new QueryClient();
  const location = useLocation();
  const orgDetails = location.state;
  const [statisticCountsRes, setStatisticCountsRes] = useState<any | null>();
  const medplum = useMedplum();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await organizationStatisticCounts(medplum, orgDetails?.id);
        setStatisticCountsRes(response?.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
  const cardsData = [
    {
      ImageSrc: '../../img/dashboard-assets/appointments.svg',
      text: 'Appointments',
      subText: 'Total # of Appointments Scheduled',
      counts: `${statisticCountsRes?.data.appointmentCounts || '00'}`,
      routeTo: '#',
      cardTheme: 'green-card',
      progressHighlight: '#8AD8C7',
      progressBg: '#D3FAF2',
      isCheckCard: false
    },
    {
      ImageSrc: '../../img/dashboard-assets/procedures.svg',
      text: 'Procedures',
      subText: 'Total # of Procedures',
      counts: `${statisticCountsRes?.data.procedureCounts || '00'}`,
      routeTo: '#',
      cardTheme: 'gray-card',
      progressHighlight: '#ADADAD',
      progressBg: '#DBDBDB',
      isCheckCard: false
    },
    {
      ImageSrc: '../../img/dashboard-assets/patients.svg',
      text: 'Patients',
      subText: 'Total # of Patients Registered',
      counts: `${statisticCountsRes?.data.patientCounts || '00'}`,
      routeTo: '#',
      cardTheme: 'blue-card',
      progressHighlight: '#82A4D4',
      progressBg: '#D7E6FB',
      isCheckCard: false
    },
    {
      ImageSrc: '../../img/dashboard-assets/lab-reports.svg',
      text: 'Lab Reports',
      subText: 'Lab Reports Generated',
      counts: `${statisticCountsRes?.data.diagnosticReportCounts || '00'}`,
      routeTo: '#',
      cardTheme: 'red-card',
      progressHighlight: '#DAA2A2',
      progressBg: '#FFE4E4',
      isCheckCard: false
    },
  ];

  return (
    <>
      <TitleComponent title={`Organizations : ${orgDetails?.organizationName}`} />

      <Box px="lg" py="sm">
        <Grid>
          <Grid.Col span={12} sm={12} lg={12}>
            <QueryClientProvider client={queryClient}>
              <FeaturedCards cardsData={cardsData} />
            </QueryClientProvider>
          </Grid.Col>
        </Grid>
      </Box>

      <Box px="lg" py="sm">
        <Grid mb="md">
          <Grid.Col span={12} sm={12} lg={6}>
            <UpcomingAppointments orgId={orgDetails?.id}/>
          </Grid.Col>
          <Grid.Col span={12} sm={12} lg={6}>
            <PatientsList orgId={orgDetails?.id}/>
          </Grid.Col>
        </Grid>
      </Box>
    </>
  );
}
