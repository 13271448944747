// eslint-disable-next-line @typescript-eslint/explicit-function-return-type

import axios from './axios';
import { MedplumClient } from '@medplum/core';
import { HumanName } from '@medplum/fhirtypes';

export function convertDateTimeIntoDate(data: any) {
  const date = new Date(data);

  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'short' });
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
}

export function getEHRTokenFromLocalStorage(): string {
  const localStorageData = localStorage.getItem('ehr_token');
  if (localStorageData !== null) {
    const data = JSON.parse(localStorageData);
    if (data && data.data && data.data.access_token) {
      return data.data.access_token;
    }
  }
  return '';
}

export const getTemplateList = async (medplum: MedplumClient): Promise<any> => {
  const token = await medplum.getAccessToken();

  const response = await axios.get(`/fhir/R4/Composition`, {
    headers: {
      Accept: 'application/fhir+json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
};

export const getProjects = async (medplum: MedplumClient, id: string): Promise<any> => {
  const token = await medplum.getAccessToken();

  const response = await axios.get(`/admin/projects/${id} || ''`, {
    headers: {
      Accept: 'application/fhir+json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
};

export function formatTime(seconds: number): string {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const pad = (num: number) => (num < 10 ? `0${num}` : `${num}`);

  return `${pad(hours)}:${pad(minutes)}:${pad(remainingSeconds)}`;
}

export function getFullName(name: HumanName): string {
  if (!name) {
    return '';
  }
  return `${name.given?.join(' ') || ''} ${name.family || ''}`.trim();
}