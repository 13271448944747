import React, { useEffect, useState } from 'react';
import { Loading, useMedplumContext } from '@medplum/react';
import { Grid, Paper } from '@mantine/core';
import { useParams } from 'react-router-dom';
import TitleComponent from '../TitleComponent';
import ZoomMeeting from './Zoom/video/ZoomMeeting';
import ZoomContext from './Zoom/context/zoom-context';
import ZoomVideo from '@zoom/videosdk';
import { getZoomSignature } from '../../fhirApi';
interface MeetingDetails {
    signature: string;
    topic: string;
    name: string;
    password: string;
}

const Meeting: React.FC = () => {
    const { medplum, profile } = useMedplumContext();
    const { id: appointmentId } = useParams<{ id: string }>();
    const [appointment, setAppointment] = useState<any>();
    const [meetingDetails, setMeetingDetails] = useState<MeetingDetails | null>(null);
    const [displayMeeting, setDisplayMeeting] = useState(false);
    const zmClient = ZoomVideo.createClient();
    const activeUserType = profile?.resourceType;

    const fetchAppointmentDetails = async (appointmentId: string) => {
        try {
            const appointment = await medplum.readResource('Appointment', appointmentId);
            if (!appointment) throw new Error('Appointment not found');
            setAppointment(appointment);

        } catch (error) {
            console.error('Error fetching appointment details:', error);
        }
    };

    const getSignatureDetails = async () => {
        const role = activeUserType === 'Patient' ? 0 : 1;
        const body = {
            appointmentId: appointmentId,
            role_type: role,
        }

        try {
            const session = await getZoomSignature(medplum, body);
            if (session) {
                const { sdkJWT } = session;

                const participants = appointment.participant?.map((part: any) => ({
                    reference: part.actor.reference,
                    display: part.actor.display,
                }));

                var name: string;
                if (participants) {
                    name = (activeUserType === 'Practitioner') ? participants[1]?.display : participants[0]?.display;
                }

                setMeetingDetails((prevDetails: any) => ({
                    ...prevDetails,
                    signature: sdkJWT,
                    topic: appointmentId,
                    name: name,
                }));

                setDisplayMeeting(true);
            }
        } catch (error) {
            console.error('Error joining Zoom meeting:', error);
        }
    };

    useEffect(() => {
        if (appointmentId) {
            fetchAppointmentDetails(appointmentId);
        }
    }, [appointmentId, medplum]);

    useEffect(() => {
        if (appointment) {
            getSignatureDetails();
        }
    }, [appointment]);

    const renderZoomMeeting = () => {
        if (!displayMeeting || !zmClient || !meetingDetails) return <Loading />;

        return (
            <ZoomContext.Provider value={zmClient}>
                <ZoomMeeting
                    meetingArgs={{
                        topic: meetingDetails.topic,
                        signature: meetingDetails.signature,
                        name: meetingDetails.name,
                        webEndpoint: 'zoom.us',
                    }}
                />
            </ZoomContext.Provider>
        );
    };

    return (
        <Paper>
            <TitleComponent title="Meeting" />

            <Grid mx="md">
                <Grid.Col xs={12} sm={7}>
                    {renderZoomMeeting()}
                </Grid.Col>
                <Grid.Col xs={12} sm={5}>
                </Grid.Col>
            </Grid>
        </Paper>
    );
};

export default Meeting;
