import { useEffect, useState } from 'react';
import { Group, Select } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { IconCalendar, IconSearch } from '@tabler/icons-react';
import { useMedplumContext } from '@medplum/react';
import { getFullName } from '../../utils/util';

interface Filters {
    date?: string;
    location?: string;
    practitioner?: string;
    patient?: string;
}

interface TelehealthFiltersProps {
    onApplyFilters: (filters: Filters) => void;
}

const TelehealthFilters: React.FC<TelehealthFiltersProps> = ({ onApplyFilters }) => {
    const { medplum } = useMedplumContext();
    const isSuperAdmin = medplum.isSuperAdmin();
    const [practitioners, setPractitioners] = useState([]);
    const [locations, setLocations] = useState([]);
    const [selectedPractitioner, setSelectedPractitioner] = useState('');
    const [selectedLocation, setSelectedLocation] = useState('');
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [patients, setPatients] = useState([]);
    const [selectedPatient, setSelectedPatient] = useState('');

    const fetchPatients = (searchTerm = '') => {
        medplum
            .get(`/fhir/R4/Patient?name=${searchTerm}&_count=10`)
            .then((response) => {
                const practitionerOptions = response.entry.map((entry: any) => ({
                    value: entry.resource.id,
                    label: getFullName(entry.resource?.name[0]) || entry.resource?.name[0]?.text || 'Unknown',
                }));
                setPatients(practitionerOptions);
            });
    };

    const fetchPractitioners = (searchTerm = '') => {
        medplum
            .get(`/fhir/R4/Practitioner?name=${searchTerm}&_count=10`)
            .then((response) => {
                const practitionerOptions = response.entry.map((entry: any) => ({
                    value: entry.resource.id,
                    label: getFullName(entry.resource?.name[0]) || entry.resource?.name[0]?.text || 'Unknown',
                }));
                setPractitioners(practitionerOptions);
            });
    };

    const fetchLocations = (searchTerm = '') => {
        medplum
            .get(`/fhir/R4/Location?name=${searchTerm}&_count=10`)
            .then((response) => {
                const locationOptions = response.entry.map((entry: any) => ({
                    value: entry.resource.id,
                    label: entry.resource.name || 'Unnamed Location',
                }));
                setLocations(locationOptions);
            });
    };

    const handleApplyFilters = () => {
        const filters = {
            practitioner: selectedPractitioner,
            location: selectedLocation,
            patient: selectedPatient,
            date: selectedDate ? selectedDate.toISOString() : '',
        };
        onApplyFilters(filters);
    };

    useEffect(() => {
        handleApplyFilters();
    }, [selectedPractitioner, selectedLocation, selectedPatient, selectedDate]);

    return (
        <Group bg="#FAFCFF"
            style={{
                borderRadius: '8px',
                border: '1px solid #F2F7FE',
            }}
            p="sm"
            mb="lg"
        >

            { isSuperAdmin && <Select
                label="Organization"
                placeholder="Search"
                w={200}
                data={locations}
                icon={<IconSearch color="#ced4da" size={18} />}
                onFocus={() => fetchLocations()}
                onChange={(value: string) => setSelectedLocation(value)}
                onSearchChange={(value) => fetchLocations(value)}
                styles={() => ({
                    input: { border: '0.0625rem solid #ced4da' },
                    label: { fontFamily: 'Inter' },
                })}
                searchable
                clearable
            /> }

            { isSuperAdmin && <Select
                label="Provider"
                placeholder="Search"
                w={200}
                data={practitioners}
                icon={<IconSearch color="#ced4da" size={18} />}
                onFocus={() => fetchPractitioners()}
                onChange={(value: string) => setSelectedPractitioner(value)}
                onSearchChange={(value) => fetchPractitioners(value)}
                styles={() => ({
                    input: { border: '0.0625rem solid #ced4da' },
                    label: { fontFamily: 'Inter' },
                })}
                searchable
                clearable
            /> }

            <Select
                label="Patient"
                placeholder="Search"
                w={200}
                data={patients}
                icon={<IconSearch color="#ced4da" size={18} />}
                onFocus={() => fetchPatients()}
                onChange={(value: string) => setSelectedPatient(value)}
                onSearchChange={(value) => fetchPatients(value)}
                styles={() => ({
                    input: { border: '0.0625rem solid #ced4da' },
                    label: { fontFamily: 'Inter' },
                })}
                searchable
                clearable
            />

            <DateInput
                label="Date"
                placeholder="Select"
                w={200}
                maw={400}
                value={selectedDate}
                valueFormat='MM/DD/YYYY'
                onChange={setSelectedDate}
                icon={<IconCalendar color="#ced4da" size={18} />}
                sx={{ '.mantine-Popover-dropdown': { transform: 'scale(0.8)', transformOrigin: 'top left' } }}
                styles={(theme) => ({
                    input: { width: '100%' },
                    calendarHeader: {
                        maxWidth: '100%',
                        marginBottom: theme.spacing.xs,
                    },
                    calendarHeaderLevel: { fontSize: theme.fontSizes.sm },
                    yearsList: { maxWidth: '100%' },
                    monthsList: { maxWidth: '100%' },
                    day: { height: 'auto', width: 'auto' },
                    label: { fontFamily: 'Inter' },
                })}
                clearable
            />

        </Group>
    );
};

export default TelehealthFilters;
