/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Card, Center, Grid, Title } from '@mantine/core';
import React from 'react';
import BreadcrumbMain from './BreadcrumbMain';

interface TitleProps {
  title: string;
}

const TitleComponent: React.FC<TitleProps> = ({ title }) => {
  return (
    <Card display="block" shadow="xs" radius="sm" p="xs" className="bg-main-light titlebar">
      <Grid>
        <Grid.Col lg={6} className="titlebar-content">
          {<BreadcrumbMain />}
        </Grid.Col>
        <Grid.Col lg={6} className="titlebar-content" style={{ justifyContent: 'flex-end' }}>
          <Center>
            <Title className="title" variant="sm" size="16px" fw="bold">
              {title}
            </Title>
          </Center>
        </Grid.Col>
      </Grid>
    </Card>
  );
};

export default TitleComponent;
